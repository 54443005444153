import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  private currentHash = environment.buildversion;

  constructor(private http: HttpClient) {
    this.currentHash = environment.buildversion;
  }
  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url: string, frequency = 1000 * 60 * 30) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: string) {
    // timestamp these requests to invalidate caches

    this.http.get(url + '?t=' + new Date().getTime()).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (response: any) => {
        const hash = response.version;
        // const hashChanged;
        const hashChanged = this.hasHashChanged(this.currentHash, hash);

        // If new version, do something
        if (hashChanged) {
          // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
          // for an example: location.reload();

          //location.reload();
          // eslint-disable-next-line no-self-assign
          window.location.href = window.location.href;
        }
        // store the new hash so we wouldn't trigger versionChange again
        // only necessary in case you did not force refresh
        // this.currentHash = hash;
      },
    });
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (newHash === currentHash) {
      return false;
    }

    return true;
  }
}
