import { Component, OnInit } from '@angular/core';
import { Faq } from 'src/app/core/models';
import { FaqService } from '../../core/services/faq.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  loading = false;
  faqlist: Faq[] = [];
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private faqservice: FaqService) {
    this.loading = true;
  }

  ngOnInit(): void {
    this.faqservice.getfaqlist('es').subscribe({
      next: (data) => {
        this.faqlist = data.msg;
        this.loading = false;
      },
      error: (msg) => console.log(msg),
    });
  }
}
