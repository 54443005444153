 <section class="bp-section bp-section--main bp-section--parking">
  <app-loading *ngIf="(loadingStatus$ | async)" class="m-5"></app-loading>
  <ng-container *ngIf="(loadingStatus$ | async) === false && !(loadingStatus)">

    <div class="bp-section__container container-fluid" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,'animate__fadeIn':(loadingStatus$ | async )=== false}">
      <div class="bp-item bp-item--parking">
        <div class="row">
          <div class="offset-lg-2 col-lg-8">
            <div class="bp-item__header">
              <div class="row">
                <div class="col-xl-5">
                  <div class="bp-item__main">
                    <div class="bp-item__heading">
                      <h1 class="bp-item__title">{{ parking.nombre | unescape | trim}}</h1>
                      <p class="bp-item__address">{{parking.direccion | trim}}</p>
                    </div>
                    <div class="row mb-4 mb-xl-0">
                      <div class="col-7 col-xl-12">
                        <div class="bp-item__access">
                          <p class="bp-item__access-title">{{ 'map.sistemadeacceso' | translate }}</p>
                          <p class="bp-item__access-description">{{ parking.acceso | trim}}</p>
                        </div>
                      </div>
                      <div class="bp-item__schedule d-none d-xl-block">
                        <ng-container *ngFor="let hora of parking.horario | splitnewline">
                          <p>{{hora}}</p>
                        </ng-container>
                      </div>
                      <div class="col-5 col-xl-12">
                        <div class="bp-item__buttons">
                          <ng-container *ngFor="let servicio of parking.servicios">
                            <ng-container *ngIf="servicio.planes.length> 0">
                              <app-pricebutton (click)="bntClick(servicio.id,parking.id)" [nClass]="'bp-item__button btn btn-sm'" [idServicio]="servicio.id" [active]="!isBtnActive(servicio.id)" [pmes]="servicio.pmes" [tiempo]="servicio.tiempo"></app-pricebutton>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="bp-item__schedule d-xl-none">
                      <ng-container *ngFor="let hora of parking.horario | splitnewline">
                        <p>{{hora}}</p>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-xl-7 align-self-end">
                  <div class="bp-item__map">
                    <div class="ratio ratio-16x9">

                      <app-mapa [parking]="parking"></app-mapa>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bp-item__body">
          <div class="bp-item__facilities">
            <ng-container>

              <div class="bp-item__facilities-item" *ngFor="let caracteristica of parking.caracteristicas; let z=index">
                <div class="bp-item__facilities-image">
                  <img [attr.src]="url + caracteristica.physicalfilename" alt="{{redparams.nombre}} - {{caracteristica.descripcion}}" title="{{caracteristica.descripcion}}">
                </div>
                <p class="bp-item__facilities-title text-start">{{ caracteristica.caracteristica | translate }}</p>
              </div>
            </ng-container>





          </div>

          <div class="bp-item__gallery bp-gallery">
            <!-- <ng-image-slider [url]="url" [images]="parking.fotos" imageSize="{width: '100%px', height: '300px', space: 4}"></ng-image-slider>-->

            <!-- <swiper-container appSwiper [config]="config" init="false" lightbox-group backgroundOpacity="0.8" closeButtonText="{{ 'map.cerrar' | translate }}">
              <ng-container *ngFor="let foto of parking.fotos">
                <swiper-slide><img alt="{{ foto.filename }}" lightbox [counter]="true" [attr.src]="url + foto.physicalfilename" /></swiper-slide>
              </ng-container>
            </swiper-container>-->
            <swiper [slidesPerView]="'auto'" [spaceBetween]="30" [pagination]="{ clickable: true }" class="mySwiper" [navigation]="true" [loop]="true" lightbox-group backgroundOpacity="0.8" closeButtonText="{{ 'map.cerrar' | translate }}">

              <ng-container *ngFor="let foto of parking.fotos">

                <ng-template swiperSlide><img alt="{{ foto.filename }}" lightbox [counter]="true" [attr.src]="url + foto.physicalfilename" /></ng-template>
              </ng-container>



            </swiper>
          </div>

          <div class="bp-item__info">
            <div class="bp-item__info--item">
              <p class="bp-item__info--title">{{ 'map.lugaresdeinteres' | translate }}</p>
              <div class="bp-item__info--description">
                <ng-container *ngFor="let lugar of parking.lugares | splitnewline">
                  <p>{{lugar | unescape}}</p>
                </ng-container>

              </div>
            </div>
            <div class="bp-item__info--item">
              <p class="bp-item__info--title">{{ 'map.comollegar' | translate }}</p>
              <div class="bp-item__info--description">
                <ng-container *ngFor="let lugar of parking.llegar | splitnewline">
                  <p>{{lugar | unescape}}</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</section>
