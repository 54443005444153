import { Component, OnInit } from '@angular/core';
import { SCREEN_SIZE } from 'src/app/core/services/screen-size.enum';

import { delay } from 'rxjs/operators';
import { ResizeService } from 'src/app/core/services/resize.service';

@Component({
  selector: 'app-instrucciones',
  templateUrl: './instrucciones.component.html',
  styleUrls: ['./instrucciones.component.scss'],
})
export class InstruccionesComponent implements OnInit {
  // size: SCREEN_SIZE;
  screenSize: boolean;
  //images: string[] = ['assets/img/ico7.png', 'assets/img/ico8.png', 'assets/img/ico9.png', 'assets/img/ico5.png'];
  //textos: string[] = ['Mensual', 'Por horas', `Pulsa APARCAR BICI en el parking seleccionado en <a [routerLink]="['/mapa']">el mapa</a>`, ' Introduce el CÓDIGO DE ACCESO de la puerta en el teclado'];

  constructor(private resizeSvc: ResizeService) {
    this.resizeSvc.onResize$.pipe(delay(0)).subscribe((x) => {
      this.screenSize = x > SCREEN_SIZE.MD ? true : false;
    });
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
  }
}
