import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { AnimationMode, DefaultProperties, Image, ImageExtended, Properties } from '../models';
import { LightboxService } from '../services/lightbox.service';
import { EventService } from '../services/event.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[lightbox]',
})
export class LightboxDirective {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  globalEventsSubscription: any;
  image!: ImageExtended;
  @Input() fullImage!: Image;
  @Input() properties: Properties = DefaultProperties;
  @Input() loop = false;

  @Input() counter = false;

  @Input() imageMaxHeight!: string;
  @Input() imageMaxWidth!: string;
  @Input() animationDuration!: number;
  @Input() animationMode: AnimationMode = 'default';
  @Input() animationTimingFunction!: string;
  //@Input() closeButtonText!: string;
  @Input() counterSeparator!: string;
  @Input() disable = false;
  @Input() simpleMode = false;
  @Input() backgroundColor: 'black' | 'white' = 'black';
  @Input() hideThumbnail = false;
  @Input() gestureEnable = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() events: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.lightbox-single') hostLightboxGroup = true;
  @HostBinding('class.lightbox-simple-mode')
  get hostSimpleMode() {
    return this.simpleMode;
  }

  get isGroupImage(): boolean {
    return this.elementRef.nativeElement.closest('.lightbox-group');
  }

  constructor(private lightbox: LightboxService, private eventService: EventService, private elementRef: ElementRef) {
    this.globalEventsSubscription = this.eventService.emitter.subscribe((event) => {
      this.handleGlobalEvents(event);
    });
  }

  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick(event: PointerEvent) {
    if (this.disable) {
      return;
    }

    if (this.isGroupImage) {
      this.eventService.emitChangeEvent({
        type: 'thumbnail:click',
        elementRef: this.elementRef,
        properties: this.properties,
      });
    } else {
      this.image = this.getImage();

      this.lightbox.open({
        images: [this.image],
        properties: this.properties,
        index: 0,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @angular-eslint/use-lifecycle-interface
  ngOnChanges(changes: any) {
    this.properties = Object.assign({}, this.properties, this.getProperties(changes));
  }
  handleGlobalEvents(event) {
    this.events.emit(event);
  }

  getImage() {
    // eslint-disable-next-line prefer-const
    let image: ImageExtended = {};
    const nativeElement = this.elementRef.nativeElement;

    if (this.fullImage) {
      image.fullImage = this.fullImage;
    }

    image.thumbnailImage = {
      path: nativeElement.src,
      height: nativeElement.naturalHeight,
      width: nativeElement.naturalWidth,
    };

    image.nativeElement = nativeElement;
    return image;
  }

  getProperties(changes) {
    // eslint-disable-next-line prefer-const, @typescript-eslint/no-explicit-any
    let properties: { [index: string]: any } = {};

    for (const prop in changes) {
      if (prop !== 'fullImage') {
        properties[prop] = changes[prop].currentValue;
      }
    }
    return properties;
  }
}
