import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Loaderv2Service } from '../services/loaderv2.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private countRequest = 0;
  constructor(private loadingService: Loaderv2Service) {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has('load')) {
      if (!this.countRequest) {
        this.loadingService.show();
      }
      this.countRequest++;
      return next.handle(req).pipe(
        finalize(() => {
          this.countRequest--;
          if (!this.countRequest) {
            this.loadingService.hide();
          }
        })
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return next.handle(req).pipe(finalize(() => {}));
    }
  }
}
