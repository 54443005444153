import { Directive, OnInit, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { UserService } from '../services/user.service';

@Directive({
  selector: '[appIsAuth]',
})
export class IsAuthDirective implements OnInit {
  condition: boolean;
  @Input() set appIsAuth(condition: boolean) {
    this.condition = condition;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private templateRef: TemplateRef<any>, private userService: UserService, private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    this.userService.isAuthenticated.subscribe((isAuthenticated) => {
      this.viewContainer.clear();
      if ((isAuthenticated && this.condition) || (!isAuthenticated && !this.condition)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
