import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  headerload: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headerload = new HttpHeaders().set('load', '1');
  }

  getQuery(query: string) {
    return this.http.get(`${environment.api_url}/${query}`);
  }
  /*
  createUser(data: FormData) {
    return this.http.post(`${environment.api_url}/api/user`, data);
  }*/

  private formatErrors(error: HttpErrorResponse) {
    return throwError(() => new Error(error.error));
    /*   if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));*/
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(path: string, params: HttpParams = new HttpParams(), headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    const options = { params: params, headers: headers };
    return this.http.get(`${environment.api_url}${path}?t=` + new Date().getTime(), options).pipe(catchError(this.formatErrors));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put(path: string, body: object = {}): Observable<any> {
    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.put(`${environment.api_url}${path}`, JSON.stringify(body), { headers: reqHeaders }).pipe(catchError(this.formatErrors));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post(path: string, body: object = {}): Observable<any> {
    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.api_url}${path}`, JSON.stringify(body), { headers: reqHeaders }).pipe(catchError(this.formatErrors));
  }

  postFile(query: string, data: FormData) {
    return this.http
      .post(`${environment.api_url}${query}`, data, {
        reportProgress: true,
        observe: 'events',
        //headers: this.headers,
      })
      .pipe();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(path: string): Observable<any> {
    return this.http
      .delete(
        `${environment.api_url}${path}`
        //, { headers: this.headers }
      )
      .pipe(catchError(this.formatErrors));
  }
}
