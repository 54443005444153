import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParkingsService } from '../../core/services/parkings.service';
import { DefaultRedParams, Parking, Redparams, TpvData, User } from '../../core/models';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.service';

import { environment } from 'src/environments/environment';
import { Serviciosparkings, TypeCodeParkings } from 'src/app/core/enum/serviciosparkings.enum';
import Swal from 'sweetalert2';
import { FirecodeService } from 'src/app/shared/firecode/firecode.service';

import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { Observable } from 'rxjs';
import { Result } from '@zxing/library';
import { QrcodeService } from '../../core/services/qrcode.service';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-parkings',
  templateUrl: './parkings.component.html',
  styles: [],
})
export class ParkingsComponent implements OnInit, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //@ViewChildren('allTheseThings') things: QueryList<any>;
  @ViewChild('inputqrcode') inputqrcode: ElementRef;
  editable = true;
  activatedCamera = false;
  selectedCamera!: MediaDeviceInfo; // Define the camera used by the scanner
  availableCameras: MediaDeviceInfo[] = []; // Defines the cameras available for this device
  qrcode = '';
  formUrl: string;
  tpvData: TpvData;
  precio: number;
  parking: Parking;

  error: boolean;
  errorMsg: string;
  index: number;
  step: number;
  user: User = {} as User;
  plan: string;
  private id: number;
  type = -1;
  today: number = Date.now();
  fechaini: string;
  fechafin: string;
  dentro: number;

  // forms: FormGroup[] = [];
  forma: UntypedFormGroup;
  eServiciosparkings = Serviciosparkings;

  navigationSubscription;
  isSubmitted = false;
  // loadingStatus: Observable<boolean>;
  loadingStatus = true;
  loadingStatus$: Observable<boolean>;
  //loadingStatus = new BehaviorSubject<boolean>(false);
  //rendered$: boolean = false;
  public redparams: Redparams = DefaultRedParams;
  constructor(
    private homeservice: HomeService,
    private qrcodeservice: QrcodeService,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
    @Inject(Router) private router: Router,
    private parkingService: ParkingsService,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder,
    private userService: UserService,
    private firecode: FirecodeService,
    private loadingService: Loaderv2Service
  ) {
    /*   this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component

      if (e instanceof NavigationEnd) {
        this.ngOnInit();
      }
    });*/
    //this.loadingService.showBody();
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.homeservice.getRed().subscribe({
      next: (data) => {
        this.redparams = data.msg;
      },
    });

    this.initProgress();
    this.loadData();
    //this.fetchData();
    this.qrcodeservice.getQrCode().subscribe((data) => {
      this.qrcode = data.trim();
      this.editable = this.qrcode.length === 0 ? true : false;
      this.getParams();
    });
  }

  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  ngForRendred() {
    // console .log('NgFor is Rendered');
    /* setTimeout(() => {
      this.rendered$ = true;
    }, 1);*/
  }

  loadData(): void {
    //  Object.assign(this.user, this.userService.getCurrentUser());
    // console. log('this.user.porhoras.id');
    this.formUrl = environment.tpv_url;
    this.step = 0;
    //  this.rendered$ = false;
    this.error = false;
    // this.loadingService.showBody();
    this.userService.getCurrentUser().subscribe({
      next: (userdata) => {
        // cons ole.log(data.msg);
        this.user = userdata.msg;
        this.dentro = this.user.porhoras.id;
        this.getParams();
      },
      error: () => {
        this.getParams();
        window.localStorage['returnUrl'] = '/' + this.route.snapshot.url.join('/');
      },
    });
  }

  getParams() {
    this.route.params.subscribe((params) => {
      this.loadingStatus = true;
      if (params.id && !isNaN(params.id) && params.type && !isNaN(params.type)) {
        //

        this.id = params.id;
        this.type = parseInt(params.type, 10);
        if (this.type === Serviciosparkings.PARKING_SUSCRIPCION) {
          this.parkingService.getParking(this.id, this.type, this.qrcode).subscribe({
            next: (data) => {
              this.getParking(data.msg);
              this.loadingStatus = false;

              this.forma = this.fb.group({
                plan: ['', [Validators.required]],
              });
              this.forma.addControl('checkterms', new UntypedFormControl(false, [Validators.requiredTrue]));
            },
          });
        }
        if (this.type === Serviciosparkings.PARKING_PORHORAS) {
          this.parkingService.getParking(this.id, this.type, '').subscribe({
            next: (data) => {
              this.loadingStatus = false;
              this.getParking(data.msg);
              this.forma = this.fb.group({
                checkterms: [true, [Validators.requiredTrue]],
              });

              //   this.loadingService.hideBody();
            },
          });
        }
        //  this.loadingService.hideBody();
      } else {
        this.router.navigateByUrl('/mapa');
      }
    });
  }

  getParking(parking: Parking) {
    this.parking = parking;
  }

  guardar(idservicio: number) {
    this.isSubmitted = true;
    switch (idservicio) {
      case Serviciosparkings.PARKING_SUSCRIPCION:
        this.saveSubscription();
        break;
      case Serviciosparkings.PARKING_PORHORAS:
        this.saveParkingPorHoras();
        break;
      case Serviciosparkings.PARKING_REGISTROTARJETA:
        break;
      default:
        break;
    }
  }

  saveParkingPorHoras() {
    this.error = false;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();

      this.step = 0;
      this.error = true;
      return;
    }

    if (this.user.credito < 90) {
      this.fireInsuficientFunds();
      return;
    }

    if (this.user.porhoras.id === 0) {
      Swal.fire({
        title: '¿Está seguro?',
        text: `¿Está seguro de aparcar en este parking?`,
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Aparcar`,
        cancelButtonText: `Cancelar`,
      }).then((resp) => {
        if (resp.value) {
          switch (this.parking.typecode) {
            case TypeCodeParkings.CODE_VAR:
              this.firecode.getCode(this.parking.id, Serviciosparkings.PARKING_PORHORAS);
              break;
            case TypeCodeParkings.CODE_FIXED:
              this.firecode.getNoCode(this.parking.id, Serviciosparkings.PARKING_PORHORAS);
              break;
            default:
              break;
          }
        }
      });
    } else {
      this.router.navigateByUrl('/finish');
    }
  }

  fireInsuficientFunds() {
    Swal.fire({
      title: 'No tienes suficientes creditos',
      text: 'Por favor, recarge credito su cuenta',
      icon: 'error',
    }).then(() => {
      this.router.navigateByUrl('/perfil');
    });
  }
  $;
  saveSubscription() {
    const planes = this.forma.value;
    this.error = false;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();

      this.step = 0;
      this.error = true;
      return;
    }
    this.parkingService.getTpvData(planes.plan, this.qrcode).subscribe((data) => {
      const msg = data.msg;
      this.tpvData = msg.tpvData;
      this.plan = msg.plan;
      this.precio = msg.precio;
      this.fechaini = msg.fechaini;
      this.fechafin = msg.fechafin;
      this.userService.getCurrentUser().subscribe({
        next: (data) => {
          this.user = data.msg;
          this.step = 2;
        },
      });
    });
  }
  endDate(meses: number) {
    const date = new Date();
    date.setMonth(date.getMonth() + meses);
    date.setDate(date.getDate() - 1);
    return new Date(date);
  }
  getNoValido(input: string): boolean {
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }
  porhoras() {
    this.router.navigateByUrl(`/parking/${this.id}/${Serviciosparkings.PARKING_PORHORAS}`);
  }
  porabono() {
    this.router.navigateByUrl(`/parking/${this.id}/${Serviciosparkings.PARKING_SUSCRIPCION}`);
  }
  get noValidPlan() {
    // return true;
    return this.forma.get('plan');
  }

  checkPermissions($event: boolean) {
    if (!$event) {
      alert('Please check your camera permissions and reaload the page');
    }
  }
  handleData($event: Result) {
    if ($event?.getText()) {
      const url = new URL($event.getText());
      const params = new URLSearchParams(url.search);

      if (params.has('utm_medium')) {
        this.qrcode = params.get('utm_medium');
      }

      this.activatedCamera = false;
      //  void this.router.navigate(['/dashboard']);
    }
  }
  activateCamera() {
    this.activatedCamera = true;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  acamerasFoundHandler(devices: MediaDeviceInfo[]) {
    //  console.log(devices);
  }
  camerasFoundHandler(availableCameras: MediaDeviceInfo[]): void {
    this.availableCameras = availableCameras; //We define this.availableCameras according to availableCameras sent by the    ZXingScannerComponent;

    const prefCamera = availableCameras.find((c) => c.deviceId === 'ID_OF_PREFERRED_CAMERA');
    if (prefCamera) this.selectedCamera = prefCamera;
  }

  sendQRcode() {
    const utm_medium: string = this.inputqrcode.nativeElement.value;
    if (utm_medium.length > 0) {
      this.qrcodeservice.setQrCode(utm_medium);

      //  this.editable = false;
    }
  }
  editQRCode() {
    //  this.editable = true;
    this.qrcodeservice.setQrCode('');
  }
}
