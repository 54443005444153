import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Notification, NotificationType } from './notification';

@Injectable({
  providedIn: 'root',
})
export class FirecodeService {
  private _subject = new Subject<Notification>();
  //  private _idx = 0;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  getObservable(): Observable<Notification> {
    return this._subject.asObservable();
  }

  info(title: string, message: string, url?: string) {
    this._subject.next(
      new Notification(
        // this._idx++,
        NotificationType.info,
        title,
        message,
        url
      )
    );
  }

  success(title: string, message: string, url?: string) {
    this._subject.next(
      new Notification(
        //  this._idx++,
        NotificationType.success,
        title,
        message,
        url
      )
    );
  }

  warning(title: string, message: string, url?: string) {
    this._subject.next(
      new Notification(
        //   this._idx++,
        NotificationType.warning,
        title,
        message,
        url
      )
    );
  }

  error(title: string, message: string, url?: string) {
    this._subject.next(
      new Notification(
        // this._idx++,
        NotificationType.error,
        title,
        message,
        url
      )
    );
  }
  getCode(idsuscripcion: number, IdServicio: number) {
    this._subject.next(
      new Notification(
        // this._idx++,
        NotificationType.code,
        idsuscripcion.toString(10),
        IdServicio.toString(10),
        '/perfil'
      )
    );
  }
  getNoCode(idsuscripcion: number, IdServicio: number) {
    this._subject.next(
      new Notification(
        // this._idx++,
        NotificationType.nocode,
        idsuscripcion.toString(10),
        IdServicio.toString(10),
        '/perfil'
      )
    );
  }
}
