import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { RedSecciones, RedStyles, Redparams, msgRedparams } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  headerload: HttpHeaders;
  httpParams: HttpParams;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parkinghomelist$: Observable<any>;
  redparameters$: Observable<msgRedparams>;
  redstyles$: Observable<RedStyles>;
  redsecciones$: Observable<RedSecciones>;
  private athome$ = new BehaviorSubject<boolean>(false);
  private atHome: boolean;
  constructor(private apiService: ApiService) {
    this.headerload = new HttpHeaders().set('load', '1');
    this.httpParams = new HttpParams();
    this.parkinghomelist$ = this.apiService.get('/api/gethomeparkinglist', this.httpParams, this.headerload).pipe(shareReplay(1));
    this.redparameters$ = this.apiService.get('/api/getred', this.httpParams, this.headerload).pipe(shareReplay(1));
    this.redstyles$ = this.apiService.get('/api/getstyles', this.httpParams, this.headerload).pipe(shareReplay(1));
    this.redsecciones$ = this.apiService.get('/api/getsecciones', this.httpParams, this.headerload).pipe(shareReplay(1));
  }
  getCookies(lang: string) {
    return this.apiService.get(`/api/getcookies/${lang}`);
  }

  getStyles() {
    return this.redstyles$;
  }
  getSecciones() {
    return this.redsecciones$;
  }
  /*
  getParkingsInfo() {
    return this.apiService.get('/api/getparkingsinfo', this.httpParams, this.headerload);
  }*/
  getHomeParkingList() {
    //return this.apiService.get('/api/gethomeparkinglist');
    return this.parkinghomelist$;
  }
  /*
  getChangeDate() {
    return this.apiService.get('/api/getdatechange', this.httpParams, this.headerload);
  }*/

  getCoords() {
    return this.redparameters$;
    //  return this.apiService.get(`/api/getcoords/`);
  }
  getRed() {
    return this.redparameters$;
    //return this.apiService.get(`/api/getred/`);
  }

  getPrivacy(lang: string) {
    return this.apiService.get(`/api/getprivacy/${lang}`);
  }
  getTerms(lang: string) {
    return this.apiService.get(`/api/getterms/${lang}`);
  }

  setAtHome(value: boolean) {
    this.atHome = value;
    this.athome$.next(value);
  }
  getAtHome(): boolean {
    return this.atHome;
  }

  listenAtHome(): Observable<boolean> {
    return this.athome$.asObservable();
  }
}
