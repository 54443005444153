import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fechasmysql',
})
export class FechasmysqlPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const dateTimeParts = value.split(/[- :]/);
    const fechas = args.map((val) => {
      switch (val) {
        case 'fecha':
          return dateTimeParts[2] + '-' + dateTimeParts[1] + '-' + dateTimeParts[0];
          break;
        case 'hora':
          return (
            dateTimeParts[3] + ':' + dateTimeParts[4] // + ':' + dateTimeParts[5]
          );
          break;
        default:
          break;
      }
    });
    return fechas[0];
  }
}
