import { Component } from '@angular/core';

@Component({
  selector: 'app-map-layout',
  templateUrl: './map-layout.component.html',
})
export class MapLayoutComponent {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
