import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
})
export class HomeLayoutComponent implements OnInit {
  atHome = true;
  athome$: Observable<boolean>;
  constructor(private home: HomeService) {}

  ngOnInit(): void {
    //this.home.listenAtHome().subscribe;
    this.athome$ = this.home.listenAtHome();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onActivate(event) {
    /* let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, Math.trunc(pos / 2)); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 8);*/
    /*  let pos = window.pageYOffset;
    if (pos > 0) {
      window.scrollTo(0, 0);
    }*/
  }
}
