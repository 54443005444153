import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
})
export class CookiesComponent implements OnInit {
  public cookies: string;
  constructor(private homeservice: HomeService) {}

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.homeservice.getCookies('es').subscribe({
      next: (data: any) => {
        this.cookies = data.msg;
      },
    });
  }
}
