import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-verified',
  templateUrl: './not-verified.component.html',
  styleUrls: ['./not-verified.component.scss'],
})
export class NotVerifiedComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
  }
}
