<div class="container-sm bp-section--main" [ngClass]="{ 'invisible': loading , 'animate__animated':true,'animate__fadeIn':!loading}">
  <div class="row my-3">
    <div class="col">
      <section class="accordion-section clearfix mt-3" aria-label="Question Accordions">
        <div class="container">

          <h2>{{'faq'|translate}} </h2>
          <div ngbAccordion [closeOthers]="true">
            @for (faq of faqlist; track faq) {
            <div ngbAccordionItem [collapsed]="true">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>{{faq.question}}</button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <markdown [data]="faq.answer" [disableSanitizer]="true">
                  </markdown>
                </div>
              </div>
            </div>
            }





          </div>


        </div>
      </section>
    </div>
  </div>
</div>
