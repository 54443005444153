<section class="bp-section bp-section--map">
  <app-loading *ngIf="(loadingStatus$ | async)" class="m-5"></app-loading>
  <div class="container-fluid" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,'animate__fadeIn':(loadingStatus$ | async ) === false}">
    <div class="row">
      <div class="col-12 order-lg-5">
        <div class="bp-section__map" [ngClass]="{ 'animate__animated':true,'animate__fadeIn':(selectedParking===-1)}">
          <div id="map"></div>
          <div id="ol-map" class="map-container"><div id="popup"></div></div>
        </div>
      </div>
      <div class="col-12 order-lg-0">
        <aside class="bp-section__sidebar">
          <a class="bp-section__header" [routerLink]="['/']" title="{{coords.nombre}}">
            <img src="assets/img/biciparc-ico-without-arrow.png" alt="{{coords.nombre}}" class="bp-section__ico" *ngIf="secciones.icono">
            <img [attr.src]="url+'/api/getimage/'+coords.idfilelogo" alt="{{coords.nombre}}" class="bp-section__logo">
          </a>
          <div class="bp-list bp-list--parkings" *ngIf="selectedParking===-1" [ngClass]="{ 'invisible': !(selectedParking===-1) , 'animate__animated':true,'animate__fadeIn':(selectedParking===-1)}">
            <ng-container *ngFor="let parking of parkings; let i= index">
              <div class="bp-list__item">
                <div class="bp-list__content">
                  <a (click)="cambiaMapa(i)"><h2 class="bp-list__title">{{ parking.nombre | unescape}}</h2></a>

                  <div class="bp-list__places">
                    <p class="bp-list__places-text">{{ 'map.plazaslibres' | translate }}</p>
                    <p class="bp-list__places-number">{{ parking.plazas - parking.uso.usadas}}</p>
                  </div>
                  <div class="bp-list__facilities">
                    <ng-container *ngFor="let caracteristica of parking.caracteristicas.slice(0, 4); let z=index">
                      <img [attr.src]="url + caracteristica.physicalfilename" alt="{{coords.nombre}} - {{caracteristica.descripcion}}" title="{{caracteristica.descripcion}}">
                    </ng-container>
                  </div>
                </div>
                <div class="bp-list__prices">
                  <ng-container *ngFor="let servicio of parking.servicios">

                    <div class="bp-list__price" *ngIf="servicio.planes.length> 0">
                      <ng-container>
                        <div class="bp-list__price-title">1 {{servicio.tiempo}} <span>desde</span></div>
                        <button (click)="bntClick(servicio.id, i)" class="bp-list__button btn btn-secondary btn-sm" [ngClass]="{'btn-secondary': servicio.id === eServiciosparkings.PARKING_PORHORAS,'btn-primary': servicio.id ===eServiciosparkings.PARKING_SUSCRIPCION}" [disabled]="!isBtnActive(servicio.id,i )">
                          {{ servicio.pmes}} <span>€</span>
                        </button>
                      </ng-container>


                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-container *ngFor="let parking of parkings; let i= index">
            <div class="bp-item bp-item--detail" *ngIf="selectedParking===i" [ngClass]="{ 'invisible': !(selectedParking===i) , 'animate__animated':true,'animate__fadeIn':selectedParking===i}">
              <div class="bp-item__image">
                <a title="Cerrar" class="bp-item__close" (click)="closeParking('')" data-viewmode="view-map">X</a>
                <img [attr.src]="url + parking.physicalfilename" alt="{{coords.nombre}} - Aparcamiento {{ parking.nombre | unescape}}">
                <div class="bp-item__centered">{{ parking.nombre | unescape }}</div>
              </div>
              <div class="bp-item__header">
                <div class="bp-item__heading">
                  <h3 class="bp-item__title">{{ parking.nombre | trim | unescape}}</h3>
                  <a [routerLink]="['/detalle', parking.id]" title="Más info" class="bp-item__info"><span>+</span> info</a>
                </div>
                <p class="bp-item__address">{{ parking.direccion | trim }}</p>
              </div>
              <div class="bp-item__body">
                <div class="bp-item__places">
                  <p class="bp-item__places-text">{{ 'map.plazaslibres' | translate }}</p>
                  <p class="bp-item__places-number">{{ parking.plazas - parking.uso.usadas}}</p>
                </div>
                <div class="bp-item__access">
                  <p class="bp-item__access-title">{{ 'map.sistemadeacceso' | translate }}</p>
                  <p class="bp-item__access-text">{{ parking.acceso | unescape}}</p>
                </div>
                <div class="bp-item__schedule">
                  <ng-container *ngFor="let hora of parking.horario | splitnewline">
                    <p>{{hora}}</p>
                  </ng-container>
                </div>

                <div class="bp-item__facilities">

                  <div class="bp-item__facilities-item" *ngFor="let caracteristica of parking.caracteristicas; let z=index">
                    <img [attr.src]="url + caracteristica.physicalfilename" alt="{{coords.nombre}} - {{caracteristica.descripcion}}" title="{{caracteristica.descripcion}}">
                    <span>{{ caracteristica.caracteristica | translate }}</span>
                  </div>

                </div>
              </div>
              <div class="bp-item__footer">
                <ng-container *ngFor="let servicio of parking.servicios">
                  <ng-container *ngIf="servicio.planes.length> 0">
                    <app-pricebutton (click)="bntClick(servicio.id,i)" [nClass]="'bp-item__button btn btn-sm'" [idServicio]="servicio.id" [active]="!isBtnActive(servicio.id,i)" [pmes]="servicio.pmes" [tiempo]="servicio.tiempo"></app-pricebutton>
                    <button *ngIf="false" (click)="bntClick(servicio.id,i)" class="bp-item__button btn btn-sm" [ngClass]="{'btn-secondary': servicio.id === eServiciosparkings.PARKING_PORHORAS,'btn-primary': servicio.id ===eServiciosparkings.PARKING_SUSCRIPCION}" [disabled]="!isBtnActive(servicio.id, i )">
                      <ng-container *ngIf="!isBtnActive(servicio.id,i); else isBtnActiveTemplate">
                        <span class="bp-item__button-inner">{{'map.plazas' | translate}}</span>
                        <span class="bp-item__button-inner">{{'map.agotadas' | translate}}</span>
                      </ng-container>
                      <ng-template #isBtnActiveTemplate>
                        <span class="bp-item__button-inner">1 {{servicio.tiempo}} <span>{{ 'map.desde' | translate }}</span></span>
                        <span class="bp-item__button-inner">{{ servicio.pmes}} <span>€/{{ servicio.tiempo}}</span></span>
                      </ng-template>

                    </button>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <div class="bp-bar bp-bar--view-mode" *ngIf="selectedParking>-1">
            <a title="Vista lista" class="bp-bar__button" [ngClass]="{'bp-bar__button--hidden': view ==='view-list'}" data-viewmode="view-list" (click)="closeParking('view-list')">
              {{ 'map.vistalista' | translate }} <i class="fa-solid fa-list"></i>
            </a>
            <a title="Vista mapa" class="bp-bar__button" [ngClass]="{'bp-bar__button--hidden': view ==='view-map'}" data-viewmode="view-map" (click)="closeParking('view-map')">
              {{ 'map.vistamapa' | translate }} <i class="fa-regular fa-map"></i>
            </a>

          </div>

        </aside>
      </div>
    </div>
  </div>
  <div class="bp-bar bp-bar--view-mode" *ngIf="selectedParking===-1">
    <a title="Vista lista" class="bp-bar__button" [ngClass]="{'bp-bar__button--hidden': view ==='view-list'}" data-viewmode="view-list" (click)="changeView('view-list')">
      {{ 'map.vistalista' | translate }} <i class="fa-solid fa-list"></i>
    </a>
    <a title="Vista mapa" class="bp-bar__button" [ngClass]="{'bp-bar__button--hidden': view ==='view-map'}" data-viewmode="view-map" (click)="changeView('view-map')">
      {{ 'map.vistamapa' | translate }} <i class="fa-regular fa-map"></i>
    </a>
  </div>
</section>
