

<div class="container-sm" [ngClass]="{'bp-section--main': full}">
  <div class="row">
    <div class="col">

      <div class="register-box">
        <div class="card">
          <div class="card-body register-box-body f5">
            <p class="login-box-msg" *ngIf="authType ==='register'">Crea tu cuenta en {{redparams.nombre}}</p>
            <p class="login-box-msg" *ngIf="authType !=='register'">Accede a {{redparams.nombre}}</p>
            <form autocomplete="off" [formGroup]="authForm" (ngSubmit)="guardar()">
              <div *ngIf="authType ==='register'">
                <div class="input-group">
                  <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre" [class.is-invalid]="getNoValido('nombre')" />
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('nombre')">Campo obligatorio</small>
              </div>
              <div *ngIf="authType ==='register'">
                <div class="input-group mt-3">
                  <input type="text" formControlName="apellidos" class="form-control" placeholder="Apellidos" [class.is-invalid]="getNoValido('apellidos')" />
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('apellidos')">Campo obligatorio</small>
              </div>
              <div *ngIf="authType ==='register'">
                <div class="input-group mt-3">
                  <input type="tel" formControlName="telefono" class="form-control" placeholder="Telefono" [class.is-invalid]="getNoValido('telefono')" minlength="9" maxlength="14" />
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('telefono')">Campo obligatorio</small>
              </div>
              <div>
                <div class="input-group mt-3">
                  <input type="email" (keyup)="emailused= false" formControlName="email" class="form-control" placeholder="Email" [class.is-invalid]="getNoValido('email') && !emailmalformed" autocomplete="username" />
                  <span class="input-group-text">
                    <i class="fas fa-at"></i>
                  </span>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('email') || emailmalformed">Correo no válido</small>
                <small class="form-text text-danger" *ngIf="emailused">Correo en uso</small>
              </div>
              <div>
                <div class="input-group mt-3">
                  <input type="password" formControlName="pass1" class="form-control" placeholder="Contraseña" [class.is-invalid]="getNoValido('pass1')" autocomplete="new-password" />
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('pass1')">Minimo 6 caracteres</small>
                <small class="form-text text-danger" *ngIf="loginError">Usuario o contraseña no son validos</small>
              </div>
              <div *ngIf="authType ==='register'">
                <div class="input-group mt-3">
                  <input type="password" class="form-control" placeholder="Confirme contraseña" formControlName="pass2" [class.is-invalid]="getNoValido('pass2')" autocomplete="new-password" />
                  <span class="input-group-text">
                    <i class="fas fa-sign-in-alt"></i>
                  </span>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('pass2') || passwordNotEqual">Contraseñas no coinciden</small>
              </div>
              <div class="row mt-3">
                <div class="col-7" *ngIf="authType ==='register'">
                  <div class="row">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="true" formControlName="checkterms" [class.is-invalid]="getNoValido('checkterms')" />
                      <label class="form-check-label f6">
                        Acepto los
                        <a [routerLink]="['/terms']" class="text-black-50">términos, condiciones</a> y la <a [routerLink]="['/priv']" class="text-black-50">política de privacidad</a>
                        de {{redparams.nombre}}.
                      </label>
                    </div>
                    <small class="form-text text-danger" *ngIf="getNoValido('checkterms')">Debes aceptar los términos y condiciones</small>
                  </div>
                  <div class="row">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="true" formControlName="checkads" />
                      <label class="form-check-label f6">{{ 'ofertasypromociones' | translate}}</label>
                    </div>
                  </div>
                </div>
                <!-- /.col -->
                <div class="col-5 align-self-center" *ngIf="authType ==='register'">
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary f5" [disabled]="loading">
                      <span *ngIf="!loading">Registrar</span>
                      <i class="f5 fas fa-sync fa-spin" *ngIf="loading"></i>
                    </button>
                  </div>
                </div>
                <div class="col" *ngIf="authType !=='register'">
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary f5" [disabled]="loading">
                      <span *ngIf="!loading">Acceder</span>
                      <i class="f5 fas fa-sync fa-spin" *ngIf="loading"></i>
                    </button>
                  </div>
                </div>
                <!-- /.col -->
              </div>
            </form>
            <div class="row">
              <div class="col mt-3 mx-auto text-center">
                <a [routerLink]="['/login']" routerLinkActive="router-link-active" class="text-center text-black-50" *ngIf="authType ==='register'">¿Ya eres usuario?</a>
                <a [routerLink]="['/register']" routerLinkActive="router-link-active" class="text-center text-black-50" *ngIf="authType !=='register'">Crea tu cuenta aquí</a>
              </div>
            </div>
            <div class="row">
              <div class="col mt-3 mx-auto text-center">
                <a [routerLink]="['/forgot']" routerLinkActive="router-link-active" class="text-center text-black-50" *ngIf="authType !=='register'">¿Has olvidado tu contraseña?</a>
              </div>
            </div>
          </div>
        </div>
        <!-- /.form-box -->
      </div>
    </div>
  </div>
</div>
