<swal #firstSwal title="SweetAlert2 Timer">
  <div *swalPortal>
    <p>Tu código de apertura es:</p>
    <p class="text-bold text-center f1">*{{code}}#</p>
    <p *ngIf="plazasnumeradas" class="text-bold text-center f1">En la plaza <span [ngStyle]="{'background-color': color}">{{plaza}}</span></p>
    <p *ngIf="!timerStatus" class="">Codigo caducado, solicite otro <a
        (click)="getCode(idsuscripcion,idservicio)">aquí</a></p>
    <p *ngIf="timerStatus" [ngClass]="{'pulsate': pulsating,'pulsatefast':speedpulse}">
      Estará activo durante
      <countdown [config]="{leftTime:leftTime,format: 'mm:ss', notify: [ 30, 60 ]}" (event)="handleEvent($event)"></countdown>.
    </p>
  </div>
</swal>

<swal #secondSwal title="SweetAlert2 Timer" [showCancelButton]="false" [allowOutsideClick]="false" [showConfirmButton]="false">
  <div *swalPortal>
    <p>Tu código de apertura es:</p>
    <p class="text-bold text-center f1">*{{code}}#</p>
    <p *ngIf="plazasnumeradas" class="text-bold text-center f1">En la plaza <span [ngStyle]="{'background-color': color}">{{plaza}}</span></p>
    <p *ngIf="!timerStatus" class=""><button class="btn btn-primary" (click)="cerrar()">Cerrar</button></p>
    <p *ngIf="timerStatus" [ngClass]="{'pulsate': pulsating,'pulsatefast':speedpulse}">
      Estará activo durante
      <countdown [config]="{leftTime: leftTime,format: 'mm:ss', notify: [ 30, 60 ]}" (event)="handleEvent($event)"></countdown>.
    </p>
  </div>
</swal>

<swal #thirdSwal title="SweetAlert2" [showCancelButton]="true" [allowOutsideClick]="true" [showConfirmButton]="true" (confirm)="getNoCode(idsuscripcion,idservicio)" icon="question" [cancelButtonText]="'firecode.cancelbuttontext'| translate">
  <div *swalPortal>
  </div>
</swal>

<swal #fourSwal title="SweetAlert2" [showConfirmButton]="true">
  <div *swalPortal>
    <p *ngIf="!status" [innerHTML]="'firecode.confirminicioparking'|translate"></p>
    <p *ngIf="status" [innerHTML]="'firecode.confirmfinalparking'|translate"></p>
    <p *ngIf="plazasnumeradas" class="text-bold text-center f1">{{'firecode.atplaza'| translate}} <span [ngStyle]="{'background-color': color}">{{plaza}}</span></p>
  </div>
</swal>
