import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { User } from 'src/app/core/models';
import { ParkingsService } from 'src/app/core/services/parkings.service';
import { Parking, Plan, Servicio } from '../../core/models/parking.model';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';

import { ElapsedtimeService, TimeSpan } from '../../core/services/elapsedtime.service';
import { Subject, timer } from 'rxjs';
import Swal from 'sweetalert2';
import { FirecodeService } from 'src/app/shared/firecode/firecode.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TypeCodeParkings } from '../../core/enum/serviciosparkings.enum';

@Component({
  selector: 'app-finishparking',
  templateUrl: './finishparking.component.html',
})
export class FinishparkingComponent implements OnInit {
  user: User = {} as User;
  parking: Parking;
  servicios: Servicio;
  plan = {} as Plan;
  precio: string;
  loading: boolean;
  elapsed: TimeSpan;
  minutes: number;
  oldminutes: number;
  code = 1234;
  finished: boolean;
  today: number;
  subject = new Subject();
  eTypeCodeParkings = TypeCodeParkings;

  private temporizador = timer(0, 1000);
  constructor(private userService: UserService, private parkingservice: ParkingsService, private elapsedtime: ElapsedtimeService, private firecode: FirecodeService, private route: Router) {
    //  this.step = 0;
    this.loading = true;
    this.finished = false;
    this.oldminutes = 0;
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.userService.getCurrentUser().subscribe((userdata) => {
      this.user = userdata.msg;

      if (this.user.porhoras.id === 0) {
        this.route.navigateByUrl('/perfil');
        return;
      }
      this.parkingservice.getParkingList().subscribe((datalist) => {
        if (datalist.msg.filter((parking: Parking) => parking.id === this.user.porhoras.idparking).length > 0) {
          this.parking = datalist.msg.filter((parking: Parking) => parking.id === this.user.porhoras.idparking)[0];
        }

        this.servicios = this.parking.servicios.filter((servicio) => servicio.id === Serviciosparkings.PARKING_PORHORAS)[0];
        this.temporizador.pipe(takeUntil(this.subject)).subscribe(() => {
          this.elapsed = this.elapsedtime.getElapsedTime(this.user.porhoras.initdate);
          this.minutes = this.elapsedtime.getElapsedMinutes(this.user.porhoras.initdate);
          if (
            this.servicios.planes.filter((plan) => {
              return plan.minminutos <= this.minutes && plan.maxminutos >= this.minutes;
            }).length > 0
          ) {
            this.plan = this.servicios.planes.filter((plan) => {
              return plan.minminutos <= this.minutes && plan.maxminutos >= this.minutes;
            })[0];
            this.loading = false;
          } else {
            const diff = this.minutes - this.oldminutes;

            if (diff >= 120) {
              this.parkingservice.devuelvePrecio(this.user.porhoras.idparking).subscribe({
                next: (data) => {
                  this.plan.precio = data.msg / 100;
                  this.oldminutes = this.minutes;
                  this.loading = false;
                },
              });
            } else {
              this.loading = false;
            }
          }
        });
      });
    });
  }

  finishParking() {
    Swal.fire({
      title: '¿Está seguro?',
      text: `¿Está seguro que quieres finalizar el parking?`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Finalizar Parking`,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.value) {
        if (this.user.porhoras.id !== 0) {
          const precio = Number(this.plan.precio) * 100;
          if (precio > this.user.credito) {
            this.firecode.error('Error', 'No tienes sufuciente credito, por favor recarga', '/perfil');
          }

          this.getCode(this.user.porhoras.idparking, Serviciosparkings.PARKING_PORHORAS);
        }
      }
    });
  }

  getCode(idParking: number, idServicio: number) {
    this.parkingservice.getCodeKey(idParking, idServicio).subscribe(
      (data) => {
        switch (this.parking.typecode) {
          case TypeCodeParkings.CODE_VAR:
            break;
          case TypeCodeParkings.CODE_FIXED:
            break;
          default:
            break;
        }
        this.code = data.msg.code;
        this.subject.next(null);
        this.finished = true;
        this.today = Date.now();
      },
      (error) => {
        switch (error.msg) {
          case 'creditoinsuficiente':
            this.firecode.error('Error', 'No tienes sufuciente credito, por favor recarga', '/perfil');
            break;

          default:
            break;
        }
      }
    );
  }
  volver() {
    this.userService.populate();
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }
}
