export const environment = {
  name: 'test',
  production: true,
  //api_url: 'https://test.biciparc.com',
  api_url: '',
  tpv_url: 'https://sis-t.redsys.es:25443/sis/realizarPago',
  version: '2.10.1-2024-04-30T19:25:43.182Z',
  buildversion: '2.10.1-2024-04-30T19:25:43.182Z',
  chatserver: 'https://testchat.biciparc.com',
  // tile_server: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
  //http://map.biciparc.com/tiles/15/17024/10792.png
  tile_server: '//{a-c}.map.biciparc.com/tiles/{z}/{x}/{y}.png',
  attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
  //tile_server: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?{foo}'
};
