<app-firecode></app-firecode>
<div class="container-sm justify-content-center bp-section--main" *ngIf="!loading">
  <ng-container *ngIf="!finished">
    <div class="row header-biciparc mx-0">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="nombre_parking">{{ parking.nombre | unescape | uppercase}}</h5>
          </div>
          <div>
            <a href="https://maps.google.com?saddr=Current+Location&dirflg=b&daddr={{ parking.latitud }},{{ parking.longitud }}" class="btn btn-location header-biciparc" target="_blank">
              <i class="fas fa-location-arrow"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-6 pe-1">
        <div class="card mb-2 shadow-sm">
          <div class="card-header border-0 rounded-0 text-center p-0 gris text-dark">
            Tu bici lleva:
          </div>
          <div class="card-body py-2 text-center">{{elapsed.hours | number:'2.0'}}:{{elapsed.minutes | number:'2.0'}}:{{elapsed.seconds | number:'2.0'}} </div>
        </div>
      </div>
      <div class="col-6 ps-1">
        <div class="card mb-2 shadow-sm">
          <div class="card-header border-0 rounded-0 text-center p-0 gris text-dark">
            Pagas:
          </div>
          <div class="card-body py-2 text-center">{{plan.precio }}€ </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mt-1 login-box-body">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2" *ngIf="servicios.planes.length>0">
        <div class="mb-3">
          <div class="row mx-auto f5" *ngFor="let plan of servicios.planes">
            <div class="col-9 me-0 px-0" style="border-bottom: 1px solid black;">
              Desde {{ (plan.minminutos/60) |number:'1.0-0' }} hasta {{ plan.maxminutos/60 |number:'1.0-0'}} horas
            </div>

            <div class="col-3 px-0" style="border-bottom: 1px solid black;">{{ plan.precio}} €</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mt-3">
      <div class="col-12 px-0">
        <div class="btn-group d-flex" role="group">
          <div class="flex-fill">
            <div class="d-grid gap-2">
              <button class="btn btn-primary flex-fill" type="button" (click)="finishParking()" *ngIf="user.porhoras.id!==0">
                Terminar Parking
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="finished">
    <div class="row mt-2">
      <div class="col-12">
        <div class="card border-0 rounded-0 text-center" *ngIf="parking.typecode === eTypeCodeParkings.CODE_VAR">
          <div class="card-header border-0 rounded-0 text-center p-0 gris text-dark">Código de acceso al parking</div>
          <div class="card-body">
            <h4 class="card-title text-center"><h2>*{{ code }}#</h2></h4>
            <p class="card-text">Estará activo durante 5 minutos.</p>
          </div>
        </div>
        <div class="card border-0 rounded-0 mt-2">
          <div class="card-header border-0 rounded-0 text-center p-0 gris text-dark">RESERVA FINALIZADA</div>

          <div class="card-body text-center">

            <div class="card">
              <div class="card-header text-dark">{{parking.nombre}}</div>
              <div class="card-body pt-0 f5">
                <div class="row mx-auto f5">
                  <div class="col-6">Fecha de entrada</div>
                  <div class="col-6">Fecha de salida</div>
                </div>
                <div class="row mx-auto f5 resumeborder">
                  <div class="col-6">{{ user.porhoras.initdate | fechasmysql: 'fecha' }}</div>
                  <div class="col-6">{{ today| date:'dd-MM-yyyy' }}</div>
                </div>
                <div class="row mx-auto f5">
                  <div class="col-6">Hora de entrada</div>
                  <div class="col-6">Hora de salida</div>
                </div>
                <div class="row mx-auto f5 resumeborder">
                  <div class="col-6">{{ user.porhoras.initdate | fechasmysql: 'hora' }}</div>
                  <div class="col-6">{{ today | date:'shortTime'}}</div>
                </div>
                <div class="row mx-auto f5">
                  <div class="col-6">Duración</div>
                  <div class="col-6">Precio final</div>
                </div>
                <div class="row mx-auto f5 resumeborder">
                  <div class="col-6">{{ minutes | convertime }}</div>
                  <div class="col-6">{{ plan.precio }}€</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 my-2">
      <div class="col-12 px-0">
        <div class="btn-group d-flex" role="group">
          <div class="flex-fill">
            <div class="d-grid gap-2">
              <button class="btn btn-primary flex-fill" (click)="volver()">
                Volver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
