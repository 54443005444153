import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ValidadoresService } from 'src/app/core/services/validadores.service';
import Swal from 'sweetalert2';
import { UserService } from '../../core/services/user.service';
import { Router } from '@angular/router';
import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
  forma: UntypedFormGroup;
  formloading: boolean;
  // loading: boolean;
  passwordNotEqual: boolean;
  passwordnotvalid: boolean;
  loadingStatus$: Observable<boolean>;
  constructor(private validadores: ValidadoresService, private fb: UntypedFormBuilder, private userService: UserService, private router: Router, private loadingService: Loaderv2Service) {
    this.passwordNotEqual = false;
    this.formloading = false;
    //this.loading = true;
    this.passwordnotvalid = false;
  }

  ngOnInit(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
    window.localStorage.removeItem('returnUrl');
    //this.loading = false;
    this.crearFormulario();
  }
  save() {
    this.formloading = true;
    this.passwordnotvalid = false;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }
    const credencials = this.forma.value;
    this.userService.updatepassword(credencials).subscribe(
      () => {
        // update the model
        //   this.updateUser(this.forma.value);
        //  this.router.navigateByUrl('/perfil');

        Swal.fire({
          title: '¡Actualizado',
          text: 'Sus datos han sido actualizados.!',
          icon: 'success',
        }).then(() => {
          this.userService.purgeAuth();
          this.router.navigateByUrl('/');
        });

        this.formloading = false;
      },
      (err) => {
        // this.errors = err;

        switch (err.msg) {
          case 'passwordnotequal':
            this.passwordNotEqual = true;
            break;
          case 'passwordnotvalid':
            this.passwordnotvalid = true;
            break;

          default:
            break;
        }
        this.formloading = false;
      }
    );
  }

  crearFormulario() {
    this.forma = this.fb.group(
      {
        oldpassword: ['', [Validators.required, Validators.minLength(6)]],
        pass1: ['', [Validators.required, Validators.minLength(6)]],
        pass2: ['', [Validators.required, Validators.minLength(6)]],
      },
      {
        validators: this.validadores.passwordIguales('pass1', 'pass2'),
      }
    );
  }

  getNoValido(input: string) {
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }
}
