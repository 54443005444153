import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-descubre',
  templateUrl: './descubre.component.html',
  styles: [],
})
export class DescubreComponent {
  @Input() showflecha = true;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
