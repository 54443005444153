import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitnewline',
})
export class SplitnewlinePipe implements PipeTransform {
  transform(str: string) {
    if (!str) {
      return '';
    }

    return str.split(/\r?\n/);
  }
}
