<div class="container-sm justify-content-center bp-section--main" *ngIf="true">
  <app-loading *ngIf="loading" class="m-5"></app-loading>
</div>
<div class="container-sm" *ngIf="invalidcheckcode && !loading" [ngClass]="{
  'invisible': loading ,
  'animate__animated':true,'animate__fadeIn':!loading}">
  <div class="row">
    <div class="col">
      <div class="login-box">
        <div class="login-logo">
          <a [routerLink]="['/home']"><b>B</b>iciparc</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div class="register-box-body f5">
            <p class="login-box-msg">El enlace ha caducado. Si quieres
              reestablecer la
              contraseña tienes que volver a solicitarlo.</p>
            <div class="row">
              <div class="col-12">
                <div class="d-grid gap-2">
                  <a class="btn btn-primary
                    f5" [routerLink]="['/login']">
                    Acceder
                  </a>
                </div>

              </div>
              <!-- /.col -->
            </div>

          </div>
          <!-- /.login-card-body -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-sm" *ngIf="!invalidcheckcode && !loading" [ngClass]="{
  'invisible': loading ,
  'animate__animated':true,'animate__fadeIn':!loading}">
  <div class="row">
    <div class="col">
      <div class="login-box">
        <div class="login-logo">
          <a [routerLink]="['/home']"><b>B</b>iciparc</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div class="register-box-body f5">
            <p class="login-box-msg">ESCOGE UNA NUEVA CONTRASEÑA.</p>

            <form autocomplete="off" [formGroup]="forma" (ngSubmit)="save()">
              <input type="hidden" formControlName="checkcode" />
              <div>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    formControlName="pass1"
                    class="form-control"
                    placeholder="Introduce nueva contraseña"
                    [class.is-invalid]="getNoValido('pass1')"
                    autocomplete="new-password"
                    onfocus="this.setAttribute('autocomplete',
                    'new-password');"
                    />

                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>

                </div>
                <small class="form-text text-danger"
                  *ngIf="getNoValido('pass1')">Minimo
                  6 caracteres</small>
              </div>

              <div>
                <div class="input-group mb-3">
                  <input
                    type="password"
                    formControlName="pass2"
                    class="form-control"
                    placeholder="Confirme nueva contraseña"
                    [class.is-invalid]="getNoValido('pass2')"
                    autocomplete="new-password"
                    onfocus="this.setAttribute('autocomplete',
                    'new-password');"
                    />

                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>

                </div>
                <small
                  class="form-text text-danger"
                  *ngIf="getNoValido('pass2') ||
                  passwordNotEqual">Contraseñas
                  no
                  coinciden</small>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid gap-2">
                    <button type="submit" class="btn btn-primary
                      f5" [disabled]="formloading">
                      <span *ngIf="!formloading">Actualizar</span>
                      <i class="f5 fas" [ngClass]="{
                        'fa-sync fa-spin': formloading
                        }"></i>
                    </button>
                  </div>

                </div>
                <!-- /.col -->
              </div>
            </form>
          </div>
          <!-- /.login-card-body -->
        </div>
      </div>
    </div>
  </div>
</div>
