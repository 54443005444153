import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styles: [],
})
export class CardsComponent {
  @Input() images;

  @Input() textos;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
