import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DescubreComponent } from './shared/descubre/descubre.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { InstruccionesComponent } from './pages/instrucciones/instrucciones.component';
import { TestComponent } from './pages/test/test.component';
import { CardsComponent } from './shared/cards/cards.component';
import { ParkingsComponent } from './pages/parkings/parkings.component';
import { DomseguroPipe } from './core/pipes/domseguro.pipe';

import { MapaComponent } from './shared/mapa/mapa.component';
import { NotVerifiedComponent } from './pages/not-verified/not-verified.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';

import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { HttpTokenInterceptor } from './core/interceptors/http.token.interceptor';
import { IsAuthDirective } from './core/directives/is-auth.directive';
import { AuthComponent } from './auth/auth.component';

import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { ReciboComponent } from './pages/recibo/recibo.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { LoaderModule } from './loader/loader.module';

import { CountdownModule } from 'ngx-countdown';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';

import localeEs from '@angular/common/locales/es';

import { ForgotComponent } from './pages/forgot/forgot.component';
import { RecoveryComponent } from './pages/recovery/recovery.component';
import { ExpiredatePipe } from './core/pipes/expiredate.pipe';
import { FirecodeComponent } from './shared/firecode/firecode.component';
import { FirecodeService } from './shared/firecode/firecode.service';
import { TiempoparkingComponent } from './shared/tiempoparking/tiempoparking.component';
import { ResumeComponent } from './pages/resume/resume.component';
import { FinishparkingComponent } from './pages/finishparking/finishparking.component';
import { FechasmysqlPipe } from './core/pipes/fechasmysql.pipe';
import { ConvertimePipe } from './core/pipes/convertime.pipe';
import { MisbicisComponent } from './pages/misbicis/misbicis.component';

import { SecurePipe } from './core/pipes/secure.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';

import { LightboxDirective } from './core/directives/lightbox.directive';
import { LightboxComponent } from './shared/lightbox/lightbox.component';
import { HomeLayoutComponent } from './core/layouts/home-layout/home-layout.component';
import { MapLayoutComponent } from './core/layouts/map-layout/map-layout.component';

import { TrimPipe } from './core/pipes/trim.pipe';
import { SplitnewlinePipe } from './core/pipes/splitnewline.pipe';
import { ParkingdetalleComponent } from './pages/parkingdetalle/parkingdetalle.component';
//import { SwiperModule } from 'swiper/angular';
import { LightboxGroupDirective } from './core/directives/lightbox-group.directive';

import { Mapav2Component } from './pages/mapav2/mapav2.component';
import { UnescapePipe } from './core/pipes/unescape.pipe';
import { Unescape } from './core/unescape';
import { PricebuttonComponent } from './shared/pricebutton/pricebutton.component';
import { IframechatComponent } from './shared/iframechat/iframechat.component';
import { FaqComponent } from './pages/faq/faq.component';
import { MarkdownModule } from 'ngx-markdown';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { DetalleparkingComponent } from './shared/detalleparking/detalleparking.component';

//import { register } from 'swiper/element/bundle';
//import { SwiperDirective } from './core/directives/swiper.directive';
//import { ImagesliderModule } from './imageslider/imageslider.module';
import { SwiperModule } from 'swiper/angular';
import { JwtService } from './core/services/jwt.service';
import { UserService } from './core/services/user.service';
import { EMPTY } from 'rxjs';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { NgxGdprCookieConsentModule } from './ngx-gdpr-cookie-consent/ngx-gdpr-cookie-consent.module';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { ReparacionesComponent } from './pages/reparaciones/reparaciones.component';

registerLocaleData(localeEs, 'es');
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/home/assets/i18n/', '.json?t=' + new Date().getTime());
}
export function initAuth(jwtService: JwtService, userService: UserService) {
  return () => (jwtService.getToken() ? userService.getCurrentUser() : EMPTY);
}

//register();

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    DescubreComponent,
    NavbarComponent,
    HomeComponent,
    InstruccionesComponent,
    TestComponent,
    CardsComponent,
    ParkingsComponent,
    DomseguroPipe,
    MapaComponent,
    NotVerifiedComponent,
    UserEditComponent,
    ReparacionesComponent,
    UserProfileComponent,
    IsAuthDirective,
    ReciboComponent,
    ErrorComponent,
    LoadingComponent,

    TermsComponent,
    PrivacyComponent,

    ForgotComponent,
    RecoveryComponent,
    ExpiredatePipe,
    FirecodeComponent,
    TiempoparkingComponent,
    ResumeComponent,
    FinishparkingComponent,
    FechasmysqlPipe,
    ConvertimePipe,
    TrimPipe,
    SplitnewlinePipe,
    MisbicisComponent,
    SecurePipe,
    LightboxDirective,
    LightboxGroupDirective,
    LightboxComponent,
    HomeLayoutComponent,
    MapLayoutComponent,

    ParkingdetalleComponent,
    Mapav2Component,
    UnescapePipe,
    PricebuttonComponent,
    IframechatComponent,
    FaqComponent,
    DetalleparkingComponent,
    CookiesComponent,
  ],
  imports: [
    NgxGdprCookieConsentModule.forRoot({
      cookieTypes: [
        {
          id: 'essential',
          name: 'Cookies imprescindibles',
          description: 'Las cookies esenciales son necesarias para garantizar la estabilidad de la aplicación.',
          disabled: true,
          selected: true,
        },
        {
          id: 'external',
          name: 'Servicios externos',
          description: 'Los servicios externos nos ayudan a ofrecer una mejor experiencia al cliente',
          scripts: [],
        },
      ],
    }),
    BrowserModule,
    ZXingScannerModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    SwiperModule,
    ReactiveFormsModule,
    LoaderModule,
    CountdownModule,
    BrowserAnimationsModule,
    AuthComponent,
    SweetAlert2Module.forRoot(),
    MarkdownModule.forRoot(/*{
      markedOptions: {
          provide: MarkedOptions,

        useValue: {
          headerIds: false,
          mangle: false,
        },
      },
    }*/),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initAuth,
      deps: [JwtService, UserService],
      multi: true,
    },
    FirecodeService,
    Unescape,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/home' },
    { provide: LOCALE_ID, useValue: 'es' },
  ],
  bootstrap: [AppComponent],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
