import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TpvData, User } from 'src/app/core/models';
import { Loaderv2Service } from 'src/app/core/services/loaderv2.service';
import { ReparacionService } from 'src/app/core/services/reparacion.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reparaciones',
  templateUrl: './reparaciones.component.html',
})
export class ReparacionesComponent implements OnInit {
  loadingStatus$: Observable<boolean>;
  loadingStatus = true;
  idreparacion = 0;
  tpvData: TpvData;
  concepto: string;
  precio: number;
  user: User = {} as User;
  formUrl: string;
  constructor(private userService: UserService, private loadingService: Loaderv2Service, @Inject(Router) private router: Router, @Inject(ActivatedRoute) private route: ActivatedRoute, private reparacionService: ReparacionService) {}
  ngOnInit(): void {
    this.formUrl = environment.tpv_url;
    this.initProgress();
    this.getParams();
  }
  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }
  getParams() {
    this.route.params.subscribe((params) => {
      this.loadingStatus = true;
      if (params.idreparacion && !isNaN(params.idreparacion)) {
        //

        this.idreparacion = params.idreparacion;

        this.reparacionService.getReparacion(this.idreparacion).subscribe({
          next: (data) => {
            const msg = data.msg;
            this.tpvData = msg.tpvData;
            this.concepto = msg.plan;
            this.precio = msg.precio;

            this.userService.getCurrentUser().subscribe({
              next: (data) => {
                this.user = data.msg;
                this.loadingStatus = false;
              },
            });
          },
        });

        //  this.loadingService.hideBody();
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }
}
