export enum Serviciosparkings {
  PARKING_SUSCRIPCION = 1,
  PARKING_PORHORAS = 2,
  PARKING_REGISTROTARJETA = 3,
  PARKING_REPARACIONSIMPLE = 4,
}
export enum TypeCodeParkings {
  CODE_VAR = 1,
  CODE_FIXED = 2,
}
