<div class="row pb-3 ps-0 mx-0" style="background-color: #fff;">
  <div *ngFor="let image of images; let i= index" class="col-md-3 mt-3">
    <div class="row g-0 border rounded overflow-hidden flex-md-row shadow-sm h-md-250 position-relative">
      <div class="col-auto w-50">
        <img src="{{ image }}" width="80%" style="max-width: 190px;" class="ms-1" />
      </div>
      <div class="col d-flex flex-column position-static align-self-center pe-3">
        <div class="card-text mb-auto f5" [innerHTML]="textos[i]"></div>
      </div>
    </div>
  </div>
</div>
