import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

import { forkJoin, Observable } from 'rxjs';
import { DefaultRedParams, DefaultRedSeccion, Parking, Redparams, RedSeccion } from 'src/app/core/models';
import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { DeviceDetectorService } from '../../core/services/device-detector.service';
import { ParkingsService } from 'src/app/core/services/parkings.service';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  version = environment.version;
  loadingStatus$: Observable<boolean>;
  year$: number = new Date().getFullYear();
  secciones: RedSeccion = DefaultRedSeccion;
  //var year = today;
  public parkings: Parking[];
  public isCollapsed = true;
  public redparams: Redparams = DefaultRedParams;
  constructor(private translate: TranslateService, private homeservice: HomeService, private parkigService: ParkingsService, private loadingService: Loaderv2Service, private deviceService: DeviceDetectorService) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    this.isCollapsed = this.deviceService.isMobile();
  }

  ngOnInit(): void {
    this.initProgress();
    forkJoin([
      //   this.homeservice.getHomeParkingList(),

      this.parkigService.getParkingList(),
      this.homeservice.getRed(),
      this.homeservice.getSecciones(),
    ]).subscribe((results) => {
      this.parkings = results[0].msg;
      this.redparams = results[1].msg;
      this.secciones = results[2].msg;
    });
  }
  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }
  whatsappClick(): void {
    //https://wa.me/+34644771029
    //http://web.whatsapp.com/send?phone=+34644771029&abid=+34644771029

    const _url = this.deviceService.isMobile() ? `https://wa.me/${this.redparams.whatsapp}` : `http://web.whatsapp.com/send?phone=${this.redparams.whatsapp}&abid=${this.redparams.whatsapp}`;

    window.open(_url, '_blank');
  }
}
