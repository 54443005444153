import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';

import { PorHoras } from '../../core/models';
import { ParkingsService } from '../../core/services/parkings.service';

export interface Entry {
  created: Date;
  id: string;
}

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'app-tiempoparking',
  templateUrl: './tiempoparking.component.html',
  styleUrls: ['./tiempoparking.component.scss'],
})
export class TiempoparkingComponent implements OnInit {
  constructor(private changeDetector: ChangeDetectorRef, private parkingservice: ParkingsService) {}
  @Input() tiempo: string;
  @Input() porhoras: PorHoras;

  tiempopasado: string;
  //private destroyed$ = new Subject();
  nombreParking: string;

  ngOnInit(): void {
    //  let dateTimeParts: DateTime;

    interval(1000).subscribe(() => {
      if (!this.changeDetector['destroyed']) {
        this.changeDetector.detectChanges();
      }
    });
    this.changeDetector.detectChanges();
    this.getNameParking(this.porhoras.idparking);
  }

  decodeDate(date: string): Date {
    const dateTimeParts = date.split(/[- :]/).map(Number);

    dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one
    // const dateObject = new Date(...dateTimeParts);
    return new Date(dateTimeParts[0], dateTimeParts[1], dateTimeParts[2], dateTimeParts[3], dateTimeParts[4], dateTimeParts[5], 0);
  }

  getNameParking(idPArking: number) {
    this.parkingservice.getParkingList().subscribe({
      next: (data) => {
        this.nombreParking = data.msg.filter((parking) => parking.id === idPArking)[0]['nombre'];
      },
    });
  }

  getElapsedTime(): TimeSpan {
    let totalSeconds = Math.floor((new Date().getTime() - this.decodeDate(this.porhoras.initdate).getTime()) / 1000);

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;
    this.tiempopasado = hours + ':' + minutes + ':' + seconds;

    return {
      hours,
      minutes,
      seconds,
    };
  }
}
