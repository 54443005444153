import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReparacionService {
  constructor(private apiService: ApiService) {}
  getReparacion(idreparacion: number) {
    return this.apiService.get(`/tpv/gettpvdata/singlerepair/${idreparacion}`);
  }
  getmisreparaciones() {
    return this.apiService.get(`/reparacion/getlistreparacion/`); //, this.httpParams, this.headers);
  }
}
