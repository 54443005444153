import { Component, OnInit } from '@angular/core';

import { MisbicisService } from '../../core/services/misbicis.service';
import { MiBici } from '../../core/models/misbicis.model';

import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import Swal from 'sweetalert2';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { User } from 'src/app/core/models';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';

import { ParkingsService } from '../../core/services/parkings.service';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-misbicis',
  templateUrl: './misbicis.component.html',
  styleUrls: ['./misbicis.component.scss'],
})
export class MisbicisComponent implements OnInit {
  loading: boolean;
  adding: boolean;
  misbicis: MiBici[] = [];
  forma: UntypedFormGroup;
  formloading: boolean;
  fileName = '';
  uploadProgress: number[] = [];
  uploadMain = 0;
  uploadSub: Subscription;
  user: User = {} as User;
  //subscriptions: Subscriptions[] = [];
  url = environment.api_url;
  edit = false;
  constructor(private fb: UntypedFormBuilder, private misbicisservice: MisbicisService, private http: HttpClient, private userService: UserService, private parkingsService: ParkingsService, private translate: TranslateService) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');

    this.crearFormulario();
    this.loading = true;
    this.adding = false;

    this.getListBicis();

    this.userService.getCurrentUser().subscribe({
      next: (data) => {
        this.user = data.msg;
      },
    });
  }

  getListBicis() {
    this.misbicisservice.getMisBicis().subscribe({
      next: (data) => {
        this.misbicis = data.msg;
        this.uploadProgress.length = this.misbicis.length;
        this.loading = false;
      },
    });
  }

  crearFormulario(nuevo = true) {
    this.forma = this.fb.group({
      marca: ['', [Validators.required, Validators.minLength(1)]],
      modelo: ['', [Validators.required, Validators.minLength(1)]],
      color: ['', [Validators.required, Validators.minLength(1)]],
      valor: ['', [Validators.required, Validators.minLength(1)]],
      //  thumbnail: ['', [Validators.required]],
      //  fileSource: ['', [Validators.required]],
      observaciones: [''],
    });
    if (nuevo) {
      this.forma.addControl('thumbnail', new UntypedFormControl('', [Validators.required]));
      this.forma.addControl('fileSource', new UntypedFormControl('', [Validators.required]));
    } else {
      this.forma.addControl('id', new UntypedFormControl('', [Validators.required]));
    }
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.forma.patchValue({
        fileSource: file,
      });
    }
  }

  getNoValido(input: string) {
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }

  saveold() {
    this.formloading = true;

    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }
    const mibici = this.forma.value;

    this.misbicisservice.setMiBici(mibici).subscribe({
      next: () => {
        Swal.fire({
          title: '¡Actualizado',
          text: 'Sus datos han sido actualizados.!',
          icon: 'success',
        }).then(() => {
          this.getListBicis();
        });

        this.formloading = false;
      },
      error: () => {
        this.formloading = false;
      },
    });
  }

  saveedit() {
    this.formloading = true;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }
    const mibici = this.forma.value;
    this.misbicisservice.setMiBici(mibici).subscribe({
      next: () => {
        Swal.fire({
          title: '¡Actualizado',
          text: 'Sus datos han sido actualizados.!',
          icon: 'success',
        }).then(() => {
          this.formloading = false;
          this.resetmain();
          this.edit = false;
          this.crearFormulario();
          // this.getListBicis();
        });
      },
      error: () => {
        // this.errors = err;
        this.edit = false;
        this.formloading = false;
      },
    });
  }

  save() {
    this.formloading = true;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }
    const miBici = new FormData();
    miBici.append('marca', this.forma.get('marca').value);
    miBici.append('modelo', this.forma.get('modelo').value);
    miBici.append('valor', this.forma.get('valor').value);
    miBici.append('color', this.forma.get('color').value);
    miBici.append('thumbnail', this.forma.get('fileSource').value);
    miBici.append('observaciones', this.forma.get('observaciones').value);

    const upload$ = this.misbicisservice.setMiBiciv2(miBici);

    this.uploadSub = upload$.subscribe((event) => {
      if (event.type == HttpEventType.UploadProgress) {
        this.uploadMain = Math.round(100 * (event.loaded / event.total));
      }
      if (event.type == HttpEventType.ResponseHeader) {
        this.formloading = false;
        if (event.ok) {
          Swal.fire({
            text: 'Archivo subido correctamente.',
            title: '¡Correcto!',
            icon: 'info',
          }).then(() => {
            this.resetmain();
          });
        } else {
          Swal.fire({
            title: '¡Error!',
            text: 'Se ha producido un error al subir el archivo',
            icon: 'error',
          }).then(() => {
            this.resetmain();
          });
        }
      }
    });
  }

  addphoto(idtipo: string, idbici: number) {
    Swal.fire({
      title: 'Seleccionar foto del vehiculo',
      input: 'file',
      inputAttributes: {
        accept: 'image/png, image/jpeg',
        'aria-label': 'Upload your profile picture',
        //   capture: 'environment',
      },
    }).then((resp) => {
      const file: File = resp.value;
      if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append('idtipo', idtipo);
        formData.append('idbici', idbici.toString());
        formData.append('thumbnail', file);

        const uploads$ = this.http
          .post(`${environment.api_url}/mibici/bicifoto`, formData, {
            reportProgress: true,
            observe: 'events',
          })
          .pipe(finalize(() => this.reset(idbici)));
        // const upload$ = this.misbicisservice.setPhoto(formData, this.reset);

        this.uploadSub = uploads$.subscribe((event) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress[idbici] = Math.round(100 * (event.loaded / event.total));
          }
          if (event.type == HttpEventType.ResponseHeader) {
            if (event.ok) {
              Swal.fire({
                text: 'Archivo subido correctamente.',
                title: '¡Correcto!',
                icon: 'info',
              });
            } else {
              Swal.fire({
                title: '¡Error!',
                text: 'Se ha producido un error al subir el archivo',
                icon: 'error',
              });
            }
          }
        });
      }
    });
  }
  /*cancelUpload() {
    this.uploadSub.unsubscribe();
    //   this.reset();
  }*/

  delPhoto(idBici: number, idPhoto: number) {
    Swal.fire({
      title: '¿Desea eliminar la imagen?',
      text: 'Esto es una operación irreversible',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.misbicisservice.delPhoto(idBici, idPhoto).subscribe(() => {
          this.getListBicis();
        });
      }
    });
  }

  delBici(idbici: number) {
    Swal.fire({
      title: '¿Desea eliminar el vehiculo?',
      text: 'Esto es una operación irreversible',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.misbicisservice.delMiBici(idbici).subscribe(() => {
          this.crearFormulario();
          this.getListBicis();
        });
      }
    });
  }

  editBici(idbici: number) {
    this.crearFormulario(false);
    this.forma.patchValue(this.filterById(this.misbicis, idbici));
    this.edit = true;
  }

  filterById(jsonObject, id) {
    return jsonObject.filter(function (jsonObject) {
      return jsonObject['id'] == id;
    })[0];
  }

  reset(idbici: number) {
    this.uploadProgress[idbici] = null;
    this.uploadSub = null;
    this.getListBicis();
    this.forma.reset();
  }

  resetmain() {
    this.forma.reset();
    this.uploadMain = null;
    this.uploadSub = null;
    this.getListBicis();
  }
}
