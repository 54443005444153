import { Injectable } from '@angular/core';

export interface TimeSpan {
  hours: number;
  minutes: number;
  seconds: number;
}

@Injectable({
  providedIn: 'root',
})
export class ElapsedtimeService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  private decodeDate(date: string): Date {
    const dateTimeParts = date.split(/[- :]/).map(Number);

    dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one
    // const dateObject = new Date(...dateTimeParts);
    return new Date(dateTimeParts[0], dateTimeParts[1], dateTimeParts[2], dateTimeParts[3], dateTimeParts[4], dateTimeParts[5], 0);
  }

  getElapsedMinutes(date: string): number {
    return Math.floor(Math.floor((new Date().getTime() - this.decodeDate(date).getTime()) / 1000) / 60) + 1;
  }

  getElapsedTime(date: string): TimeSpan {
    let totalSeconds = Math.floor((new Date().getTime() - this.decodeDate(date).getTime()) / 1000);

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;
    // this.tiempopasado = hours + ':' + minutes + ':' + seconds;
    // return hours + ':' + minutes + ':' + seconds;
    return {
      hours,
      minutes,
      seconds,
    };
  }
}
