import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { DefaultRedParams, MiBici, Plaza, Redparams, RegisteredCard, Reparaciones, Suscripcion, TpvData, User } from 'src/app/core/models';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

//import { Subscriptions } from '../../core/models/subscriptions.model';
import { ParkingsService } from '../../core/services/parkings.service';
//import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import Swal, { SweetAlertOptions } from 'sweetalert2';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { environment } from 'src/environments/environment';
import { FirecodeService } from 'src/app/shared/firecode/firecode.service';

import { MisbicisService } from '../../core/services/misbicis.service';
import { forkJoin, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';
import { TypeCodeParkings } from '../../core/enum/serviciosparkings.enum';
import { Unescape } from 'src/app/core/unescape';
import { HomeService } from 'src/app/core/services/home.service';
import { DeviceDetectorService } from 'src/app/core/services/device-detector.service';
import { ReparacionService } from '../../core/services/reparacion.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit, OnDestroy {
  // @ViewChild('first Swal') private first Swal: SwalComponent;
  @ViewChild('registerCardSwal') private registerCardSwal: SwalComponent;
  @ViewChild('buyCreditSwal') private buyCreditSwal: SwalComponent;
  @ViewChild('regCardForm') formGroupElement;
  @ViewChild('fourSwal') private fourSwal: SwalComponent;

  status: boolean;
  plazasnumeradas: boolean;
  plaza: string;
  color: string;
  // loading: boolean;
  formUrl: string;
  misbicis: MiBici[] = [];
  public alertOption: SweetAlertOptions = {};

  private prevSelected: HTMLInputElement;
  tpvData: TpvData;
  formGroup: FormGroup;
  navigationSubscription;
  // registroobligatorio: Boolean;
  msgalert: string;
  txtbuttonok: string;
  txtbuttoncancel: string;
  txtswalheader: string;
  loadingStatus$: Observable<boolean>;
  loadingStatus = true;
  public typecode = TypeCodeParkings;
  public redparams: Redparams = DefaultRedParams;
  public reparaciones: Reparaciones[] = [];
  constructor(
    private loadingService: Loaderv2Service,
    private router: Router,
    private userService: UserService,
    private fb: FormBuilder,
    private homeservice: HomeService,
    private parkingsService: ParkingsService,
    protected firecode: FirecodeService,
    public readonly swalTargets: SwalPortalTargets,
    private misbicisservice: MisbicisService,
    private translate: TranslateService,
    private unscape: Unescape,
    private deviceService: DeviceDetectorService,
    private reparacionService: ReparacionService
  ) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
    //this.loading = true;
    translate.get(['userprofile.alert', 'userprofile.txtbuttonok', 'userprofile.txtbuttoncancel', 'userprofile.txtswalheader']).subscribe((res: string) => {
      this.msgalert = res['userprofile.alert'];
      this.txtbuttonok = res['userprofile.txtbuttonok'];
      this.txtbuttoncancel = res['userprofile.txtbuttoncancel'];
      this.txtswalheader = res['userprofile.txtswalheader'];
    });
    this.plazasnumeradas = false;
    this.plaza = '';
    this.color = '';
  }
  suscripcions: Suscripcion[];
  suscripcionesactivas: Suscripcion[] = [];
  forma: FormGroup<{
    nombre: FormControl<string>;
    apellidos: FormControl<string>;
    telefono: FormControl<string>;
    email: FormControl<string>;
    checkads: FormControl<boolean>;
  }>;
  user: User = {} as User;
  registeredcards: RegisteredCard[] = [];
  creditList = [];
  // passwordNotEqual: boolean;
  emailused: boolean;
  notify: string;

  emailmalformed: boolean;
  notchecked: boolean;
  formloading: boolean;

  code = 0;

  pulsating: boolean;
  speedpulse: boolean;
  timerStatus: boolean;
  idParkingSelected: number;
  idSubscriptionSelected: number;

  // modalRef: NgbModalRef;
  closeResult: string;

  text: string;
  confirmButtonText: string;
  editplaza: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedGroup: any;
  refreshUser;

  crearFormulario() {
    this.forma = this.fb.group({
      nombre: ['', [Validators.required]],
      apellidos: ['', [Validators.required]],
      telefono: ['', [Validators.required, Validators.minLength(9)]],
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,3}$')]],
      checkads: [false],
    });
  }
  unique: string[];
  plazas: Plaza[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupedArray: any[];

  cancelPlaza() {
    this.editplaza = false;
  }

  changePlaza(index: number) {
    const suscripcion = this.suscripcions[index];
    this.parkingsService.getPlazasLibres(suscripcion.idparking).subscribe({
      next: (result) => {
        this.plazas = result.msg;
        this.unique = [...new Set(this.plazas.map((item) => item.tipoplaza))];
        this.groupedArray = this.unique.map((i) => ({
          name: i,
          values: this.plazas.filter((d) => d.tipoplaza === i),
        }));
        this.selectedGroup = '0';
        this.editplaza = true;
      },
      error(err) {
        // console .log(err.msg);
        switch (err.msg) {
          case 'noplazaslibres':
            Swal.fire('¡No hay plazas libres!', `En este momento podemos asignarte una plaza, por favor pongase en contacto con el +34644771029.`, 'error');
            this.editplaza = false;
            break;
        }
      },
    });
  }
  selectPlaza(index: number) {
    if (this.selectedGroup !== undefined && this.selectedGroup > 0) {
      Swal.fire({
        title: this.translate.instant('userprofile.estasseguro'),
        //  text: this.text,
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: this.translate.instant('userprofile.cambiarplaza'),
        cancelButtonText: this.translate.instant('userprofile.cancel'),
      }).then((resp) => {
        if (resp.isConfirmed) {
          this.parkingsService.setPlaza(this.selectedGroup, index).subscribe({
            next: () => {
              // console .log(data);
              this.editplaza = false;
              this.loadData();
              Swal.fire('¡Actualizado!', `Sus datos han sido actualizados.`, 'success');
            },
          });
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.refreshUser) {
      this.refreshUser.unsubscribe();
    }
    //
  }
  ngOnInit(): void {
    this.homeservice.getRed().subscribe({
      next: (data) => {
        this.redparams = data.msg;
      },
    });
    this.editplaza = false;
    this.loadingStatus$ = this.loadingService.listenLoading();
    window.localStorage.removeItem('returnUrl');
    this.crearFormulario();
    this.pulsating = false;
    this.speedpulse = false;
    this.timerStatus = false;
    this.idParkingSelected = 0;
    this.idSubscriptionSelected = 0;
    this.formUrl = environment.tpv_url;
    this.refreshUser = this.userService.refreshUser.subscribe(() => {
      this.loadData();
    });
  }

  private loadData() {
    forkJoin([this.parkingsService.getUserSubscriptions(), this.userService.getRegisteredCards(), this.parkingsService.getCredits(), this.misbicisservice.getMisBicis(), this.userService.getCurrentUser(), this.reparacionService.getmisreparaciones()]).subscribe({
      next: (results) => {
        this.suscripcions = results[0].msg;
        this.suscripcionesactivas = this.suscripcions.filter((d) => d.status === 'activa');

        this.registeredcards = results[1].msg;
        results[2].msg.map((val) => {
          this.creditList[val[0]] = val[1] + '€';
        });

        this.misbicis = results[3].msg;

        //    Object.assign(this.user, );
        this.user = results[4].msg;
        this.reparaciones = results[5].msg;
        this.forma.patchValue(this.user);
        this.loadingStatus = false;

        if (this.user.trasteros === 0 && this.misbicis.length === 0 && (this.suscripcions.length > 0 || this.user.credito > 0)) {
          Swal.fire({
            title: this.txtswalheader,
            text: this.msgalert,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.txtbuttonok,
            cancelButtonText: this.txtbuttoncancel,
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/misbicis']);
            } else {
              //this.loading = false;
            }
          });
        } else {
          //this.loading = false;
        }
      },
      error: () => {
        this.router.navigate(['/login']);
      },
    });
  }
  getStatus(index: number): boolean {
    //   const subscription = this.subscriptions[index];
    return this.jsonHasKeyVal(this.user.movimientos.parkings, 'idsuscripcion', this.suscripcions[index].id);
  }

  setMovv1(index: number) {
    const subscription = this.suscripcions[index];
    const status = this.getStatus(index);
    if (status) {
      this.text = this.translate.instant('userprofile.segurofinal');
      this.confirmButtonText = this.translate.instant('userprofile.finalizarparking');
    } else {
      this.text = this.translate.instant('userprofile.seguroinicio');
      this.confirmButtonText = this.translate.instant('userprofile.iniciarparking');
    }
    this.ShowMsg(subscription.idsubscription);
    /* if (subscription.plazasnumeradas == 1) {
      if (subscription.plaza.idplaza == 0) {
        this.parkingsService.getPlazaLibreSuscripcion(subscription.idparking).subscribe({
          next: (data) => {
            //         console .log(data);
            this.ShowMsg(subscription.idsubscription);
          },
          error: (e) => {
            console.error(e);
          },
        });
      } else {
        this.ShowMsg(subscription.idsubscription);
      }
    } else {
      this.ShowMsg(subscription.idsubscription);
    }*/
  }
  setMovv2(index: number) {
    const subscription = this.suscripcions[index];
    this.status = this.getStatus(index);
    if (this.status) {
      this.text = this.translate.instant('userprofile.segurofinal');
      this.confirmButtonText = this.translate.instant('userprofile.finalizarparking');
    } else {
      this.text = this.translate.instant('userprofile.seguroinicio');
      this.confirmButtonText = this.translate.instant('userprofile.iniciarparking');
    }
    this.ShowMsg(subscription.idsubscription);
    /* if (subscription.plazasnumeradas == 1) {
      if (subscription.plaza.idplaza == 0) {
        this.parkingsService.getPlazaLibreSuscripcion(subscription.idparking).subscribe({
          next: (data) => {
            //         console .log(data);
            this.ShowMsg(subscription.idsubscription);
          },
          error: (e) => {
            console.error(e);
          },
        });
      } else {
        this.ShowMsg(subscription.idsubscription);
      }
    } else {
      this.ShowMsg(subscription.idsubscription);
    }*/
  }
  ShowMsg(idsuscripcion: number) {
    Swal.fire({
      title: this.translate.instant('userprofile.estasseguro'),
      text: this.text,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: this.confirmButtonText,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.parkingsService.getCodeKey(idsuscripcion, Serviciosparkings.PARKING_SUSCRIPCION).subscribe({
          next: (codekey) => {
            this.plazasnumeradas = codekey.msg.plazasnumeradas;
            if (this.plazasnumeradas) {
              this.plaza = codekey.msg.plaza;
              this.color = codekey.msg.color;
            }
            this.fourSwal.title = this.unscape.it(codekey.msg.nombre);
            this.userService.getCurrentUser().subscribe({
              next: (data) => {
                this.user = data.msg;
                this.forma.patchValue(this.user);
                this.fourSwal.fire();
              },
            });
          },
        });
      }
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setMov(idParking: number, status: boolean) {
    this.parkingsService.getPlazaLibrePorHoras(idParking).subscribe({
      next: () => {
        Swal.fire({
          title: this.translate.instant('userprofile.estasseguro'),
          text: this.text,
          icon: 'question',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: this.confirmButtonText,
          cancelButtonText: `Cancelar`,
        }).then(() => {
          this.parkingsService.getCodeKey(idParking, Serviciosparkings.PARKING_SUSCRIPCION).subscribe({
            next: () => {
              this.userService.getCurrentUser().subscribe({
                next: (data) => {
                  this.user = data.msg;
                  this.forma.patchValue(this.user);
                },
              });
            },
          });
        });
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  getNoValido(input: string) {
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }

  getCode(index: number) {
    const suscripcion = this.suscripcions[index];
    // this.firecode.getCode(idsuscripcion, Serviciosparkings.PARKING_SUSCRIPCION);

    switch (suscripcion.typecode) {
      case TypeCodeParkings.CODE_VAR:
        this.firecode.getCode(suscripcion.idsubscription, Serviciosparkings.PARKING_SUSCRIPCION);

        break;
      case TypeCodeParkings.CODE_FIXED:
        //this.firecode.getNoCode(suscripcion.idsubscription, Serviciosparkings.PARKING_SUSCRIPCION);
        this.setMovv2(index);
        break;
      default:
        break;
    }
  }

  save() {
    this.formloading = true;
    this.emailused = false;
    // this.passwordNotEqual = false;
    this.emailmalformed = false;
    this.notchecked = false;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }

    this.userService.update(this.forma.value).subscribe({
      next: () => {
        Swal.fire('¡Actualizado!', `Sus datos han sido actualizados.`, 'success');
        this.formloading = false;
      },
      error: (err) => {
        switch (err.msg) {
          case 'emailmalformed':
            this.emailmalformed = true;
            break;
          case 'notchecked':
            this.notchecked = true;
            break;
          case 'emailused':
            this.emailused = true;
            break;
          default:
            break;
        }
        this.formloading = false;
      },
    });
  }

  paySubscription(idSubscriptionSelected: number, index: number) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea renovar su subscripción en ${this.suscripcions[index].nombre}`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.value) {
        console.log(idSubscriptionSelected);
        this.parkingsService.getTpvDataRenew(idSubscriptionSelected).subscribe((data) => {
          //  const msg = data.msg;
          this.tpvData = data.msg.tpvData;
          this.setTpvData('Renovar abono');
          // console.log(msg);
          //      this.tpvData = msg.tpvData;
          /*    this.plan = msg.plan;
              this.precio = msg.precio;
              this.fechaini = msg.fechaini;
              this.fechafin = msg.fechafin;
              this.userService.getCurrentUser('finisparking->saveSubscription').subscribe({
                next: (data) => {
                  this.user = data.msg;
                  this.step = 2;
                },
              });*/
        });
        /*this.parkingsService.cancelSubscription(idSubscriptionSelected).subscribe({
           next: () => {
             this.suscripcions[index].status = 'cancelada';
             Swal.fire('¡Cancelada!', `Su subscripcion en ${this.suscripcions[index].nombre} ha sido cancelada.`, 'success');
           },
         });*/
      }
    });
  }

  cancelSubscription(idSubscriptionSelected: number, index: number) {
    Swal.fire({
      title: '¿Está seguro?',
      text: `Está seguro que desea cancelar su subscripción en ${this.suscripcions[index].nombre}`,
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.value) {
        this.parkingsService.cancelSubscription(idSubscriptionSelected).subscribe({
          next: () => {
            this.suscripcions[index].status = 'cancelada';
            Swal.fire('¡Cancelada!', `Su subscripcion en ${this.suscripcions[index].nombre} ha sido cancelada.`, 'success');
          },
        });
      }
    });
  }

  handleChange(evt) {
    const target: HTMLInputElement = evt.target;
    if (target.checked) {
      this.userService.activateRegisteredCard(this.registeredcards[target.value].id).subscribe({
        next: () => {
          //     alert('Tarjeta activada');

          this.registeredcards.map((val) => {
            // printing element
            val.userstatus = 0;
          });
          this.registeredcards[target.value].userstatus = 1;
        },
      });
      this.prevSelected = target;
    }
  }

  setTpvData(title: string) {
    this.formGroup = this.fb.group({
      Ds_Signature: [this.tpvData.Ds_Signature, Validators.required],
      Ds_SignatureVersion: [this.tpvData.Ds_SignatureVersion, Validators.required],
      Ds_MerchantParameters: [this.tpvData.Ds_MerchantParameters, Validators.required],
      cb: ['', Validators.requiredTrue],
    });
    this.alertOption = {
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `Cancelar`,
      title,
      //  showLoaderOnConfirm: true,
      //  focusCancel: true,
      preConfirm: () => {
        console.log(this.formGroup.invalid);
        if (this.formGroup.invalid) {
          this.formGroup.markAllAsTouched();
          return false;
        }
        this.formGroupElement.nativeElement.submit();
        return true;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };
    this.registerCardSwal.swalOptions = this.alertOption;

    this.registerCardSwal.fire();
  }

  registrarTarjeta() {
    this.parkingsService.getTpvDataRegCard().subscribe({
      next: (data) => {
        // const msg = data.msg;
        // ncons ole.log(data.msg);
        this.tpvData = data.msg.tpvData;
        this.setTpvData('Registrar Tarjeta');
      },
    });
  }

  get cbNoValido() {
    return this.formGroup.get('cb').invalid && this.formGroup.get('cb').touched;
  }

  recargarSaldo() {
    Swal.fire({
      title: 'Recargar saldo',
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `Cancelar`,
      input: 'select',
      html: '<label for="idcredito">Seleccione la cantidad</label>',

      inputAttributes: {
        id: 'idcredito',
      },
      inputOptions: this.creditList,
    }).then((result) => {
      // con sole.log(result);
      if (result.isConfirmed) {
        this.parkingsService.getTpvDataCredits(Number(result.value)).subscribe({
          next: (data) => {
            //    con sole.log(data);
            this.tpvData = data.msg.tpvData;
            // con sole.log(data.msg);
            this.setTpvData('Compra de saldo');
          },
        });
      }
    });
  }

  redirect() {
    this.router.navigateByUrl('/mapa');
  }

  finishParking() {
    this.router.navigateByUrl('/finish');
  }

  jsonHasKeyVal(json, keyname, value): boolean {
    return Object.keys(json).some((key) => (typeof json[key] === 'object' && json[key] !== null ? this.jsonHasKeyVal(json[key], keyname, value) : key === keyname && json[key] === value));
  }

  isExpirationExpired(date: string): boolean {
    const hoy = new Date();
    const acaba = new Date(date);

    return hoy > acaba ? false : true;
  }

  getMecanico(idmecanico: number, número: string, idparking: number) {
    Swal.fire({
      title: '¿Necesita los servicios de nuestro mecanico?',
      text: ' ¡Ponte en contacto con él!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      cancelButtonText: `Cancelar`,
    }).then((result) => {
      // con sole.log(result);
      if (result.isConfirmed) {
        this.parkingsService.setReparacion(idmecanico, idparking).subscribe({
          next: () => {
            const _url = this.deviceService.isMobile() ? `https://wa.me/${número}&text=Hola!` : `http://web.whatsapp.com/send?phone=${número}&abid=${número}&text=Hola!`;
            window.open(_url, '_blank');
          },
        });
      }
    });
  }
}
