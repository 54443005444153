import { Component, OnInit } from '@angular/core';
import { MiBici } from '../../core/models/misbicis.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from '../../core/models/user.model';
import { Suscripcion } from '../../core/models/subscriptions.model';
import { environment } from 'src/environments/environment';
import { MisbicisService } from '../../core/services/misbicis.service';
import { ParkingsService } from '../../core/services/parkings.service';
import { UserService } from 'src/app/core/services/user.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import { fromLonLat } from 'ol/proj';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  loading: boolean;
  adding: boolean;
  misbicis: MiBici[] = [];
  forma: UntypedFormGroup;
  formloading: boolean;
  fileName = '';
  uploadProgress: number[] = [];
  uploadMainPhoto: number;
  uploadSub: Subscription;
  user: User = {} as User;
  subscriptions: Suscripcion[] = [];
  url = environment.api_url;

  test = `eoooeo`;

  constructor(private fb: UntypedFormBuilder, private misbicisservice: MisbicisService, private http: HttpClient, private userService: UserService, private parkingsService: ParkingsService) {}

  ngOnInit(): void {
    console.log(fromLonLat([-1.0959, 41.6021]));
    console.log(fromLonLat([-0.7007, 41.7024]));
    //  window.localStorage.removeItem('returnUrl');
    // this.getSuscriptions();
  }
  getSuscriptions() {
    this.parkingsService.getUserSubscriptions().subscribe((data) => {
      console.log(data.msg);
    });
  }
  getListBicis() {
    this.misbicisservice.getMisBicis().subscribe((data) => {
      this.misbicis = data.msg;
      this.uploadProgress.length = this.misbicis.length;
      this.loading = false;
    });
  }

  crearFormulario() {
    this.forma = this.fb.group({
      marca: ['', [Validators.required, Validators.minLength(1)]],
      modelo: ['', [Validators.required, Validators.minLength(1)]],
      color: ['', [Validators.required, Validators.minLength(1)]],
      thumbnail: ['', [Validators.required]],
      fileSource: ['', [Validators.required]],
      serie: [''],
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.forma.patchValue({
        fileSource: file,
      });
    }
  }

  getNoValido(input: string) {
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }

  save() {
    this.formloading = true;

    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }
    const miBici = new FormData();
    miBici.append('marca', this.forma.get('marca').value);
    miBici.append('modelo', this.forma.get('modelo').value);
    miBici.append('color', this.forma.get('color').value);
    miBici.append('thumbnail', this.forma.get('fileSource').value);
    miBici.append('serie', this.forma.get('serie').value);

    const upload$ = this.misbicisservice.setMiBiciv2(miBici);

    this.uploadSub = upload$.subscribe((event) => {
      if (event.type == HttpEventType.UploadProgress) {
        this.uploadMainPhoto = Math.round(100 * (event.loaded / event.total));
      }
      if (event.type == HttpEventType.ResponseHeader) {
        this.formloading = false;
        if (event.ok) {
          Swal.fire({
            text: 'Archivo subido correctamente.',
            title: '¡Correcto!',
            icon: 'info',
          }).then(() => {
            this.getListBicis();
          });
        } else {
          Swal.fire({
            title: '¡Error!',
            text: 'Se ha producido un error al subir el archivo',
            icon: 'error',
          }).then(() => {
            this.getListBicis();
          });
        }
      }
    });

    // formData.append('thumbnail', this.forma.get('fileSource').value);
    /*  const mibici = this.forma.value;

    this.misbicisservice.setMiBici(mibici).subscribe(
      (data) => {
        Swal.fire({
          title: '¡Actualizado',
          text: 'Sus datos han sido actualizados.!',
          icon: 'success',
        }).then((resp) => {
          this.getListBicis();
        });

        this.formloading = false;
      },
      (err) => {
        // this.errors = err;

        this.formloading = false;
      }
    );*/
  }

  addphoto(idtipo: string, idbici: number) {
    Swal.fire({
      title: 'Seleccionar foto del vehiculo',
      input: 'file',
      inputAttributes: {
        accept: 'image/png, image/jpeg',
        'aria-label': 'Upload your profile picture',
        //   capture: 'environment',
      },
    }).then((resp) => {
      const file: File = resp.value;
      if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append('idtipo', idtipo);
        formData.append('idbici', idbici.toString());
        formData.append('thumbnail', file);

        const uploads$ = this.http
          .post(`${environment.api_url}/mibici/bicifoto`, formData, {
            reportProgress: true,
            observe: 'events',
          })
          .pipe(finalize(() => this.reset(idbici)));
        //       const uploads$ = this.misbicisservice.setPhoto(formData, this.reset);

        this.uploadSub = uploads$.subscribe((event) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress[idbici] = Math.round(100 * (event.loaded / event.total));
          }
          if (event.type == HttpEventType.ResponseHeader) {
            if (event.ok) {
              Swal.fire({
                text: 'Archivo subido correctamente.',
                title: '¡Correcto!',
                icon: 'info',
              });
            } else {
              Swal.fire({
                title: '¡Error!',
                text: 'Se ha producido un error al subir el archivo',
                icon: 'error',
              });
            }
          }
        });
      }
    });
  }
  /*cancelUpload() {
    this.uploadSub.unsubscribe();
    //   this.reset();
  }*/

  delPhoto(idBici: number, idPhoto: number) {
    Swal.fire({
      title: '¿Desea eliminar la imagen?',
      text: 'Esto es una operación irreversible',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.misbicisservice.delPhoto(idBici, idPhoto).subscribe(() => {
          this.getListBicis();
        });
      }
    });
  }

  delBici(idbici: number) {
    Swal.fire({
      title: '¿Desea eliminar el vehiculo?',

      text: 'Esto es una operación irreversible',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
    }).then((resp) => {
      if (resp.isConfirmed) {
        this.misbicisservice.delMiBici(idbici).subscribe(() => {
          this.getListBicis();
        });
      }
    });
  }

  reset(idbici: number) {
    this.uploadProgress[idbici] = null;
    this.uploadSub = null;
    this.getListBicis();
  }

  resetmain() {
    this.uploadMainPhoto;
    this.uploadSub = null;
    this.getListBicis();
  }
}
