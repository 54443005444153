import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { TestComponent } from './pages/test/test.component';
import { ParkingsComponent } from './pages/parkings/parkings.component';

import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AuthGuard } from './core/services/auth.guard';

import { ReciboComponent } from './pages/recibo/recibo.component';
import { ErrorComponent } from './pages/error/error.component';

import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { UserEditComponent } from './pages/user-edit/user-edit.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { RecoveryComponent } from './pages/recovery/recovery.component';
import { ResumeComponent } from './pages/resume/resume.component';
import { FinishparkingComponent } from './pages/finishparking/finishparking.component';
import { MisbicisComponent } from './pages/misbicis/misbicis.component';
import { HomeLayoutComponent } from './core/layouts/home-layout/home-layout.component';
import { MapLayoutComponent } from './core/layouts/map-layout/map-layout.component';
import { ParkingdetalleComponent } from './pages/parkingdetalle/parkingdetalle.component';
import { Mapav2Component } from './pages/mapav2/mapav2.component';

import { FaqComponent } from './pages/faq/faq.component';
import { UserService } from './core/services/user.service';
import { map } from 'rxjs/operators';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { ReparacionesComponent } from './pages/reparaciones/reparaciones.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'recibo/:idred', component: ReciboComponent, canActivate: [AuthGuard] },
      { path: 'reparaciones/:idreparacion', component: ReparacionesComponent, canActivate: [AuthGuard] },
      { path: 'error', component: ErrorComponent, canActivate: [AuthGuard] },
      {
        path: 'finish',
        component: FinishparkingComponent,
        canActivate: [AuthGuard],
      },
      { path: 'resume', component: ResumeComponent, canActivate: [AuthGuard] },
      /* {
        path: 'login',
        component: AuthComponent,
        canActivate: [NoAuthGuard],
      },*/
      {
        path: 'login',
        loadComponent: () => import('./auth/auth.component').then((m) => m.AuthComponent),
        canActivate: [() => inject(UserService).isAuthenticated.pipe(map((isAuth) => !isAuth))],
      },
      {
        path: 'register',
        loadComponent: () => import('./auth/auth.component').then((m) => m.AuthComponent),
        canActivate: [() => inject(UserService).isAuthenticated.pipe(map((isAuth) => !isAuth))],
      },
      /*{
        path: 'register',
        component: AuthComponent,
        canActivate: [NoAuthGuard],
      },*/
      {
        path: 'misbicis',
        component: MisbicisComponent,
        canActivate: [AuthGuard],
      },

      { path: 'test', component: TestComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'forgot', component: ForgotComponent },
      { path: 'recovery', component: RecoveryComponent },
      { path: 'terms', component: TermsComponent },
      { path: 'privacy', component: PrivacyComponent },
      { path: 'cookies', component: CookiesComponent },
      {
        path: 'perfil',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'password',
        component: UserEditComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
      },

      // { path: 'parking/:id', component: ParkingsComponent },
      { path: 'parking', pathMatch: 'full', redirectTo: 'mapa' },
      //  { path: 'parking/:id/:type', component: ParkingsComponent },
      { path: 'parking/:id/:type', component: ParkingsComponent },
      { path: 'detalle', pathMatch: 'full', redirectTo: 'mapa' },
      { path: 'detalle/:id', component: ParkingdetalleComponent },
      /*********V1 */
      // { path: 'mapa', component: MapComponent },
      //{ path: 'mapa/:id', component: MapComponent },
      //  { path: '', pathMatch: 'full', redirectTo: 'home' },
      //{ path: '**', pathMatch: 'full', component: HomeComponent },
    ],
  },
  /*****v2*/
  {
    path: '',
    component: MapLayoutComponent,
    children: [
      { path: 'mapa', component: Mapav2Component },
      { path: 'mapa/:id', component: Mapav2Component },
    ],
  },
  { path: '**', pathMatch: 'full', component: HomeComponent },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // useHash: true,
      enableTracing: false,
      //relativeLinkResolution: 'legacy',
      //onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
