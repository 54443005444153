export class Notification {
  constructor(
    //  public id: number,
    public type: NotificationType,
    public title: string,
    public message: string,
    public url: string
  ) {
    // con sole.log(this.url);
  }
}

export enum NotificationType {
  success = 0,
  warning = 1,
  error = 2,
  info = 3,
  none = 4,
  custom = 5,
  code = 6,
  nocode = 7,
}
