import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidadoresService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  public passwordIguales(pass1: string, pass2: string): ValidatorFn {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
      const pass1Control = formGroup.controls[pass1];
      const pass2Control = formGroup.controls[pass2];
      if (pass1Control.value === pass2Control.value) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEsIgual: true });
      }
      return;
    };
  }
}
