 <button class="{{nClass}}" [ngClass]="{'btn-secondary': idServicio === eServiciosparkings.PARKING_PORHORAS,'btn-primary': idServicio ===eServiciosparkings.PARKING_SUSCRIPCION}" [disabled]="active">
  <ng-container *ngIf="active; else isBtnActiveTemplate">
    <span class="bp-item__button-inner">{{'map.plazas' | translate}}</span>
    <span class="bp-item__button-inner">{{'map.agotadas' | translate}}</span>
  </ng-container>
  <ng-template #isBtnActiveTemplate>
    <span class="bp-item__button-inner">1 {{tiempo}} <span>{{ 'map.desde' | translate }}</span></span>
    <span class="bp-item__button-inner">{{pmes}} <span>€/{{tiempo}}</span></span>
  </ng-template>

</button>
<!--bp-item__button btn btn-sm-->
