export interface Redparams {
  nombre: string;
  longitud: number;
  latitud: number;
  minx: number;
  miny: number;
  maxx: number;
  maxy: number;
  municipio: string;
  whatsapp?: string;
  facebook?: string;
  pinterest?: string;
  instagram?: string;
  telefono?: string;
  horario?: string;
  email?: string;
  idfilelogo: number;
  idfilefondodesktop: number;
  idfilefondomobile: number;
  idfileseccion2: number;
  idfileaparcabicis: number;
  idred: number;
}

export const DefaultRedParams: Redparams = {
  nombre: '',
  latitud: 0,
  longitud: 0,
  maxx: 0,
  maxy: 0,
  minx: 0,
  miny: 0,
  municipio: '',
  facebook: '',
  horario: '',
  instagram: '',
  pinterest: '',
  telefono: '',
  whatsapp: '',
  email: '',
  idfilelogo: 0,
  idfilefondodesktop: 0,
  idfilefondomobile: 0,
  idfileseccion2: 0,
  idfileaparcabicis: 0,
  idred: 0,
};

export interface msgRedparams {
  msg: Redparams;
}

export interface RedStyle {
  id: number;
  nombre: string;
  color: string;
}
export interface RedStyles {
  msg: RedStyle[];
}
export interface RedSeccion {
  quienessomos: boolean;
  opiniones: boolean;
  icono: boolean;
  faqtrasteros: boolean;
  bannerblanco: boolean;
}
export interface RedSecciones {
  msg: RedSeccion;
}
export const DefaultRedSeccion: RedSeccion = {
  quienessomos: false,
  opiniones: false,
  icono: false,
  faqtrasteros: false,
  bannerblanco: true,
};
