<div class="container-sm justify-content-center" *ngIf="(loadingStatus$ |async )">
  <app-loading class="m-5"></app-loading>
</div>
@if ((loadingStatus$ |async ) === false && !(loadingStatus)) {
<div [ngClass]="{ 'invisible':loadingStatus , 'animate__animated':true,'animate__fadeIn': !loadingStatus }">
  <div class="container-sm justify-content-center bp-section--main">
    <div class="row header-biciparc mx-0">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="nombre_parking">Reparación</h5>
          </div>
          <div>

          </div>
        </div>
      </div>
    </div>

    <div #allTheseThings class="row mx-0 mt-1 login-box-body">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
        <h4 class="text-center">Reparación</h4>

        <!--//TODO:Arreglar plan contratado-->
        <form #form action="{{formUrl}}" method="post">
          <p>Precio: {{ (precio) | number: '1.0-2'}} € </p>
          <p>A nombre de: {{ user.nombre}} {{user.apellidos}} </p>
          <p>Concepto: {{concepto}}</p>

          <input type="hidden" name="Ds_MerchantParameters" value="{{ tpvData.Ds_MerchantParameters}}" />
          <input type="hidden" name="Ds_Signature" value="{{ tpvData.Ds_Signature}}" />
          <input type="hidden" name="Ds_SignatureVersion" value="{{ tpvData.Ds_SignatureVersion}}" />
          <div class="d-grid gap-2">
            <button *appIsAuth="true" type="submit" class="btn btn-primary f5" (click)="form.submit()">
              <span *ngIf="true">Pagar reparación</span>
              <i class [ngClass]="{ 'f5 fas fa-sync fa-spin': false }"></i>
            </button>
          </div>

        </form>

      </div>
    </div>
  </div>

</div>
}
