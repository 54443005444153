import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultRedParams, Parking, Redparams } from 'src/app/core/models';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-detalleparking',
  templateUrl: './detalleparking.component.html',
  styleUrls: ['./detalleparking.component.scss'],
})
export class DetalleparkingComponent implements OnInit {
  private emptyParking: Parking = {
    acceso: '',
    caracteristicas: [],
    direccion: '',
    fotos: [],
    horario: '',
    id: 0,
    latitud: 0,
    llegar: '',
    longitud: 0,
    lugares: '',
    nombre: '',
    plazas: 0,
    reservas: 0,
    texto: '',
    typecode: 0,
    uso: { usadas: 0, suscripciones: 0, porhoras: 0 },
    hasmecanico: 0,
    mecanicophone: '',
  };
  @Input() public parking: Parking;
  eServiciosparkings = Serviciosparkings;
  url = environment.api_url;
  public redparams: Redparams = DefaultRedParams;
  constructor(private _router: Router, private homeservice: HomeService) {}

  ngOnInit(): void {
    // this will concate both the objects and the object declared later (ie.. ...this.newCar )
    // will overwrite the default value. ONLY AND ONLY IF DEFAULT VALUE IS PRESENT

    this.parking = { ...this.emptyParking, ...this.parking };
    this.homeservice.getRed().subscribe({
      next: (data) => {
        this.redparams = data.msg;
      },
    });
  }

  bntClick(type: number, idParking: number) {
    if (this.isBtnActive(type)) {
      this._router.navigate([`/parking/${idParking}/${type}`]);
    }
  }
  isBtnActive(type: number) {
    const reservas = this.parking.reservas;
    const suscripciones = this.parking.uso.suscripciones;
    const porhoras = this.parking.uso.porhoras;
    const plazas = this.parking.plazas;
    if (Serviciosparkings.PARKING_PORHORAS == type) {
      return plazas - (suscripciones + porhoras) < 1 ? false : true;
    }
    if (Serviciosparkings.PARKING_SUSCRIPCION == type) {
      //      22          10              3
      return plazas - (suscripciones + Math.max(reservas, porhoras)) < 1 ? false : true;
    }
    return true;
  }
}
