<div class="container-sm justify-content-center bp-section--main">
  <div class="row">
    <div class="col mx-3" style="background-color: #f8ffe0;">
      <section id="legal">
        <markdown [data]="terms" [disableSanitizer]="true">
        </markdown>
      </section>
    </div>
  </div>
</div>
