import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { HomeService } from 'src/app/core/services/home.service';
import { DefaultRedParams, Redparams } from 'src/app/core/models';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  public redparams: Redparams = DefaultRedParams;
  constructor(private router: ActivatedRoute, private api: ApiService, private route: Router, private homeservice: HomeService) {}

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    /* this.router.queryParams.subscribe((params) => {
      const parameters = new HttpParams({ fromObject: params });
      this.api.get('/noti/errortpv', parameters).subscribe({
        next: () => {

        },
      });
    });*/
    this.homeservice.getRed().subscribe({
      next: (data) => {
        this.redparams = data.msg;
        this.fireRegError();
      },
    });
  }

  fireRegError() {
    Swal.fire({
      title: 'Error',
      html: `<p>No se ha podido realizar la reserva.</p>
      <p class="card-text">Puedes solicitar información llamando al <a
          href="tel:${this.redparams.telefono}" class="text-dark">${this.redparams.telefono}</a></p>
      <p class="card-text">O escribiendo a <a href="mailto:${this.redparams.email}"
          class="text-dark">${this.redparams.email}</a></p>`,
      icon: 'error',
    }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }
}
