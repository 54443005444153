import { Component, Input } from '@angular/core';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';

@Component({
  selector: 'app-pricebutton',
  templateUrl: './pricebutton.component.html',
})
export class PricebuttonComponent {
  eServiciosparkings = Serviciosparkings;

  @Input() idServicio: number;
  @Input() idParking: number;
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Input() nClass: string;
  @Input() tiempo: string;
  @Input() pmes: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}
