import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Loaderv2Service {
  private subject$ = new BehaviorSubject<boolean>(false);
  //private subjectDOM$ = new BehaviorSubject<boolean>(false);
  //private subjectFULL$ = new BehaviorSubject<boolean>(false);
  //private subjectBody$ = new BehaviorSubject<boolean>(false);
  constructor() {
    /*  document.addEventListener('readystatechange', (event) => {
      // When HTML/DOM elements are ready:

      if (event.target['readyState'] === 'complete') {
        this.hideDOM();
      }
    });*/
  }
  /*showDOM(): void {
    //console. log('showDOM');
    this.subjectDOM$.next(true);
    this.subjectFULL$.next(true);
  }
  hideDOM(): void {
    //console .log('hideDOM');
    this.subjectDOM$.next(false);
  }*/

  show(): void {
    //console .log('show');
    this.subject$.next(true);
    //  this.subjectFULL$.next(true);
  }
  hide(): void {
    //console .log('hide');
    setTimeout(() => {
      this.subject$.next(false);
    }, 1000);
  }
  /*showBody(): void {
    //console .log('showBody');
    this.subjectBody$.next(true);
  }
  hideBody(): void {
    //console .log('hideBody');
    this.subjectBody$.next(false);
  }*/
  listenLoading(): Observable<boolean> {
    //console. log('listenLoading');
    /*  zip(this.subject$, this.subjectDOM$).subscribe(([http$, dom$]) => {
      console .log('http:' + !http$ + ' dom:' + !dom$);
      if (!http$ && !dom$) {
        this.subjectFULL$.next(false);
      }
    });
    //return this.subjectFULL$.asObservable();*/
    return this.subject$.asObservable();
  }
  /*
  listenBodyLoading(): Observable<boolean> {
    return this.subjectBody$.asObservable();
  }*/
}
