 <div class="bp-item bp-item--parking2">
  <div class="">
    <div class="offset-lg-2 col-lg-8">
      <div class="bp-item__header">
        <div class="row">
          <div class="col-xl-5">
            <div class="bp-item__main">
              <div class="bp-item__heading">
                <h1 class="bp-item__title">{{ parking.nombre | unescape | trim}}</h1>
                <p class="bp-item__address">{{parking.direccion | trim}}</p>
              </div>
              <div class="row mb-4 mb-xl-0">
                <div class="col-7 col-xl-12">
                  <div class="bp-item__access">
                    <p class="bp-item__access-title">{{ 'map.sistemadeacceso' | translate }}</p>
                    <p class="bp-item__access-description">{{ parking.acceso | trim}}</p>
                  </div>
                </div>
                <div class="bp-item__schedule d-none d-xl-block">
                  <ng-container *ngFor="let hora of parking.horario | splitnewline">
                    <p>{{hora}}</p>
                  </ng-container>
                </div>
                <div class="col-5 col-xl-12">
                  <div class="bp-item__buttons">
                    <ng-container *ngFor="let servicio of parking.servicios">
                      <ng-container *ngIf="servicio.planes.length> 0">
                        <app-pricebutton (click)="bntClick(servicio.id,parking.id)" [nClass]="'bp-item__button btn btn-sm'" [idServicio]="servicio.id" [active]="!isBtnActive(servicio.id)" [pmes]="servicio.pmes" [tiempo]="servicio.tiempo"></app-pricebutton>

                        <button *ngIf="false" (click)="bntClick(servicio.id,parking.id)" title="1 {{servicio.tiempo}} desde {{ servicio.pmes}} €/{{ servicio.tiempo}}" class="bp-item__button btn btn-sm" [ngClass]="{'btn-secondary': servicio.id === eServiciosparkings.PARKING_PORHORAS,'btn-primary': servicio.id
                          ===eServiciosparkings.PARKING_SUSCRIPCION}">

                          <ng-container *ngIf="!isBtnActive(servicio.id); else isBtnActiveTemplate">
                            <span class="bp-item__button-inner">{{'map.plazas' | translate}}</span>
                            <span class="bp-item__button-inner">{{'map.agotadas' | translate}}</span>
                          </ng-container>
                          <ng-template #isBtnActiveTemplate>
                            <span class="bp-item__button-inner">1 {{servicio.tiempo}} <span>{{ 'map.desde' | translate }}</span></span>
                            <span class="bp-item__button-inner">{{ servicio.pmes}} <span>€/{{ servicio.tiempo}}</span></span>
                          </ng-template>


                        </button>

                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="bp-item__schedule d-xl-none">
                <ng-container *ngFor="let hora of parking.horario | splitnewline">
                  <p>{{hora}}</p>
                </ng-container>
              </div>
            </div>

          </div>
          <div class="col-xl-7 align-self-end">
            <div class="bp-item__map">
              <div class="ratio ratio-16x9">

                <app-mapa [parking]="parking"></app-mapa>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bp-item__body">
    <div class="bp-item__facilities">
      <ng-container>

        <div class="bp-item__facilities-item" *ngFor="let caracteristica of parking.caracteristicas; let z=index">
          <div class="bp-item__facilities-image">
            <img [attr.src]="url + caracteristica.physicalfilename" alt="{{redparams.nombre}} - {{caracteristica.descripcion}}" title="{{caracteristica.descripcion}}">
          </div>
          <p class="bp-item__facilities-title text-start">{{ caracteristica.caracteristica | translate }}</p>
        </div>
      </ng-container>





    </div>




  </div>
</div>
