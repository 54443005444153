import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emitter: ReplaySubject<any> = new ReplaySubject(1);

  emitChangeEvent(data) {
    this.emitter.next(data);
  }
}
