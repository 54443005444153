<div class="container-sm justify-content-center" *ngIf="(loadingStatus$ | async )">
  <app-loading class="m-5"></app-loading>
</div>

<div [ngClass]="{ 'invisible':(loadingStatus$ | async ) , 'animate__animated':true,'animate__fadeIn': (loadingStatus$ | async )===false}">
  <section class="bp-section bp-section--hero bp-section--hero-home">
    <div class="container-fluid p-0">
      <div class="bp-hero bp-hero--home">
        <div class="bp-hero__content">
          <div class="row align-items-end justify-content-between">
            <div class="col-md-6 col-lg-5 col-xl-4 col-xxl-3">
              <div class="mb-1 mb-md-0">
                <h1 class="bp-hero__title" [innerHTML]="('home.titulo' | translate)+ redparams.municipio">{{ redparams.municipio }}</h1>

                <div class="bp-hero__button d-none d-md-block">
                  <a title="{{ 'home.registrate' | translate }}" class="btn btn-secondary" routerLink="/register" tabindex="-1" role="button" aria-disabled="true">Regístrate</a>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4 col-xxl-3">
              <p class="bp-hero__subtitle">{{ 'home.subtitle' | translate }}</p>
              <div class="bp-list bp-list--steps">
                <div class="bp-list__item">
                  <p class="bp-list__number">01</p>
                  <div class="bp-list__content">
                    <p class="bp-list__title">{{ 'home.donde' | translate }}</p>
                    <p class="bp-list__description">{{ 'home.eligedonde' | translate }}</p>
                  </div>
                </div>
                <div class="bp-list__item">
                  <p class="bp-list__number">02</p>
                  <div class="bp-list__content">
                    <p class="bp-list__title">{{ 'home.duracion' | translate }}</p>
                    <p class="bp-list__description">{{ 'home.eligepor' | translate }}</p>
                  </div>
                </div>
                <div class="bp-list__item">
                  <p class="bp-list__number">03</p>
                  <div class="bp-list__content">
                    <p class="bp-list__title">{{ 'home.aparca' | translate }}</p>
                    <p class="bp-list__description">{{ 'home.registayaparca' | translate }}</p>
                  </div>
                </div>
              </div>
              <div class="bp-hero__button">
                <a [routerLink]="['/mapa']" title="{{ 'home.reserva' | translate }}" class="btn btn-primary" tabindex="-1" role="button" aria-disabled="true">{{ 'home.reserva' | translate }}</a>
              </div>
              <div class="bp-hero__button d-md-none">
                <a title="{{ 'home.registrate' | translate }}" class="btn btn-secondary" routerLink="/register" tabindex="-1" role="button" aria-disabled="true">{{ 'home.registrate' | translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bp-section bp-section--default bp-section--no-overflow bp-section--where">
    <div class="container-fluid">
      <div class="bp-heading bp-heading--main">
        <p class="bp-heading__number">01</p>
        <h2 class="bp-heading__title">{{ 'home.dondeaparcar' | translate }}</h2>
      </div>
      <div class="bp-card bp-card--places" *ngIf="parkings.length>1">
        <div class="bp-card__grid">

          <ng-container *ngFor="let parking of parkings">
            <div class="bp-card__item" (click)="clickParking(parking.id)">
              <div class="bp-card__container">
                <img *ngIf="false" [attr.src]="url+'/api/getHomeImage/' + parking.uuid" alt="{{redparams.nombre}} - Aparcamiento {{ parking.nombre | unescape }}" class="bp-card__image">
                <img [attr.src]="url + parking.physicalfilename" alt="{{redparams.nombre}} - Aparcamiento {{ parking.nombre | unescape}}" class="bp-card__image">
                <div class="bp-card__centered">{{ parking.nombre | unescape}}</div>
              </div>



              <div class="bp-card__inner d-none d-sm-block">

                <div class="bp-card__back">
                  <div class="bp-card__content">

                    <p class="bp-card__title">{{ parking.nombre | unescape }}</p>
                    <div class="bp-card__description">

                      <p>{{ parking.texto }}</p>

                    </div>

                  </div>
                </div>

              </div>
            </div>

          </ng-container>
          <div class="bp-card__button">
            <a [routerLink]="['/mapa']" title="{{ 'home.masubicaciones' | translate }}" class="btn btn-primary btn-xl btn-with-shadow" tabindex="-1" role="button" aria-disabled="true">
              {{ 'home.masubicaciones' | translate }}
            </a>
          </div>
        </div>

      </div>
      <div class="bp-card" *ngIf="parkings.length===1">
        <div class="bp-card__grid" style="background-color: var(--FA2);">
          <app-detalleparking [parking]="parkings[0]"></app-detalleparking>
        </div>
      </div>
    </div>
  </section>

  <section class="bp-section bp-section--default bp-section--no-overflow bp-section--duration">
    <div class="container-fluid p-0">
      <div class="bp-heading bp-heading--main">
        <p class="bp-heading__number">02</p>
        <h2 class="bp-heading__title">{{ 'home.duracion' | translate }}</h2>
      </div>
      <div class="bp-banner bp-banner--duration">
        <div class="bp-banner__content">
          <div class="bp-banner__header">
            <h3 class="bp-banner__title">{{ 'home.seacualsea' | translate }}</h3>
            <p class="bp-banner__description">{{ 'home.tenemos' | translate }}</p>
          </div>
          <div class="bp-banner__buttons">
            <div class="bp-banner__button">

              <a [routerLink]="[ '/mapa' ]" title="{{ 'home.porhoras' | translate }}" class="btn btn-lg btn-secondary" tabindex="-1" role="button" aria-disabled="true">
                {{ 'home.porhoras' | translate }}
              </a>
            </div>
            <div class="bp-banner__button">
              <a [routerLink]="[ '/mapa' ]" title="{{ 'home.abonos' | translate }}" class="btn btn-lg btn-primary" tabindex="-1" role="button" aria-disabled="true">{{ 'home.abonos' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bp-section bp-section--default bp-section--features">
    <div class="container-fluid p-md-0">
      <div class="bp-heading bp-heading--image">
        <img src="assets/img/text-bike-your-life.png" alt="{{redparams.nombre}} - Bike your life & park the rest" class="img-fluid">
      </div>
      <div class="bp-features bp-features--ico">
        <div class="bp-features__grid">
          <div class="bp-features__item">
            <img src="assets/img/feature-schedule.png" alt="{{redparams.nombre}} - Abierto 24 horas 365 días" *ngIf="true">

          </div>
          <div class="bp-features__item">
            <img src="assets/img/feature-cameras.png" alt="{{redparams.nombre}} - Cámaras de vigilancia de CCTV">
          </div>
          <div class="bp-features__item">
            <img src="assets/img/feature-alarm.png" alt="{{redparams.nombre}} - Alarma">
          </div>
          <div class="bp-features__item">
            <img src="assets/img/feature-inssurancev2.png" alt="{{redparams.nombre}} - Seguro hasta 3.000€" style="background-color: var(--FF1);">

          </div>
          <div class="bp-features__item">
            <img src="assets/img/feature-padlock.png" alt="{{redparams.nombre}} - Fácil colocación de candado">
          </div>
          <div class="bp-features__item">
            <img src="assets/img/feature-tools.png" alt="{{redparams.nombre}} - Herramientas de reparación">
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bp-section bp-section--default pb-0 bp-section--parking" id="unete">
    <div class="container-fluid p-0">
      <div class="bp-banner bp-banner--parking">
        <div class="bp-banner__contentleft"></div>
        <div class="bp-banner__content">
          <div class="bp-banner__header">
            <h3 class="bp-banner__title">{{ 'home.tienes' | translate }}</h3>
            <p class="bp-banner__description">{{ 'home.unete' | translate }}</p>

          </div>
          <div class="bp-banner__buttons">
            <div class="bp-banner__button">

              <a title="{{ 'home.contacta' | translate }}" href="mailto:hola@biciparc.com" class="btn btn-lg btn-primary btn-with-shadow" tabindex="-1" role="button" aria-disabled="true">
                {{ 'home.contacta' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bp-section bp-section--somos bp-section--somos-home" *ngIf="secciones.quienessomos">
    <div class="container-fluid p-0">
      <div class="bp-heading bp-somos--main bp-heading--blue">
        <h2 class="bp-somos--title">{{ 'home.quienessomos' | translate }}</h2>
      </div>
      <div class="bp-somos bp-somos--home">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5">
            <div class="bp-somos__box pe-md-5">
              <div class="row wr-md-75 wr-lg-100 mt-md-5 pt-md-5 mb-2">
                <div class="col bp-somos__title">{{ 'home.quienessomostitulo' | translate }}</div>
              </div>
              <div class="row wr-md-75 wr-lg-100 bp-somos__subtitle">
                <div class="col-md-12 col-lg-6">{{ 'home.quienessomosfraseizq' | translate }}</div>
                <div class="col-md-12 col-lg-6">{{ 'home.quienessomosfraseder' | translate }}</div>
              </div>

            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-6 col-xl-5 col-xxl-5 bp-somos__bottom">
            <div class="bp-somos__box ps-md-5 ms-md-5 ps-lg-4 ms-lg-4">
              <div class="row wr-md-75 wr-lg-100 mt-md-5 pt-md-5">
                <div class="col bp-somos__title mb-2">{{ 'home.sostenible' | translate }}</div>
              </div>
              <div class="row wr-md-75 wr-lg-100 bp-somos__subtitle">
                <div class="col-md-12 col-lg-6">{{ 'home.creemos' | translate }}</div>
                <div class="col-md-12 col-lg-6">{{ 'home.queremos' | translate }}</div>
              </div>

            </div>
          </div>
          <div class="col-12 d-md-none bp-somos__background"></div>
        </div>
      </div>
    </div>
  </section>
  <section class="bp-section bp-section--opinions" *ngIf="secciones.opiniones">
    <div class="container-fluid">
      <div class="bp-opinions bp-opinions--default">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-xxl-3">
            <div class="bp-opinions__item">
              <div class="bp-opinions__image">
                <img src="assets/img/biciparc-jose-vicente-ponce.png" alt="{{redparams.nombre}} - José Vicente Ponce">
              </div>
              <p class="bp-opinions__title">" Ahora sí.</p>
              <p class="bp-opinions__description">Un sistema muy sencillito que me
                permite usar bici eléctrica, y sin tener que subirla a casa.</p>
              <p class="bp-opinions__name">José Vicente Ponce</p>
            </div>
          </div>
          <div class="col-lg-4 offset-xxl-1 col-xxl-3">
            <div class="bp-opinions__item">
              <div class="bp-opinions__image">
                <img src="assets/img/biciparc-anna-pico.png" alt="{{redparams.nombre}} - Anna Picó">
              </div>
              <p class="bp-opinions__title">" Seguridad.</p>
              <p class="bp-opinions__description">Me gusta porque me permite mover
                sin perder tiempo, y sin preocuparme por mi bici gacela del 82,
                que por nada querría que le pasase algo.</p>
              <p class="bp-opinions__name">Anna Picó</p>
            </div>
          </div>
          <div class="col-lg-4 col-xxl-3">
            <div class="bp-opinions__item">
              <div class="bp-opinions__image">
                <img src="assets/img/biciparc-manuel-ahuir.png" alt="{{redparams.nombre}} - Manuel Ahuir">
              </div>
              <p class="bp-opinions__title">" Rápido.</p>
              <p class="bp-opinions__description">La manera más rápida que he
                encontrado para moverme por Valencia a cualquier hora.</p>
              <p class="bp-opinions__name">Manuel Ahuir</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
