import { Component } from '@angular/core';
import { DefaultRedParams, Redparams } from 'src/app/core/models';
import { HomeService } from 'src/app/core/services/home.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styles: [],
})
export class LoadingComponent {
  url = environment.api_url;
  public redparams: Redparams = DefaultRedParams;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private homeservice: HomeService) {
    forkJoin([this.homeservice.getRed()]).subscribe((results) => {
      this.redparams = results[0].msg;
    });
  }
}
