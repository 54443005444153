import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertime',
})
export class ConvertimePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(mins: number, ...args: unknown[]): string {
    const h = Math.floor(mins / 60);
    const m = mins % 60;
    const hora = h < 10 ? '0' + h : h;
    const min = m < 10 ? '0' + m : m;
    return `${hora}:${min}`;
  }
}
