import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { Validators, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { ValidadoresService } from 'src/app/core/services/validadores.service';
import { ApiService } from 'src/app/core/services/api.service';
import { UserService } from '../core/services/user.service';

import { DefaultRedParams, Redparams } from '../core/models';
import { HomeService } from '../core/services/home.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

interface AuthForm {
  email: FormControl<string>;
  pass1: FormControl<string>;
  nombre?: FormControl<string>;
  apellidos?: FormControl<string>;
  telefono?: FormControl<string>;
  pass2?: FormControl<string>;
  checkterms?: FormControl<boolean>;
  checkads?: FormControl<boolean>;
}
/*
export interface Errors {
  errors: { [key: string]: string };
}
*/
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  imports: [RouterLink, NgIf, NgClass, ReactiveFormsModule, TranslateModule],
  standalone: true,
})
export class AuthComponent implements OnInit, OnDestroy {
  full = true;
  @Input() set alone(full: boolean) {
    this.full = full;
  }
  public redparams: Redparams = DefaultRedParams;
  returnUrl = '';

  notchecked: boolean;
  loading: boolean;
  emailused: boolean;
  emailmalformed: boolean;
  loginError: boolean;
  passwordNotEqual: boolean;
  authType = '';
  title = '';
  //errors: Errors = { errors: {} };

  authForm: FormGroup<AuthForm>;
  destroy$ = new Subject<void>();

  constructor(private homeservice: HomeService, private validadores: ValidadoresService, private api: ApiService, private readonly router: Router, private readonly route: ActivatedRoute, private readonly userService: UserService) {
    /*  private fb: FormBuilder,




    this.notchecked = false;
    */

    this.crearFormulario();
    this.resetErrors();
  }
  resetErrors() {
    this.loading = false;
    this.passwordNotEqual = false;
    this.loginError = false;
    this.emailused = false;
    this.emailmalformed = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  crearFormulario() {
    /*   this.forma = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,3}$')]],
      pass1: ['', [Validators.required, Validators.minLength(6)]],
      //    checkads: [true],
    });*/
    this.authForm = new FormGroup<AuthForm>({
      email: new FormControl('', {
        validators: [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,3}$')],
        nonNullable: true,
      }),
      pass1: new FormControl('', {
        validators: [Validators.required, Validators.minLength(6)],
        nonNullable: true,
      }),
    });
  }

  getNoValido(input: string) {
    // return false;
    return this.authForm.get(input).invalid && this.authForm.get(input).touched;
  }

  ngOnInit(): void {
    //this.full = true;
    this.homeservice.getRed().subscribe({
      next: (data) => {
        this.redparams = data.msg;
      },
    });
    this.authType = this.route.snapshot.url.at(-1)?.path;
    if (this.authType === 'register') {
      this.authForm.addControl('nombre', new FormControl('', { validators: [Validators.required], nonNullable: true }));
      this.authForm.addControl('apellidos', new FormControl('', { validators: [Validators.required], nonNullable: true }));
      this.authForm.addControl('telefono', new FormControl('', { validators: [Validators.required, Validators.minLength(9)], nonNullable: true }));
      this.authForm.addControl('pass2', new FormControl('', { validators: [Validators.required, Validators.minLength(6)], nonNullable: true }));
      this.authForm.addControl('checkterms', new FormControl(false, { validators: [Validators.requiredTrue], nonNullable: true }));
      this.authForm.addControl('checkads', new FormControl(true));

      this.authForm.setValidators(this.validadores.passwordIguales('pass1', 'pass2'));
    }
  }
  guardar(): void {
    this.loading = true;
    if (this.authForm.invalid) {
      this.authForm.markAllAsTouched();
      this.loading = false;
      return;
    }

    this.resetErrors();

    const observable =
      this.authType === 'register'
        ? this.userService.register(
            this.authForm.value as {
              email: string;
              pass1: string;
              nombre: string;
              apellidos: string;
              telefono: string;
              pass2: string;
              checkterms: boolean;
              checkads: boolean;
            }
          )
        : this.userService.login(this.authForm.value as { email: string; pass1: string });

    observable.pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.returnUrl = window.localStorage['returnUrl'] || '/perfil';
        window.localStorage.removeItem('returnUrl');
        this.router.navigateByUrl(this.returnUrl);
      },
      error: (err) => {
        switch (err.msg) {
          case 'passwordnotequal':
            this.passwordNotEqual = true;
            break;
          case 'emailmalformed':
            this.emailmalformed = true;
            break;
          case 'notchecked':
            this.notchecked = true;
            break;
          case 'emailused':
            this.emailused = true;
            break;
          case 'invalidIdPasswd':
            this.loginError = true;
            break;
          default:
            break;
        }
        this.loading = false;
      },
    });
  }
}
