 <div class="bp-footer footer f3" [ngClass]="{ 'invisible':(loadingStatus$ | async ) , 'animate__animated':true,'animate__fadeIn': (loadingStatus$ | async ) === false}">
  <section class="bp-footer__top">
    <div class="container-fluid">
      <div class="row">
        <div class="bp-footer__col col-lg-4">
          <div class="bp-nav bp-nav--footer">
            <p class="bp-nav__title">{{ 'footer.legal' | translate }}</p>
            <nav class="bp-nav__list">
              <ul>
                <li><a routerLink="/privacy" title="{{ 'footer.privacy' | translate }}">{{ 'footer.privacy' | translate }}</a></li>
                <li><a routerLink="/terms" title="{{ 'footer.condiciones' | translate }}">{{ 'footer.condiciones' | translate }}</a></li>
                <li><a routerLink="/cookies" title="{{ 'footer.cookies' | translate }}">{{ 'footer.cookies' | translate }}</a></li>
                <li><a routerLink="/faq" title="{{ 'faq' | translate }}">{{ 'faq' | translate }}</a></li>
                <li *ngIf="secciones.faqtrasteros"><a href="https://dejaloaqui.biciparc.com" title="{{ 'trasteros' | translate }}">{{ 'faqtrasteros' | translate }}</a></li>
                <li><a href="#unete" title="{{'navbar.montatubiciparc' | translate }}">{{'navbar.montatubiciparc' | translate }}</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="bp-footer__col col-lg-4">
          <div class="bp-nav bp-nav--footer">
            <p class="bp-nav__title" id="contacta">{{ 'footer.siguenos' | translate }}</p>
            <ul class="bp-nav__social">
              <li *ngIf="redparams.instagram!==''">
                <a href="https://www.instagram.com/{{redparams.instagram}}/" target="_blank" title="{{ 'footer.instagram' | translate }}" rel="nofollow">
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 24C0
                      10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48
                      37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0
                      24ZM24.0012 11.2C20.5249 11.2 20.0886 11.2152 18.7233
                      11.2773C17.3606 11.3397 16.4305 11.5555 15.6166
                      11.872C14.7747 12.1989 14.0606 12.6363 13.3491
                      13.348C12.6371 14.0595 12.1997 14.7736 11.8717
                      15.6152C11.5544 16.4294 11.3384 17.3598 11.2771
                      18.7219C11.216 20.0873 11.2 20.5238 11.2 24.0001C11.2
                      27.4764 11.2155 27.9114 11.2773 29.2767C11.34 30.6394
                      11.5557 31.5695 11.872 32.3834C12.1992 33.2253 12.6365
                      33.9394 13.3483 34.6509C14.0595 35.3629 14.7736 35.8013
                      15.615 36.1283C16.4294 36.4448 17.3598 36.6605 18.7222
                      36.7229C20.0876 36.7851 20.5236 36.8003 23.9996
                      36.8003C27.4762 36.8003 27.9111 36.7851 29.2765
                      36.7229C30.6391 36.6605 31.5703 36.4448 32.3848
                      36.1283C33.2264 35.8013 33.9394 35.3629 34.6506
                      34.6509C35.3626 33.9394 35.8 33.2253 36.128
                      32.3837C36.4427 31.5695 36.6587 30.6391 36.7227
                      29.277C36.784 27.9116 36.8 27.4764 36.8 24.0001C36.8
                      20.5238 36.784 20.0876 36.7227 18.7222C36.6587 17.3595
                      36.4427 16.4294 36.128 15.6155C35.8 14.7736 35.3626
                      14.0595 34.6506 13.348C33.9386 12.636 33.2266 12.1987
                      32.384 11.872C31.5679 11.5555 30.6373 11.3397 29.2746
                      11.2773C27.9092 11.2152 27.4746 11.2 23.9972
                      11.2H24.0012Z" fill="black" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8529 13.5067C23.1937 13.5062 23.574 13.5067 24.0012 13.5067C27.4188 13.5067 27.8239 13.519 29.1735 13.5803C30.4215 13.6374
                        31.0989 13.8459 31.5501 14.0211C32.1474 14.2531 32.5733 14.5304 33.021 14.9784C33.469 15.4264 33.7464 15.8531 33.9789 16.4505C34.1541 16.9011 34.3629 17.5785 34.4197
                        18.8265C34.481 20.1758 34.4944 20.5812 34.4944 23.9972C34.4944 27.4132 34.481 27.8186 34.4197 29.1679C34.3626 30.4159 34.1541 31.0933 33.9789 31.5439C33.7469 32.1413 33.469
                        32.5666 33.021 33.0144C32.573 33.4624 32.1477 33.7397 31.5501 33.9717C31.0994 34.1477 30.4215 34.3557 29.1735 34.4128C27.8242 34.4741 27.4188 34.4874 24.0012 34.4874C20.5833
                        34.4874 20.1782 34.4741 18.8289 34.4128C17.5809 34.3552 16.9035 34.1466 16.4521 33.9714C15.8547 33.7394 15.428 33.4621 14.98 33.0141C14.532 32.5661 14.2547 32.1405 14.0222
                        31.5429C13.847 31.0922 13.6382 30.4149 13.5814 29.1669C13.52 27.8175 13.5078 27.4122 13.5078 23.994C13.5078 20.5758 13.52 20.1726 13.5814 18.8233C13.6384 17.5753 13.847 16.8979
                        14.0222 16.4467C14.2542 15.8494 14.532 15.4227 14.98 14.9747C15.428 14.5267 15.8547 14.2494 16.4521 14.0168C16.9033 13.8408 17.5809 13.6328 18.8289 13.5755C20.0097 13.5222
                        20.4673 13.5062 22.8529 13.5035V13.5067ZM30.8338 15.632C29.9858 15.632 29.2978 16.3193 29.2978 17.1675C29.2978 18.0155 29.9858 18.7035 30.8338 18.7035C31.6818 18.7035 32.3698
                        18.0155 32.3698 17.1675C32.3698 16.3195 31.6818 15.632 30.8338 15.632ZM24.0012 17.4267C20.371 17.4267 17.4278 20.37 17.4278 24.0001C17.4278 27.6303 20.371 30.5722 24.0012
                        30.5722C27.6314 30.5722 30.5735 27.6303 30.5735 24.0001C30.5735 20.37 27.6314 17.4267 24.0012 17.4267Z" fill="black" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0012 19.7334C26.3575 19.7334 28.2679 21.6436 28.2679 24.0001C28.2679 26.3564 26.3575 28.2668 24.0012 28.2668C21.6446
                          28.2668 19.7345 26.3564 19.7345 24.0001C19.7345 21.6436 21.6446 19.7334 24.0012 19.7334Z" fill="black" />
                        </svg>
                      </a>
                    </li>
                    <li *ngIf="redparams.facebook!==''">
                      <a href="https://www.facebook.com/{{redparams.facebook}}" target="_blank" title="{{ 'footer.facebook' | translate }}" rel="nofollow">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                          y="0px" width="612px" height="612px" viewBox="0 0 612
                          612" style="enable-background:new 0 0 612 612;"
                          xml:space="preserve">
                          <path
                            d="M612,306C612,137.004,474.995,0,306,0C137.004,0,0,137.004,0,306c0,168.995,137.004,306,306,306
                            C474.995,612,612,474.995,612,306z
                            M223.825,306v-58.557h35.885V212.03c0-47.764,14.271-82.175,66.569-82.175h62.201v58.418
                            h-43.785c-21.949,0-26.929,14.577-26.929,29.849v29.32h67.487L376.046,306h-58.278v176.617H259.71V306H223.825z"
                            />
                          </svg>
                        </a>
                      </li>
                      <li *ngIf="redparams.whatsapp!==''">

                        <a target="_blank" title="{{ 'footer.whatsapp' | translate }}" rel="nofollow" (click)="whatsappClick()">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="256" height="256" viewBox="0 0 256 256" xml:space="preserve">
                            <defs>
                            </defs>
                            <g transform="translate(128 128) scale(0.72 0.72)">
                              <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero;
                                opacity: 1;" transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)">
                                <path d="M 76.735 13.079 C 68.315 4.649 57.117 0.005 45.187 0 C 20.605 0 0.599 20.005 0.589 44.594 c -0.003 7.86 2.05 15.532 5.953 22.296 L 0.215 90 l 23.642 -6.202 c
                                  6.514 3.553 13.848 5.426 21.312 5.428 h 0.018 c 0.001 0 -0.001 0 0 0 c 24.579 0 44.587 -20.007 44.597 -44.597 C 89.789 32.713 85.155 21.509 76.735 13.079 z M 27.076
                                  46.217 c -0.557 -0.744 -4.55 -6.042 -4.55 -11.527 c 0 -5.485 2.879 -8.181 3.9 -9.296 c 1.021 -1.115 2.229 -1.394 2.972 -1.394 s 1.487 0.007 2.136 0.039 c 0.684 0.035
                                  1.603 -0.26 2.507 1.913 c 0.929 2.231 3.157 7.717 3.436 8.274 c 0.279 0.558 0.464 1.208 0.093 1.952 c -0.371 0.743 -0.557 1.208 -1.114 1.859 c -0.557 0.651 -1.17
                                  1.453 -1.672 1.952 c -0.558 0.556 -1.139 1.159 -0.489 2.274 c 0.65 1.116 2.886 4.765 6.199 7.72 c 4.256 3.797 7.847 4.973 8.961 5.531 c 1.114 0.558 1.764 0.465 2.414
                                  -0.279 c 0.65 -0.744 2.786 -3.254 3.529 -4.369 c 0.743 -1.115 1.486 -0.929 2.507 -0.558 c 1.022 0.372 6.5 3.068 7.614 3.625 c 1.114 0.558 1.857 0.837 2.136 1.302 c
                                  0.279 0.465 0.279 2.696 -0.65 5.299 c -0.929 2.603 -5.381 4.979 -7.522 5.298 c -1.92 0.287 -4.349 0.407 -7.019 -0.442 c -1.618 -0.513 -3.694 -1.199 -6.353 -2.347 C
                                  34.934 58.216 27.634 46.961 27.076 46.217 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter;
                                  stroke-miterlimit: 10; fill-rule: evenodd; opacity: 1;" transform="matrix(1 0 0 1 0 0)" stroke-linecap="round" />
                                </g>
                              </g>
                            </svg>
                          </a>
                        </li>
                        <li *ngIf="redparams.pinterest!==''">

                          <a href="https://www.pinterest.es/{{redparams.pinterest}}/" target="_blank" title="{{ 'footer.instagram' | translate }}" rel="nofollow">
                            <svg fill="none" height="48px" width="48px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                              viewBox="0 0 480.666 480.666" xml:space="preserve">
                              <g id="pinterest_2_">
                                <g>
                                  <path d="M240.35,0.008C107.612,0.008,0,107.605,0,240.31c0,98.431,59.168,182.967,143.867,220.133
                                    c-0.717-16.795-0.157-36.918,4.145-55.17c4.646-19.522,30.957-130.976,30.957-130.976s-7.669-15.345-7.669-38.009
                                    c0-35.623,20.637-62.215,46.323-62.215c21.885,0,32.421,16.429,32.421,36.076c0,21.962-13.996,54.85-21.198,85.283
                                    c-6.016,25.5,12.781,46.301,37.907,46.301c45.545,0,76.221-58.506,76.221-127.781c0-52.66-35.478-92.087-100.006-92.087
                                    c-72.916,0-118.305,54.359-118.305,115.077c0,20.949,6.142,35.702,15.837,47.127c4.428,5.268,5.051,7.388,3.43,13.405
                                    c-1.154,4.427-3.773,15.072-4.895,19.28c-1.592,6.096-6.516,8.262-12.033,6.033c-33.573-13.733-49.192-50.471-49.192-91.814
                                    c0-68.279,57.578-150.125,171.736-150.125c91.773,0,152.189,66.377,152.189,137.654c0,94.277-52.434,164.723-129.713,164.723
                                    c-25.937,0-50.346-14.045-58.701-29.975c0,0-13.965,55.389-16.894,66.065c-5.113,18.517-15.089,37.058-24.193,51.491
                                    c21.605,6.375,44.454,9.85,68.116,9.85c132.736,0,240.316-107.595,240.316-240.348C480.666,107.605,373.086,0.008,240.35,0.008z"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </a>
                          </li>
                        </ul>
                        <!--
                        <div class="bp-nav__newsletter">
                      <p class="bp-nav__label">Newsletter</p>
                      <input type="text" id="email">
                    </div>
                  -->
                        <nav class="bp-nav__list">
                          <ul>
                            <li> {{ 'footer.contacto' | translate }}</li>
                            <li><a href="mailto:{{ redparams.email }}" title="{{ 'footer.contacto' | translate }}">{{ redparams.email }}</a></li>
                            <li>{{ 'footer.horario' | translate }}: {{redparams.horario}}</li>
                            <li>Teléfono: <a href="tel:{{redparams.telefono}}">{{redparams.telefono}}</a></li>
                          </ul>
                        </nav>
                      </div>
                    </div>

                    <div class="bp-footer__col col-lg-4">
                      <div class="bp-nav bp-nav--footer">
                        <p class="bp-nav__title dropdown-toggle" style="cursor: pointer;" (click)="collapse.toggle()">Parkings</p>
                        <nav class="bp-nav__list f6">
                          <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed"><ul>
                              <ng-container *ngFor="let parking of parkings">
                                <li><a [routerLink]="['/mapa', parking.id]" title="{{ parking.nombre | unescape}}">{{ parking.nombre | unescape }}</a></li>
                              </ng-container>


                            </ul></div>

                        </nav>
                      </div>
                    </div>
                    <!--    <div class="bp-footer__col col-lg-3">
                  <div class="bp-nav bp-nav--footer">
                    <p class="bp-nav__title">Idiomas</p>
                    <nav class="bp-nav__list">
                      <ul>
                        <li><a href="/" title="Valencià">Valencià</a></li>
                        <li><a href="/" title="Dansk">Dansk</a></li>
                        <li><a href="/" title="Deutsch">Deutsch</a></li>
                        <li><a href="/" title="English">English</a></li>
                        <li><a href="/" title="Français">Français</a></li>
                        <li><a href="/" title="Italiano">Italiano</a></li>
                        <li><a href="/" title="Nederlands">Nederlands</a></li>
                        <li><a href="/" title="Português">Português</a></li>
                      </ul>
                    </nav>
                  </div>
                </div>-->
                  </div>
                </div>
              </section>
              <section class="bp-footer__bottom f6">
                <div class="container-fluid">
                  <div class="bp-footer__grid">
                    <p class="bp-footer__copy">&#169; {{year$}} Dejalo Aquí S.L. <br>Versión: {{version}} </p>
                    <div class="bp-footer__logo">
                      <img src="assets/img/biciparc-logo-white.png" alt="{{redparams.nombre}} - Bike Your Life & Park The Rest">
                    </div>
                  </div>
                </div>
              </section>
            </div>
