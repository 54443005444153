export type ShowState = 'initial' | 'initial-default' | 'initial-thumbnail-image' | 'initial-virtual-image' | 'animation' | 'animation-end';
export type ClosingState = 'initial' | 'reset-scale' | 'initial-styles' | 'animation' | 'animation-end';
export interface LightboxData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  images?: Images & any;
  image?: Image & ImageExtended;
  properties: Properties;
  index?: number;
}

export interface Images {
  [index: number]: { path: string; width?: number; height?: number };
}

export interface Image {
  path: string;
  width?: number;
  height?: number;
}

export interface ImageExtended {
  fullImage?: Image;
  thumbnailImage?: Image;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nativeElement?: any;
}

export type AnimationMode = 'default' | 'zoom' | 'zoom-blur' | 'zoom-preloader' | 'opacity' | 'none';

export interface Properties {
  loop: boolean;
  index: number;
  counter: boolean;
  imageMaxHeight: string;
  imageMaxWidth: string;
  animationDuration: number;
  animationMode: AnimationMode;
  /*  | 'default'
    | 'zoom'
    | 'zoom-blur'
    | 'zoom-preloader'
    | 'opacity'
    | 'none'; // можно экспортировать тип*/
  animationTimingFunction: string;
  closeButtonText: string;
  counterSeparator: string;
  disable: boolean;
  simpleMode: boolean;
  backgroundColor: 'black' | 'white';
  backgroundOpacity: number;
  hideThumbnail: boolean;
  //imagePathError: string;
  gestureEnable: boolean;
}
export const DefaultProperties: Properties = {
  loop: true,
  index: 0,
  counter: false,
  imageMaxHeight: '100%',
  imageMaxWidth: '100%',
  animationDuration: 350,
  animationMode: 'zoom-preloader',
  animationTimingFunction: 'cubic-bezier(0.475, 0.105, 0.445, 0.945)',
  closeButtonText: 'Close',
  counterSeparator: '/',
  disable: false,
  simpleMode: false,
  backgroundColor: 'black',
  backgroundOpacity: 1,
  hideThumbnail: true,
  gestureEnable: false,
};
