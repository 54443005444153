import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ParkingsService } from 'src/app/core/services/parkings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { Location } from '@angular/common';
import { forkJoin, Observable } from 'rxjs';
import { DefaultRedParams, DefaultRedSeccion, Parking, RedSeccion, Redparams, msgRedparams } from 'src/app/core/models';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';
import { environment } from 'src/environments/environment';

import Map from 'ol/Map';
import View from 'ol/View';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
//import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import Feature from 'ol/Feature';
import { LineString, Point, Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { fromLonLat } from 'ol/proj';
import { Vector as VectorLayer } from 'ol/layer';
import TileLayer from 'ol/layer/WebGLTile';
import Cluster from 'ol/source/Cluster';
import { Overlay } from 'ol';
import Swal from 'sweetalert2';
//import Overlay from 'ol/Overlay';

import CircleStyle from 'ol/style/Circle';
import monotoneChainConvexHull from 'monotone-chain-convex-hull';
import { createEmpty, extend, getWidth } from 'ol/extent';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-mapav2',
  templateUrl: './mapav2.component.html',
  styleUrls: ['./mapav2.component.scss'],
})
export class Mapav2Component implements OnInit, OnDestroy {
  view = 'view-map';
  loadingStatus$: Observable<boolean>;
  url = environment.api_url;

  parkings: Parking[];
  selectedParking = -1;
  eServiciosparkings = Serviciosparkings;

  map: Map;
  //vectorSource: VectorSource;

  viewmap: View;

  public coords: Redparams = DefaultRedParams;
  public secciones: RedSeccion = DefaultRedSeccion;

  constructor(private homeservice: HomeService, private translate: TranslateService, private parkingservice: ParkingsService, private router: ActivatedRoute, private _router: Router, private location: Location, private loadingService: Loaderv2Service) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }

  ngOnInit(): void {
    if (document.querySelector('body').getAttribute('data-viewmode')) {
      this.view = document.querySelector('body').getAttribute('data-viewmode');
    } else {
      this.view = 'view-map';
      document.querySelector('body').setAttribute('data-viewmode', this.view);
    }
    window.localStorage.removeItem('returnUrl');
    this.initProgress();
    this.loadData();
  }

  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }

  loadData() {
    forkJoin([this.parkingservice.getParkingList(), this.homeservice.getCoords(), this.homeservice.getSecciones(), this.homeservice.getRed()]).subscribe((results) => {
      this.parkings = results[0].msg;

      this.coords = results[1].msg;
      this.secciones = results[2].msg;
      console.log(results[3].msg);
      setTimeout(() => {
        this.initMap();
      }, 1);
      this.router.params.subscribe((params) => {
        if (params['id']) {
          this.selectedParking = this.parkings.findIndex((parking) => {
            return parking.id == params.id;
          });
        } else {
          this.selectedParking = -1;
        }
        if (this.selectedParking > -1) {
          setTimeout(() => {
            this.cambiaMapa(this.selectedParking);
          }, 1);
        }
      });
    });
  }

  private initMap(): void {
    const greenIcon = new Style({
      image: new Icon({
        anchor: [0.5, 33],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: 'assets/img/icon-1.png',
      }),
    });
    const circleDistanceMultiplier = 1;
    const circleFootSeparation = 28;
    const circleStartAngle = Math.PI / 2;

    const convexHullFill = new Fill({
      color: 'rgba(255, 153, 0, 0.4)',
    });
    const convexHullStroke = new Stroke({
      color: 'rgba(204, 85, 0, 1)',
      width: 1.5,
    });
    const outerCircleFill = new Fill({
      color: 'rgba(255, 153, 102, 0.3)',
    });
    const innerCircleFill = new Fill({
      color: 'rgba(255, 165, 0, 0.7)',
    });
    const textFill = new Fill({
      color: '#fff',
    });
    const textStroke = new Stroke({
      color: 'rgba(0, 0, 0, 0.6)',
      width: 3,
    });
    const innerCircle = new CircleStyle({
      radius: 14,
      fill: innerCircleFill,
    });
    const outerCircle = new CircleStyle({
      radius: 20,
      fill: outerCircleFill,
    });
    /*   const darkIcon = new Icon({
      src: 'data/icons/emoticon-cool.svg',
    });
    const lightIcon = new Icon({
      src: 'data/icons/emoticon-cool-outline.svg',
    });*/
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let hoverFeature: any;
    /**
     * Style for convex hulls of clusters, activated on hover.
     * @param {Feature} cluster The cluster feature.
     * @return {Style} Polygon style for the convex hull of the cluster.
     */
    function clusterHullStyle(cluster) {
      if (cluster !== hoverFeature) {
        return;
      }
      const originalFeatures = cluster.get('features');
      const points = originalFeatures.map((feature) => feature.getGeometry().getCoordinates());
      return new Style({
        geometry: new Polygon([monotoneChainConvexHull(points)]),
        fill: convexHullFill,
        stroke: convexHullStroke,
      });
    }

    /**
     * From
     * https://github.com/Leaflet/Leaflet.markercluster/blob/31360f2/src/MarkerCluster.Spiderfier.js#L55-L72
     * Arranges points in a circle around the cluster center, with a line pointing from the center to
     * each point.
     * @param {number} count Number of cluster members.
     * @param {Array<number>} clusterCenter Center coordinate of the cluster.
     * @param {number} resolution Current view resolution.
     * @return {Array<Array<number>>} An array of coordinates representing the cluster members.
     */
    function generatePointsCircle(count, clusterCenter, resolution) {
      const circumference = circleDistanceMultiplier * circleFootSeparation * (2 + count);
      let legLength = circumference / (Math.PI * 2); //radius from circumference
      const angleStep = (Math.PI * 2) / count;
      const res = [];
      let angle;

      legLength = Math.max(legLength, 35) * resolution; // Minimum distance to get outside the cluster icon.

      for (let i = 0; i < count; ++i) {
        // Clockwise, like spiral.
        angle = circleStartAngle + i * angleStep;
        res.push([clusterCenter[0] + legLength * Math.cos(angle), clusterCenter[1] + legLength * Math.sin(angle)]);
      }

      return res;
    }

    /**
     * Single feature style, users for clusters with 1 feature and cluster circles.
     * @param {Feature} clusterMember A feature from a cluster.
     * @return {Style} An icon style for the cluster member's location.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function clusterMemberStyle(clusterMember: Feature) {
      return greenIcon;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let clickFeature: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let clickResolution: any;
    /**
     * Style for clusters with features that are too close to each other, activated on click.
     * @param {Feature} cluster A cluster with overlapping members.
     * @param {number} resolution The current view resolution.
     * @return {Style} A style to render an expanded view of the cluster members.
     */
    function clusterCircleStyle(cluster, resolution) {
      if (cluster !== clickFeature || resolution !== clickResolution) {
        return;
      }
      const clusterMembers = cluster.get('features');
      const centerCoordinates = cluster.getGeometry().getCoordinates();
      return generatePointsCircle(clusterMembers.length, cluster.getGeometry().getCoordinates(), resolution).reduce((styles, coordinates, i) => {
        const point = new Point(coordinates);
        const line = new LineString([centerCoordinates, coordinates]);
        styles.unshift(
          new Style({
            geometry: line,
            stroke: convexHullStroke,
          })
        );
        styles.push(
          clusterMemberStyle(
            new Feature({
              ...clusterMembers[i].getProperties(),
              geometry: point,
            })
          )
        );
        return styles;
      }, []);
    }

    function clusterStyle(feature) {
      const size = feature.get('features').length;
      if (size > 1) {
        return [
          new Style({
            image: outerCircle,
          }),
          new Style({
            image: innerCircle,
            text: new Text({
              text: size.toString(),
              fill: textFill,
              stroke: textStroke,
            }),
          }),
        ];
      } else {
        const originalFeature = feature.get('features')[0];

        return clusterMemberStyle(originalFeature);
      }
    }

    const coormin = fromLonLat([this.coords.minx, this.coords.miny]);
    const coormax = fromLonLat([this.coords.maxx, this.coords.maxy]);

    this.viewmap = new View({
      zoom: 14,
      // center: fromLonLat([-0.365643, 39.483886]),
      center: fromLonLat([this.coords.longitud, this.coords.latitud]),
      minZoom: 12,
      maxZoom: 18,
      //extent: [-58035.28873578864, 4772973.679446996, -23371.096408461213, 4809472.360453167],
      extent: [coormin[0], coormin[1], coormax[0], coormax[1]],
    });

    // [minx, miny, maxx, maxy];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const vectorLayer = new VectorLayer({
      source: this.generateData(),
    });
    const rasterLayer = new TileLayer({
      preload: 16,
      source: new XYZ({
        url: environment.tile_server,
        attributions: environment.attribution,
      }),
    });

    const clusterSource = new Cluster({
      distance: 150,
      source: this.generateData(),
    });
    // Layer displaying the convex hull of the hovered cluster.
    const clusterHulls = new VectorLayer({
      source: clusterSource,
      style: clusterHullStyle,
    });

    // Layer displaying the clusters and individual features.
    const clusters = new VectorLayer({
      source: clusterSource,
      style: clusterStyle,
    });

    // Layer displaying the expanded view of overlapping cluster members.
    const clusterCircles = new VectorLayer({
      source: clusterSource,
      style: clusterCircleStyle,
    });

    this.map = new Map({
      view: this.viewmap,
      layers: [rasterLayer, clusterHulls, clusters, clusterCircles],
      target: 'ol-map',
    });

    const element = document.getElementById('popup');

    const popup = new Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
    });
    this.map.addOverlay(popup);
    this.map.on('click', (e) => {
      clusters.getFeatures(e.pixel).then((features) => {
        if (features.length > 0) {
          const clusterMembers = features[0].get('features');
          if (clusterMembers.length > 1) {
            // Calculate the extent of the cluster members.
            const extent = createEmpty();
            clusterMembers.forEach((feature) => extend(extent, feature.getGeometry().getExtent()));
            const view = this.map.getView();
            const resolution = this.map.getView().getResolution();
            if (view.getZoom() === view.getMaxZoom() || (getWidth(extent) < resolution && getWidth(extent) < resolution)) {
              // Show an expanded view of the cluster members.
              clickFeature = features[0];
              clickResolution = resolution;
              clusterCircles.setStyle(clusterCircleStyle);
            } else {
              // Zoom to the extent of the cluster members.
              view.fit(extent, { duration: 500, padding: [50, 50, 50, 50] });
            }
          } else {
            const feature = this.map.forEachFeatureAtPixel(e.pixel, function (feature) {
              return feature.getProperties().features[0];
            });

            if (feature) {
              this.cambiaMapa(feature.get('index'));
            }
          }
        }
      });
    });
    this.map.on('pointermove', (e) => {
      const pixel = this.map.getEventPixel(e.originalEvent);
      const hit = this.map.hasFeatureAtPixel(pixel);
      this.map.getTargetElement().style.cursor = hit ? 'pointer' : '';
      clusters.getFeatures(e.pixel).then((features) => {
        if (features[0] !== hoverFeature) {
          // Display the convex hull on hover.
          hoverFeature = features[0];
          clusterHulls.setStyle(clusterHullStyle);
          // Change the cursor style to indicate that the cluster is clickable.
          this.map.getTargetElement().style.cursor = hoverFeature && hoverFeature.get('features').length > 1 ? 'pointer' : '';
        }
      });
    });
  }

  cambiaMapa(value: number) {
    this.selectedParking = value;
    this.location.replaceState(`/mapa/${this.parkings[value].id}`);

    this.viewmap.animate({ duration: 500, center: fromLonLat([this.parkings[value].longitud, this.parkings[value].latitud]), zoom: 16 });
  }

  changeView(viewMode: string): void {
    document.querySelector('body').setAttribute('data-viewmode', viewMode);
    this.view = viewMode;
  }

  closeParking(viewmode: string) {
    this.selectedParking = -1;
    this.location.replaceState(`/mapa/`);
    this.viewmap.animate({ duration: 500, center: fromLonLat([this.coords.longitud, this.coords.latitud]), zoom: 14 });

    if (viewmode.length === 0) {
      this.changeView(this.view);
    } else {
      this.changeView(viewmode);
    }
  }
  isBtnActive(type: number, idParking: number): boolean {
    const reservas = this.parkings[idParking].reservas;
    const suscripciones = this.parkings[idParking].uso.suscripciones;
    const porhoras = this.parkings[idParking].uso.porhoras;
    const plazas = this.parkings[idParking].plazas;
    if (Serviciosparkings.PARKING_PORHORAS == type) {
      return plazas - (suscripciones + porhoras) < 1 ? false : true;
    }
    if (Serviciosparkings.PARKING_SUSCRIPCION == type) {
      //      22          10              3
      return plazas - (suscripciones + Math.max(reservas, porhoras)) < 1 ? false : true;
    }
    return true;
  }

  isBtnActivev1(type: number, idParking: number) {
    if (Serviciosparkings.PARKING_PORHORAS == type) {
      if (this.parkings[idParking].reservas > 0) {
        if (this.parkings[idParking].reservas - this.parkings[idParking].uso.porhoras < 1) {
          return false;
        }
      } else {
        if (this.parkings[idParking].plazas - this.parkings[idParking].uso.porhoras - this.parkings[idParking].uso.suscripciones < 1) {
          return false;
        }
      }
    }
    if (Serviciosparkings.PARKING_SUSCRIPCION == type) {
      if (this.parkings[idParking].plazas - this.parkings[idParking].reservas - this.parkings[idParking].uso.suscripciones < 1) {
        return false;
      }
    }
    return true;
  }

  bntClick(type: number, idParking: number) {
    if (this.isBtnActive(type, idParking)) {
      this._router.navigate([`/parking/${this.parkings[idParking].id}/${type}`]);
    } else {
      this.translate.get('map.plazasagotadas' + type).subscribe((res: string) => {
        Swal.fire({
          title: 'Error',
          html: res,
          icon: 'error',
        }).then(() => {
          // console  .log(resp);
        });
      });
    }
  }

  generateData(): VectorSource {
    // const data: Feature[] = [];
    const greenIcon = new Style({
      image: new Icon({
        anchor: [0.5, 33],
        anchorXUnits: 'fraction',
        anchorYUnits: 'pixels',
        src: 'assets/img/icon-1.png',
      }),
    });

    const vectorSource = new VectorSource();
    for (let index = 0; index < this.parkings.length; index++) {
      const parking = this.parkings[index];
      const marker = new Feature({
        geometry: new Point(fromLonLat([parking.longitud, parking.latitud])),
        name: parking.nombre,
        index: index,
        style: greenIcon,
      });
      marker.setStyle(greenIcon);

      vectorSource.addFeature(marker);
    }

    return vectorSource;
  }
  ngOnDestroy(): void {
    this.map.dispose();
  }
}
