import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
})
export class ForgotComponent implements OnInit {
  forma: UntypedFormGroup;
  loading: boolean;
  emailmalformed: boolean;

  constructor(private fb: UntypedFormBuilder, private userService: UserService, private router: Router) {
    this.crearFormulario();
    this.loading = false;
    this.emailmalformed = false;
  }

  getNoValido(input: string) {
    // return false;
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
  }

  crearFormulario() {
    this.forma = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,3}$')]],
    });
  }

  guardar() {
    this.loading = true;
    this.emailmalformed = false;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.loading = false;
      return;
    }

    const credentials = this.forma.value;
    this.userService.forgotpassword(credentials).subscribe(
      () => {
        // con sole.log(data);
        Swal.fire({
          title: 'Enviado',
          text: 'Te hemos enviado un correo electrónico. Haz clic en el enlace que aparece en el correo para restablecer tu contraseña! Si no lo ves, mira en la carpeta de SPAM.',
          icon: 'success',
        }).then(() => {
          this.router.navigateByUrl('/');
        });
      },
      (err) => {
        //  this.errors = err;

        switch (err.msg) {
          case 'emailmalformed':
            this.emailmalformed = true;
            break;
          default:
            break;
        }
        this.loading = false;
      }
    );
  }
}
