import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { MiBici } from '../models';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MisbicisService {
  headersload: HttpHeaders;
  httpParams: HttpParams;
  constructor(private apiService: ApiService) {
    this.headersload = new HttpHeaders().set('load', '1');
    this.httpParams = new HttpParams();
  }

  getMisBicis() {
    return this.apiService.get(`/mibici/getlistmisbicis/`); //, this.httpParams, this.headers);
  }
  setMiBiciv2(mibici: FormData) {
    return this.apiService.postFile('/mibici/mibici', mibici);
  }
  setMiBici(mibici: MiBici) {
    return this.apiService.put('/mibici/mibici', mibici);
  }
  setPhoto(data: FormData) {
    return this.apiService.postFile('/mibici/bicifoto', data);
  }
  delPhoto(idBici: number, idPhoto: number) {
    return this.apiService.delete(`/mibici/bicifoto/${idBici}/${idPhoto}`);
  }

  delMiBici(idbici: number) {
    return this.apiService.delete(`/mibici/mibici/${idbici}`);
  }
}
