import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from './core/services/user.service';
import { environment } from '../environments/environment';
import { DOCUMENT, Location } from '@angular/common';
import { VersionCheckService } from './core/services/version-check.service';

import { Observable, forkJoin } from 'rxjs';
import { Loaderv2Service } from './core/services/loaderv2.service';

import { HomeService } from './core/services/home.service';
import { DefaultRedParams, Redparams } from './core/models';
import { QrcodeService } from './core/services/qrcode.service';
import { ActivatedRoute } from '@angular/router';
import { NgxGdprCookieConsentConfig } from './ngx-gdpr-cookie-consent/model/common-types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = '';
  location: Location;
  url = environment.api_url;
  // loading: boolean = true;
  firsttime = true;
  loadingStatus$: Observable<boolean>;
  // public parkings: Parking[];
  public redparams: Redparams = DefaultRedParams;

  config: NgxGdprCookieConsentConfig = {
    backButtonText: 'Volver',
    confirmAllButtonText: 'Confirmar todas las Cookies',
    confirmButtonText: 'Guardar',
    title: 'Nosotros usamos Cookies',
    expiration: 365,
    subtitle: 'En nuestro sitio web utilizamos cookies. Algunas de ellas son esenciales, mientras que otras nos ayudan a mejorar este sitio web y su experiencia.',
    individualPrivacySettingsButtonText: 'Ajustes individuales de privacidad',
    individualPrivacySettingsSubtitle: 'Aquí encontrará un resumen de todas las cookies utilizadas. Puede dar su consentimiento a categorías enteras o mostrar más información y así seleccionar solo determinadas cookies.',

    image: 'assets/img/cookie.png',
    legalLinks: [
      {
        name: 'Política de privacidad',
        url: 'privacy',
      },
      {
        name: 'Terminos y condiciones',
        url: 'terms',
      },
      {
        name: 'Política de cookies',
        url: 'cookies',
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private homeservice: HomeService, @Inject(DOCUMENT) private doc: any, private qrcodeservice: QrcodeService, private route: ActivatedRoute, private userService: UserService, private versionCheckService: VersionCheckService, private loadingService: Loaderv2Service) {
    forkJoin([this.homeservice.getStyles(), this.homeservice.getRed()]).subscribe((results) => {
      results[0].msg.forEach((style) => {
        document.documentElement.style.setProperty(`--${style.nombre}`, style.color);
        if (style.nombre === 'FF10') {
          document.documentElement.style.setProperty('--bs-info-rgb', this.hexToRgb(style.color));
        }
      });
      document.documentElement.style.setProperty('--bs-dark', 'var(--CF1)');
      this.redparams = results[1].msg;
      this.title = this.redparams.nombre;
      document.documentElement.style.setProperty('--bp-filefondodesktop', 'url(' + this.url + '/api/getimage/' + this.redparams.idfilefondodesktop + ')');
      document.documentElement.style.setProperty('--bp-filefondomobile', 'url(' + this.url + '/api/getimage/' + this.redparams.idfilefondomobile + ')');
      document.documentElement.style.setProperty('--bp-fileseccion2', 'url(' + this.url + '/api/getimage/' + this.redparams.idfileseccion2 + ')');
      document.documentElement.style.setProperty('--bp-fileaparcabicis', 'url(' + this.url + '/api/getimage/' + this.redparams.idfileaparcabicis + ')');
    });
  }
  private hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16);
  }

  private setBotScriptTag() {
    const s = this.doc.createElement('script');
    s.type = 'text/javascript';
    s.innerHTML = `var botmanWidget = {
    frameEndpoint: 'assets/html/chat.html',
    chatServer: '${environment.chatserver}'
    };
    `;
    const body = this.doc.getElementsByTagName('body')[0];
    body.appendChild(s);

    const s2 = this.doc.createElement('script');
    s2.type = 'text/javascript';
    s2.src = `https://cdn.jsdelivr.net/npm/botman-web-widget@0/build/js/widget.js`;
    // const body = this.doc.getElementsByTagName('body')[0];
    body.appendChild(s2);
    //console.log(environment.chatserver);
  }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
      this.versionCheckService.initVersionCheck(environment.api_url + '/home/assets/version/version.json', 1000 * 1 * 30);
    } else {
      //  this.setBotScriptTag();
    }

    this.initProgress();
    this.userService.populate();
    this.route.queryParamMap.subscribe((param) => {
      const qrcode = param.get('utm_medium');
      if (qrcode !== null) {
        this.qrcodeservice.setQrCode(qrcode);
      }
    });
  }

  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
    /*   forkJoin([this.homeservice.getHomeParkingList(), this.homeservice.getRed()]).subscribe((results) => {
      this.parkings = results[0].msg;
      this.redparams = results[1].msg;
    });*/
  }
}
