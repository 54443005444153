import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { DefaultRedParams, Parking, Redparams } from 'src/app/core/models';
import { ParkingsService } from 'src/app/core/services/parkings.service';
import { environment } from 'src/environments/environment';
import { Loaderv2Service } from '../../core/services/loaderv2.service';

import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';
import { HomeService } from 'src/app/core/services/home.service';

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-parkingdetalle',
  templateUrl: './parkingdetalle.component.html',
  styleUrls: ['./parkingdetalle.component.scss'],
})
export class ParkingdetalleComponent implements OnInit {
  loadingStatus$: Observable<boolean>;
  loadingStatus = true;
  parking: Parking;
  eServiciosparkings = Serviciosparkings;
  url = environment.api_url;
  private id: number;
  public redparams: Redparams = DefaultRedParams;

  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  scrollLeft() {
    this.widgetsContent.nativeElement.scrollLeft -= 150;
  }

  scrollRight() {
    this.widgetsContent.nativeElement.scrollLeft += 150;
  }

  constructor(private homeservice: HomeService, private _router: Router, private translate: TranslateService, private route: ActivatedRoute, private loadingService: Loaderv2Service, private parkingService: ParkingsService) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }
  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
    this.homeservice.getRed().subscribe({
      next: (data) => {
        this.redparams = data.msg;
      },
    });
  }

  loadData(): void {
    this.route.params.subscribe((params) => {
      if (params.id && !isNaN(params.id)) {
        //
        this.id = params.id;

        this.parkingService.getParking(this.id, 0, '').subscribe({
          next: (data) => {
            this.parking = data.msg;

            this.loadingStatus = false;
          },
        });
      } else {
        //this.router.navigateByUrl('/mapa');
      }
    });
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.initProgress();
    this.loadData();
  }
  bntClick(type: number, idParking: number) {
    if (this.isBtnActive(type)) {
      this._router.navigate([`/parking/${idParking}/${type}`]);
    }
  }

  isBtnActive(type: number) {
    const reservas = this.parking.reservas;
    const suscripciones = this.parking.uso.suscripciones;
    const porhoras = this.parking.uso.porhoras;
    const plazas = this.parking.plazas;
    if (Serviciosparkings.PARKING_PORHORAS == type) {
      return plazas - (suscripciones + porhoras) < 1 ? false : true;
    }
    if (Serviciosparkings.PARKING_SUSCRIPCION == type) {
      //      22          10              3
      return plazas - (suscripciones + Math.max(reservas, porhoras)) < 1 ? false : true;
    }
    return true;
  }
}
