import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QrcodeService {
  qrcode = new ReplaySubject<string>();
  constructor() {
    this.qrcode.next('');
  }
  setQrCode(qrcode: string) {
    this.qrcode.next(qrcode);
  }
  getQrCode(): Observable<string> {
    return this.qrcode.asObservable();
  }
}
