<div class="container-sm justify-content-center bp-section--main">
  <app-loading *ngIf="loading" class="m-5"></app-loading>
</div>
<div class="container-sm justify-content-center" [ngClass]="{ 'invisible': loading , 'animate__animated':true,'animate__fadeIn':!loading}">
  <div class="row my-3" *ngIf="!edit">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 gris text-dark">
          {{ 'MISBICIS.TITLE' | translate }}
        </div>
        <div class="card-body">
          <ng-container *ngIf="misbicis.length ===0">
            {{ 'MISBICIS.NOBICIREGISTRADA' | translate }}
          </ng-container>
          <ng-container *ngIf="misbicis.length>0">
            <ng-container *ngFor="let mibici of misbicis">
              <div class="card mb-3">
                <div class="row g-0">
                  <div class="col-md-3 text-center align-self-center position-relative">
                    <ng-container *ngIf="mibici.idfilevehiculo !== 0; else sinfotobici">
                      <div class="thumbnail rec thmbnail-large">
                        <img [attr.src]="url+'/mibici/bicifoto/'+mibici.idfilevehiculo|secure|async" [fullImage]="{path: url+'/mibici/bicifoto/'+mibici.idfilevehiculo|secure|async}" lightbox
                          closeButtonText="Cerrar" class="tmb">

                        <button class="classic_button_next btn btn-outline-danger btn-sm" id="next_button" (click)="delPhoto( mibici.id,1)">
                          <i class="far fa-window-close"></i>
                        </button>
                      </div>
                    </ng-container>
                    <ng-template #sinfotobici>
                      <div class="flex-centered">
                        <button class="btn btn-primary" (click)="addphoto('1', mibici.id )">
                          {{ 'MISBICIS.ADDPHOTO' | translate }}
                        </button>
                      </div>
                      <div class="progress" *ngIf="uploadProgress[mibici.id]">
                        <div class="progress-bar" role="progressbar" attr.aria-valuenow="{{uploadProgress[mibici.id]}}" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-md-3 text-center align-self-center position-relative">
                    <ng-container *ngIf="mibici.idfilefactura !== 0; else sinfotofactura">
                      <div class="thumbnail rec thmbnail-large">

                        <img [attr.src]="url+'/mibici/bicifoto/'+mibici.idfilefactura|secure|async" [fullImage]="{path: url+'/mibici/bicifoto/'+mibici.idfilefactura|secure|async}" lightbox
                          closeButtonText="Cerrar" class="tmb">
                        <button class="classic_button_next btn btn-outline-danger btn-sm" id="next_button" (click)="delPhoto( mibici.id,2)">
                          <i class="far fa-window-close"></i>
                        </button>
                      </div>
                    </ng-container>
                    <ng-template #sinfotofactura>
                      <div class="flex-centered">
                        <button class="btn btn-primary" (click)="addphoto('2', mibici.id )">{{ 'MISBICIS.addbil' | translate}}</button>
                      </div>
                    </ng-template>
                  </div>
                  <div class="col-md-4" #kk>
                    <div class="card-body">
                      <h5 class="card-title">{{ 'MISBICIS.MARCA' | translate }}: {{ mibici.marca }}</h5>
                      <p class="card-text">{{ 'MISBICIS.MODELO' | translate }}: {{ mibici.modelo }}</p>
                      <p class="card-text">{{ 'MISBICIS.COLOR' | translate }}: {{ mibici.color }}</p>
                      <p class="card-text">{{ 'MISBICIS.VALOR' | translate }}: {{ mibici.valor }}</p>
                      <p class="card-text"><small class="text-muted">{{ 'MISBICIS.observaciones' | translate }}: {{ mibici.observaciones }}</small></p>
                    </div>
                  </div>
                  <div class="col-md-1 text-center align-self-center">
                    <div class="flex-centered">
                      <button class="btn btn-primary btn-sm" (click)="editBici(mibici.id)">{{ 'MISBICIS.EDITAR' | translate }}</button>
                    </div>
                  </div>
                  <div class="col-md-1 text-center align-self-center">
                    <div class="flex-centered">
                      <button class="btn btn-primary btn-sm" (click)="delBici(mibici.id )">{{ 'MISBICIS.ELIMINAR' | translate }}</button>
                    </div>
                  </div>

                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-3" *ngIf="!edit">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 gris text-dark">{{ 'MISBICIS.ADDBICI' | translate }} </div>

        <form autocomplete="nope" [formGroup]="forma" (ngSubmit)="save()">
          <div class="card-body">
            <div>
              <div class="input-group mt-3">
                <input type="text" formControlName="marca" class="form-control" [placeholder]="'MISBICIS.ADDMARCA' | translate" [class.is-invalid]="getNoValido('marca')" autocomplete="nope"
                  onfocus="this.setAttribute('autocomplete', 'nope');" />
                <span class="input-group-text">
                  <i class="fas fa-trademark"></i>
                </span>
              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('marca')">{{ 'MISBICIS.MARCAINVALIDA' | translate }}</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input type="text" formControlName="modelo" class="form-control" [placeholder]="'MISBICIS.ADDMODELO' | translate" [class.is-invalid]="getNoValido('modelo')" autocomplete="nope"
                  onfocus="this.setAttribute('autocomplete', 'nope');" />

                <span class="input-group-text">
                  <i class="fas fa-biking"></i>
                </span>

              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('modelo')">{{ 'MISBICIS.MODELOINVALIDO' | translate }}</small>
            </div>

            <div>
              <div class="input-group mt-3">
                <input type="text" class="form-control" [placeholder]="'MISBICIS.COLOR' | translate" formControlName="color" [class.is-invalid]="getNoValido('color')" autocomplete="nope" />

                <span class="input-group-text">
                  <i class="fas fa-palette"></i>
                </span>

              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('color')">{{ 'MISBICIS.COLORINVALIDO' | translate }}.</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input type="text" class="form-control" [placeholder]="'MISBICIS.VALOR' | translate" formControlName="valor" [class.is-invalid]="getNoValido('valor')" autocomplete="nope" />

                <span class="input-group-text">
                  <i class="fas fa-euro-sign"></i>
                </span>

              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('valor')">{{ 'MISBICIS.VALORINVALIDO' | translate }}.</small>
            </div>
            <div>
              <div class="input-group mt-3">

                <input type="file" id="inputGroupFile" class="form-control" placeholder="Foto" formControlName="thumbnail" [class.is-invalid]="getNoValido('thumbnail')" autocomplete="nope"
                  accept="image/png,
                  image/jpeg" (change)="onFileChange($event)" />
                <label class="input-group-text" for="inputGroupFile">{{ 'MISBICIS.ELIGEFOTO' | translate }}</label>
              </div>

              <small class="form-text text-danger" *ngIf="getNoValido('thumbnail')">{{ 'MISBICIS.FOTOINVALIDO' | translate }}</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <textarea class="form-control" [placeholder]="'MISBICIS.lgobservaciones' | translate" formControlName="observaciones" autocomplete="nope" rows="3"></textarea>
                <span class="input-group-text">
                  <i class="fas fa-barcode"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="card-footer bg-transparent border-top-0">
            <div class="d-grid gap-2">
              <button type="submit" class="btn btn-primary f5" [disabled]="formloading">
                <span *ngIf="!formloading">{{ 'MISBICIS.GUARDAR' | translate }}</span>
                <i class="f5 fas" [ngClass]="{ 'fa-sync fa-spin': formloading }"></i>
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
  <div class="row my-3" *ngIf="edit">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 gris
          text-dark">{{ 'MISBICIS.EDITBICICLETA' | translate }}
        </div>
        <form autocomplete="nope" [formGroup]="forma"
          (ngSubmit)="saveedit()">
          <div class="card-body">
            <div>
              <div class="input-group mt-3">
                <input
                  type="text"
                  formControlName="marca"
                  class="form-control"
                  [placeholder]="'MISBICIS.ADDMARCA' | translate"
                  [class.is-invalid]="getNoValido('marca')"
                  autocomplete="nope"
                  onfocus="this.setAttribute('autocomplete',
                  'nope');" />

                <span class="input-group-text">
                  <i class="fas fa-trademark"></i>
                </span>

              </div>
              <small class="form-text text-danger"
                *ngIf="getNoValido('marca')">{{ 'MISBICIS.MARCAINVALIDA' |
                translate }}</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input
                  type="text"
                  formControlName="modelo"
                  class="form-control"
                  [placeholder]="'MISBICIS.ADDMODELO' | translate"
                  [class.is-invalid]="getNoValido('modelo')"
                  autocomplete="nope"
                  onfocus="this.setAttribute('autocomplete',
                  'nope');" />

                <span class="input-group-text">
                  <i class="fas fa-biking"></i>
                </span>

              </div>
              <small class="form-text text-danger"
                *ngIf="getNoValido('modelo')">{{ 'MISBICIS.MODELOINVALIDO' |
                translate }}</small>
            </div>

            <div>
              <div class="input-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  [placeholder]="'MISBICIS.COLOR' | translate"
                  formControlName="color"
                  [class.is-invalid]="getNoValido('color')"
                  autocomplete="nope" />

                <span class="input-group-text">
                  <i class="fas fa-palette"></i>
                </span>

              </div>
              <small
                class="form-text text-danger"
                *ngIf="getNoValido('color')">{{ 'MISBICIS.COLORINVALIDO' |
                translate }}</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  [placeholder]="'MISBICIS.VALOR' | translate"
                  formControlName="valor"
                  [class.is-invalid]="getNoValido('valor')"
                  autocomplete="nope" />

                <span class="input-group-text">
                  <i class="fas fa-euro-sign"></i>
                </span>

              </div>
              <small
                class="form-text text-danger"
                *ngIf="getNoValido('color')">{{ 'MISBICIS.COLORINVALIDO' |
                translate }}</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <textarea class="form-control"
                  [placeholder]="'MISBICIS.lgobservaciones' | translate"
                  formControlName="observaciones"
                  autocomplete="nope" rows="3"></textarea>

                <span class="input-group-text">
                  <i class="fas fa-barcode"></i>
                </span>

              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-grid gap-2">
              <button type="submit" class="btn btn-primary f5" [disabled]="formloading">
                <span *ngIf="!formloading">{{ 'MISBICIS.GUARDAR' | translate }}</span>
                <i class="f5 fas" [ngClass]="{ 'fa-sync fa-spin': formloading }"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
