import { ContentChildren, Directive, HostBinding, Input, QueryList } from '@angular/core';

import { DefaultProperties, ImageExtended, Properties } from '../models';
import { EventService } from '../services/event.service';
import { LightboxService } from '../services/lightbox.service';
import { LightboxDirective } from './lightbox.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[lightbox-group]',
})
export class LightboxGroupDirective {
  @Input() closeButtonText!: string;
  @Input() backgroundOpacity!: number;
  globalEventsSubscription;
  thumbnailImageElement;
  thumbnailImages = [];
  images = [];
  thumbnailImageIndex: number;
  thumbnailLightboxDirective: LightboxDirective;
  properties: Properties = DefaultProperties;

  get lightboxDirectiveList() {
    if (this._lightboxDirectiveList) {
      return this._lightboxDirectiveList.toArray();
    } else {
      return [];
    }
  }

  @HostBinding('class.lightbox-group') hostLightboxGroup = true;
  @ContentChildren(LightboxDirective, { descendants: true }) _lightboxDirectiveList: QueryList<LightboxDirective>;

  constructor(private eventService: EventService, private lightbox: LightboxService) {
    this.globalEventsSubscription = this.eventService.emitter.subscribe((event) => {
      this.handleGlobalEvents(event);
    });
  }

  handleGlobalEvents(event) {
    if (event.type === 'thumbnail:click') {
      this.thumbnailImageElement = event.elementRef.nativeElement;
      this.thumbnailImages = this.getThumbnailImages();
      this.thumbnailImageIndex = this.getThumbnailImageIndex(this.thumbnailImageElement);

      if (this.thumbnailImageIndex == undefined) {
        return;
      }

      this.thumbnailLightboxDirective = this.getThumbnailLightboxDirective(this.thumbnailImageIndex);
      this.images = this.getImages();
      this.properties = event.properties;
      this.properties.index = this.thumbnailImageIndex;
      this.properties.closeButtonText = this.closeButtonText;
      this.properties.backgroundOpacity = this.backgroundOpacity;

      this.lightbox.open({
        images: this.images,
        index: this.thumbnailImageIndex,
        properties: this.properties,
      });
    }
  }

  getThumbnailLightboxDirective(index) {
    return this.lightboxDirectiveList[index];
  }

  getThumbnailImages() {
    // eslint-disable-next-line prefer-const
    let thumbnailImages = [];
    // eslint-disable-next-line prefer-const
    let tempimages = [];

    this.lightboxDirectiveList.forEach((el) => {
      if (tempimages.indexOf(el['elementRef'].nativeElement.src) !== -1) {
        // alert('Yes, the value exists!');
      } else {
        tempimages.push(el['elementRef'].nativeElement.src);
        thumbnailImages.push(el['elementRef'].nativeElement);
      }
    });
    return thumbnailImages;
  }

  getThumbnailImageIndex(element) {
    const images = this.thumbnailImages;
    for (let i = 0; i < images.length; i++) {
      if (element.src === images[i].src) {
        return i;
      }
    }
  }

  getImages() {
    // eslint-disable-next-line prefer-const
    let images = [];
    // eslint-disable-next-line prefer-const
    let tempimages = [];
    this.lightboxDirectiveList.forEach((el) => {
      const image: ImageExtended = {};
      const nativeElement = el['elementRef'].nativeElement;

      if (el.fullImage) {
        image.fullImage = el.fullImage;
      }

      image.thumbnailImage = {
        path: nativeElement.src,
        height: nativeElement.naturalHeight,
        width: nativeElement.naturalWidth,
      };

      image.nativeElement = nativeElement;

      if (tempimages.indexOf(image.thumbnailImage.path) !== -1) {
        // alert('Yes, the value exists!');
      } else {
        tempimages.push(image.thumbnailImage.path);
        images.push(image);
      }
    });

    return images;
  }
}
