
<div class="container-sm justify-content-center bp-section--main">
  <app-loading *ngIf="(loadingStatus$ | async)" class="m-5"></app-loading>
</div>
<div class="container-sm justify-content-center bp-section--main" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,'animate__fadeIn':(loadingStatus$ | async )===false}">
  <div class="row my-3">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 gris
          text-dark">
          CAMBIAR CONTRASEÑA
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="forma"
            (ngSubmit)="save()">


            <div>
              <div class="input-group mt-3">
                <input
                  type="password"
                  formControlName="oldpassword"
                  class="form-control"
                  placeholder="Introduce contraseña actual"
                  [class.is-invalid]="getNoValido('oldpassword')"
                  autocomplete="new-password"
                  onfocus="this.setAttribute('autocomplete',
                  'new-password');"
                  />


                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>

              </div>
              <small class="form-text text-danger"
                *ngIf="getNoValido('pass1') || passwordnotvalid">La contraseña
                no es correcta</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input
                  type="password"
                  formControlName="pass1"
                  class="form-control"
                  placeholder="Introduce nueva contraseña"
                  [class.is-invalid]="getNoValido('pass1')"
                  autocomplete="new-password"
                  onfocus="this.setAttribute('autocomplete',
                  'new-password');"
                  />


                <span class="input-group-text">
                  <i class="fas fa-lock"></i>
                </span>

              </div>
              <small class="form-text text-danger"
                *ngIf="getNoValido('pass1')">Minimo
                6 caracteres</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input
                  type="password"
                  class="form-control"
                  placeholder="Confirme nueva contraseña"
                  formControlName="pass2"
                  [class.is-invalid]="getNoValido('pass2')"
                  autocomplete="new-password"
                  />

                <span class="input-group-text">
                  <i class="fas fa-user-lock"></i>
                </span>

              </div>
              <small
                class="form-text text-danger"
                *ngIf="getNoValido('pass2') ||
                passwordNotEqual">Contraseñas
                no
                coinciden</small>
            </div>
            <div class="row mt-3">

              <div class="col">
                <div class="d-grid gap-2">
                  <button type="submit" class="btn btn-primary
                    f5" [disabled]="formloading">
                    <span *ngIf="!formloading">Actualizar</span>
                    <i class="f5 fas" [ngClass]="{
                      'fa-sync fa-spin': formloading
                      }"></i>
                  </button>
                </div>

              </div>
              <!-- /.col -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
