import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private apiService: ApiService) {}
  getfaqlist(lang: string) {
    return this.apiService.get(`/api/getfaqlist/${lang}`);
  }
}
