import { Component } from '@angular/core';

@Component({
  selector: 'app-iframechat',
  templateUrl: './iframechat.component.html',
  styleUrls: ['./iframechat.component.scss']
})
export class IframechatComponent {

}
