import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/api.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidadoresService } from 'src/app/core/services/validadores.service';
import { UserService } from 'src/app/core/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss'],
})
export class RecoveryComponent implements OnInit {
  msg: string;
  // msg: any;
  loading: boolean;
  forma: UntypedFormGroup;
  formloading: boolean;
  passwordNotEqual: boolean;
  passwordnotvalid: boolean;
  invalidcheckcode: boolean;

  constructor(private route: ActivatedRoute, private api: ApiService, private fb: UntypedFormBuilder, private validadores: ValidadoresService, private userService: UserService, private router: Router) {
    this.passwordNotEqual = false;
    this.formloading = false;
    this.loading = true;
    this.passwordnotvalid = false;
    this.invalidcheckcode = false;
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.loading = true;
    this.crearFormulario();
    this.route.queryParams.subscribe((params) => {
      const parameters = new HttpParams({ fromObject: params });
      this.api.get('/api/recoverypassword', parameters).subscribe(
        (data) => {
          this.msg = data.msg;
          this.loading = false;
          this.forma.reset({
            checkcode: data.msg,
          });
        },
        (error) => {
          this.msg = error.msg;
          this.invalidcheckcode = true;
          this.loading = false;
        }
      );
    });
  }

  crearFormulario() {
    this.forma = this.fb.group(
      {
        checkcode: ['', [Validators.required, Validators.minLength(36)]],
        pass1: ['', [Validators.required, Validators.minLength(6)]],
        pass2: ['', [Validators.required, Validators.minLength(6)]],
      },
      {
        validators: this.validadores.passwordIguales('pass1', 'pass2'),
      }
    );
  }

  getNoValido(input: string) {
    return this.forma.get(input).invalid && this.forma.get(input).touched;
  }
  save() {
    this.formloading = true;
    this.passwordnotvalid = false;
    if (this.forma.invalid) {
      this.forma.markAllAsTouched();
      this.formloading = false;
      return;
    }
    const credencials = this.forma.value;
    this.userService.savenewpassword(credencials).subscribe({
      next: () => {
        // update the model
        //   this.updateUser(this.forma.value);
        //  this.router.navigateByUrl('/perfil');

        Swal.fire({
          title: '¡Actualizado',
          text: 'Sus datos han sido actualizados.!',
          icon: 'success',
        }).then(() => {
          this.userService.purgeAuth();
          this.router.navigateByUrl('/login');
          //   this.router.navigate(['/']);
        });

        this.formloading = false;
      },
      error: (err) => {
        // this.errors = err;

        switch (err.msg) {
          case 'passwordnotequal':
            this.passwordNotEqual = true;
            break;
          case 'passwordnotvalid':
            this.passwordnotvalid = true;
            break;

          default:
            break;
        }
        this.formloading = false;
      },
    });
  }
}
