<app-firecode></app-firecode>
<swal #registerCardSwal [showCancelButton]="true" [allowOutsideClick]="false">
  <div *swalPortal>
    <p>Va a ser redirigido a la web de su banco para que introduzca los
      datos de
      su tarjeta.</p>

    <form ngNoForm [formGroup]="formGroup"
      action="{{formUrl}}" method="POST"
      #regCardForm>
      <input type="hidden" formControlName="Ds_MerchantParameters"
        name="Ds_MerchantParameters" />
      <input type="hidden" formControlName="Ds_Signature"
        name="Ds_Signature" />
      <input type="hidden" formControlName="Ds_SignatureVersion"
        name="Ds_SignatureVersion" />

      <div class="mb-3">
        <div class="form-check">
          <input type="checkbox" class="form-check-input ms-1" id="cb" value="true" formControlName="cb" [class.is-invalid]="cbNoValido">
          <label class="custom-control-label f6" for="cb">Acepto los términos, condiciones y la política de privacidad de {{redparams.nombre}}.</label>
        </div>
        <small class="form-text text-danger" *ngIf="cbNoValido">Debes aceptar los términos y condiciones</small>
      </div>

    </form>

  </div>
  <ng-container *swalPortal="swalTargets.footer">
    <span class="f6">Ver los <a [routerLink]="['/terms']" class="text-black-50">términos, condiciones</a> y la
      <a [routerLink]="['/privacy']" class="text-black-50">política de privacidad</a> de {{redparams.nombre}}.
    </span>
  </ng-container>
</swal>
@if ((loadingStatus$ |async ) === false && !(loadingStatus)) {
@if (!(loadingStatus) && user.porhoras.id!==0) {
<div class="container-sm justify-content-center" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,'animate__fadeIn':(loadingStatus$ | async ) === false,
'bp-section--main':user.porhoras.id!==0,'pb-0':user.porhoras.id!==0}">
  <app-tiempoparking [porhoras]="user.porhoras"></app-tiempoparking>
</div>
}
@if (!(loadingStatus) && user.trasteros===0 && misbicis.length === 0 && (suscripcions.length> 0 || user.credito > 0)) {
<div class="container-sm justify-content-center px-0" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,
'animate__fadeIn':(loadingStatus$ | async ) === false,
'bp-section--main':user.porhoras.id===0 && user.trasteros===0 && misbicis.length === 0 && (suscripcions.length> 0 || user.credito > 0),
'pb-0':user.porhoras.id===0 && user.trasteros===0 && misbicis.length === 0 && (suscripcions.length> 0 || user.credito > 0)}">
  <a [routerLink]="['/misbicis']" style="color: #000;">
    <div class="row mx-0" style="border: 5px var(--bs-danger-border-subtle) solid">
      <div class="col f6 ms-3"> <span style="color: #F00;" class="align-middle">{{ 'userprofile.alert' | translate}}</span></div>
      <div class="col-2 ps-0 text-center align-middle"> <span class="fas fa-chevron-right"></span> </div>
    </div>
  </a>
</div>
}
@if (!(loadingStatus) && reparaciones.length >0){
@for (reparacion of reparaciones; track $index) {
<div class="container-sm justify-content-center px-0" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,
'animate__fadeIn':(loadingStatus$ | async ) === false,
'bp-section--main':user.porhoras.id===0 && user.trasteros===0   ,
'pb-0':user.porhoras.id===0 && user.trasteros===0   }">
  <a [routerLink]="['/reparaciones',reparacion.id]" style="color: #000;">
    <div class="row mx-0" style="border: 5px var(--bs-danger-border-subtle) solid">
      <div class="col f6 ms-3"> <span style="color: #F00;" class="align-middle">{{ 'userprofile.reparacionpendiente' | translate}} ({{reparacion.importe}}€)</span></div>
      <div class="col-2 ps-0 text-center align-middle"> <span class="fas fa-chevron-right"></span> </div>
    </div>
  </a>
</div>
}
}
}

<div class="container-sm justify-content-center">
  <app-loading *ngIf="(loadingStatus$ | async)" class="m-5"></app-loading>
</div>

<div class="container-sm justify-content-center bp-section--main" *ngIf="(loadingStatus$ | async) === false && !(loadingStatus)" [ngClass]="{ 'invisible': (loadingStatus$ | async) , 'animate__animated':true,'animate__fadeIn':(loadingStatus$ | async ) === false,
  'pt-0':user.porhoras.id!==0 || (user.trasteros===0 && misbicis.length === 0 && (suscripcions.length> 0 || user.credito > 0)  ) ||   (reparaciones.length> 0)}">
  <div class="row">
    <div class="col">
      <div class="bocadillo-redondo">
        <div class="nombrebocadillo">
          Hola {{ user.nombre }}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <img src="assets/img/dino.svg" class="mx-auto d-block" style="max-width: 150px;">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="bocadillo-redondo-invertido text-center">
        <div class="nombrebocadillo-invertido text-center pt-4">
          <span *ngIf="suscripcionesactivas.length===0">No tienes ninguna suscripción mensual.</span>
          <span *ngIf="suscripcionesactivas.length===1">Tienes {{suscripcionesactivas.length}} abono en:</span>
          <span *ngIf="suscripcionesactivas.length>1">Tienes {{suscripcionesactivas.length}} abonos en:</span>
          <a class="float-end align-middle mx-2 text-dark" placement="left" ngbTooltip="Paga una cuota fija por entrar en un parking de la red">
            <i class="fas fa-info-circle fa-lg"></i>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngFor="let subscription of suscripcions; let i= index">

    @if(isExpirationExpired(subscription.enddate) ){
    <div class="col">
      <div class="mx-auto pt-0 color text-center"></div>
      <div class="card color rounded-0">
        <div class="card-body text-center p-1">
          <h4 class="card-title mb-0">
            {{ subscription.nombre | uppercase | unescape }}
          </h4>
          <ng-container *ngIf="subscription.plazasnumeradas===1 && subscription.plaza.idplaza>0">
            <span>{{'suscripcion.suplaza'| translate}}<span [ngStyle]="{'background-color': subscription.plaza.color}">&nbsp;{{ subscription.plaza.nombreplaza}}&nbsp;</span></span>
            &nbsp;<div *ngIf="!editplaza" class="text-black-50 f6 ms-1" style="display: contents;"><a (click)="changePlaza(i)" [innerHTML]="'userprofile.cambiarplaza'| translate"></a></div>
            <div class="d-flex justify-content-center">
              <div class="input-group" *ngIf="editplaza" style="max-width: 350px;">
                <select class="form-select" id="selectidplaza" name="selectidplaza" required [(ngModel)]="selectedGroup">
                  <option value="0" [innerHTML]="'userprofile.seleccionaplaza' | translate">

                  </option>
                  <optgroup [attr.label]="tipoplaza.name" *ngFor="let tipoplaza of groupedArray">
                    <ng-container *ngFor="let disbursementTypeOption of tipoplaza.values">
                      <option [value]="disbursementTypeOption.id" [style.background-color]="disbursementTypeOption.color">
                        {{disbursementTypeOption.nombreplaza}}
                      </option>
                    </ng-container>

                  </optgroup>
                </select>
                <button class="btn btn-primary f5 btn-sm" type="button" [innerHTML]="'userprofile.save'| translate" (click)="selectPlaza(subscription.id);"></button>
                <button class="btn btn-primary btn-sm f5" type="button" [innerHTML]="'userprofile.cancel'| translate" (click)="cancelPlaza();"></button>
              </div>
            </div>

          </ng-container>
          <a (click)="getCode(i)" class="btn btn-primary f4 font-weight-bold mt-1">
            <ng-container *ngIf="subscription.typecode===typecode.CODE_VAR"><span [innerHTML]="'userprofile.solicitarpin'| translate"></span></ng-container>
            <ng-container *ngIf="!getStatus(i) && subscription.typecode===typecode.CODE_FIXED">
              <span [innerHTML]="'userprofile.aparcarbicicleta'| translate"></span>
            </ng-container>
            <ng-container *ngIf="getStatus(i) && subscription.typecode===typecode.CODE_FIXED">
              <span [innerHTML]="'userprofile.sacarbicicleta'| translate"></span>
            </ng-container>
          </a>
          @if(subscription.hasmecanico){
          <a (click)="getMecanico(subscription.idmecanico,subscription.mecanicophone,subscription.idparking)" class="btn btn-primary f4 font-weight-bold mt-1 ms-3">
            <span [innerHTML]="'userprofile.solicitarmecanico'| translate"></span>
          </a>
          }
          <p class="card-text f5 mb-1 text-black">Valido hasta {{subscription.enddate | date:'dd-MM-yyyy'}} </p>
          <p class="card-text f6 mt-1" *ngIf="subscription.status === 'activa' && user.trasteros===0">
            <a class="text-black-50" style="cursor: pointer;" (click)="cancelSubscription(subscription.idsubscription,i)">
              <!--TODO-->
              <i class="far fa-times-circle"></i> CANCELAR RENOVACION
            </a>
          </p>
          <p class="card-text f6 mt-1" *ngIf="subscription.status === 'cancelada'">ABONO CANCELADO </p>
        </div>
      </div>
    </div>
    }
    @else if(subscription.status === 'activa'){
    <div class="col">
      <div class="mx-auto pt-0 color text-center"></div>
      <div class="card color rounded-0">
        <div class="card-body text-center p-1">
          <h4 class="card-title mb-0">
            {{ subscription.nombre | uppercase | unescape }}
          </h4>
          <p class="card-text mt-1 me-3">ABONO PENDIENTE DE PAGO <a class="btn btn-primary btn-sm" style="cursor: pointer;" (click)="paySubscription(subscription.idsubscription,i)">
              PAGAR AHORA ({{subscription.importe}}€)
            </a>
          </p>
        </div>
      </div>
    </div>
    }
  </div>
  <div class="row" *ngIf="suscripcions.length===0">
    <div class="col">
      <div class="mx-auto pt-3 color">
        <app-descubre [showflecha]="false"></app-descubre>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 bg-info text-dark">
          PAGAR POR HORAS
          <a class="float-end align-middle me-2 text-dark" placement="left" ngbTooltip="Solo pagas por las horas estacionadas (válido en toda la red)">
            <i class="fas fa-info-circle fa-lg"></i>
          </a>
        </div>

        <div class="card-body text-center pb-0">
          <app-tiempoparking [porhoras]="user.porhoras" *ngIf="user.porhoras.id!==0"></app-tiempoparking>
          <div class="btn-group d-flex mt-2" role="group"
            aria-label="Basic
            example">
            <div class="mx-1 flex-fill">
              <div class="d-grid gap-2">
                <button class="btn btn-primary flex-fill" (click)="finishParking()" *ngIf="user.porhoras.id!==0">
                  Terminar Parking
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body text-center">
          <p>Tu saldo para aparcar por horas en cualquier parking de la red es de:</p>

          <div class="btn-group d-flex" role="group">
            <div class="mx-1 flex-fill">
              <div class="d-grid gap-2">
                <div class="btn btn-info flex-fill">
                  {{ (user.credito/100) | number:'1.2' }}€
                </div>
              </div>

            </div>
            <div class="mx-1 flex-fill">
              <div class="d-grid gap-2">
                <button class="btn btn-primary flex-fill" (click)="recargarSaldo()">
                  Recargar saldo
                </button>
              </div>
            </div>
          </div>
          <div class="btn-group d-flex mt-2" role="group">
            <div class="mx-1 flex-fill">
              <div class="d-grid gap-2">
                <button class="btn btn-primary flex-fill" (click)="redirect()" *ngIf="user.porhoras.id===0">
                  Aparcar
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row my-3">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 bg-info text-dark">
          TARJETAS REGISTRADAS
        </div>
        <div class="card-body text-center">
          <p *ngIf="registeredcards.length<1">No tienes ninguna tarjeta de pago registrada.</p>
          <p *ngIf="registeredcards.length>0">Tienes {{registeredcards.length}} tarjeta(s) de pago registrada(s):</p>
          <div class="list-group list-group-flush" *ngIf="registeredcards.length>0">
            <li class="list-group-item d-flex justify-content-between align-items-center f6 px-1" *ngFor="let registeredcard of registeredcards; let i=index">
              XXXX-XXXX-XXXX-XXXX Exp: {{ registeredcard.expira | expiredate }}
              <div class="custom-control custom-switch">
                <input type="radio" class="form-check-input" name="customSwitch" [attr.data-index]="i" [checked]="registeredcard.userstatus" value="{{i}}" (change)="handleChange($event)"
                  id="customSwitch{{i}}">
                <label class="custom-control-label" for="customSwitch{{i}}">
                  <span *ngIf="registeredcard.userstatus">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span *ngIf="!registeredcard.userstatus">Activar</span>
                </label>
              </div>
            </li>
          </div>
          <button class="btn btn-primary" (click)="registrarTarjeta()"> Registrar tarjeta </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col">
      <div class="card border-0 rounded-0">
        <div class="card-header border-0 rounded-0 text-center p-0 bg-info text-dark">
          DATOS DE USUARIO
        </div>
        <div class="card-body">
          <form autocomplete="off" [formGroup]="forma" (ngSubmit)="save()">
            <div>
              <div class="input-group">
                <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre" [class.is-invalid]="getNoValido('nombre')" />
                <span class="input-group-text">
                  <i class="fas fa-user"></i>
                </span>
              </div>
              <small class="form-text text-danger"
                *ngIf="getNoValido('nombre')">
                Nombre no valido
              </small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input type="text" formControlName="apellidos" class="form-control" placeholder="Apellidos" [class.is-invalid]="getNoValido('apellidos')" />
                <span class="input-group-text">
                  <i class="fas fa-user"></i>
                </span>
              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('apellidos')"> Apellidos no validos </small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input type="email" formControlName="email" class="form-control" placeholder="Email" [class.is-invalid]="getNoValido('email')" autocomplete="username" />
                <span class="input-group-text">
                  <i class="fas fa-at"></i>
                </span>
              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('email')">Correo no válido</small>
              <small class="form-text text-danger" *ngIf="emailused">Correo en uso</small>
            </div>
            <div>
              <div class="input-group mt-3">
                <input type="tel" formControlName="telefono" class="form-control" placeholder="Telefono" [class.is-invalid]="getNoValido('telefono')" autocomplete="telefono" minlength="9"
                  maxlength="14" />
                <span class="input-group-text">
                  <i class="fas fa-at"></i>
                </span>
              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('telefono')">Campo obligatorio</small>
            </div>
            <div>
              <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" value="true" formControlName="checkads" />
                <label class="form-check-label f6">{{ 'ofertasypromociones' | translate}}</label>
              </div>
              <small class="form-text text-danger" *ngIf="getNoValido('checkads')">Campo obligatorio</small>
            </div>
            <div class="row mt-3">
              <div class="col">
                <div class="d-grid gap-2">
                  <button type="submit" class="btn btn-primary f5" [disabled]="formloading">
                    <span *ngIf="!formloading">Actualizar</span>
                    <i class="f5 fas" [ngClass]="{ 'fa-sync fa-spin': formloading }"></i>
                  </button>
                </div>
              </div>
              <!-- /.col -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<swal #fourSwal title="SweetAlert2" [showConfirmButton]="true">
  <div *swalPortal>
    <p *ngIf="!status" [innerHTML]="'firecode.confirminicioparking'|translate"></p>
    <p *ngIf="status" [innerHTML]="'firecode.confirmfinalparking'|translate"></p>
    <p *ngIf="plazasnumeradas" class="text-bold text-center f1">{{'firecode.atplaza'| translate}} <span [ngStyle]="{'background-color': color}">{{plaza}}</span></p>
  </div>
</swal>
