<div id="note" *ngIf="getElapsedTime() as elapsed" class="container-sm px-0">
  <a [routerLink]="['/finish']" style="color: #000;">
    <div class="row mx-0" style="border: 5px var(--bs-primary-border-subtle) solid">
      <div class="col-3 px-0 f6"> <span style="color: #F00;"
          class="align-middle">EN USO</span></div>
      <div class="col-7 f6 px-0 text-start">
        <span class="align-middle">{{ nombreParking|uppercase }}- LLevas
          {{elapsed.hours |
          number:'2.0'}}:{{elapsed.minutes | number:'2.0'}}</span></div>
      <div class="col-2 ps-0 text-center align-middle">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </a>
</div>
