import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expiredate',
})
export class ExpiredatePipe implements PipeTransform {
  transform(expira: string): string {
    return (
      expira.toString().substr(-2, 2) + '/' + expira.toString().substr(0, 2)
    );
  }
}
