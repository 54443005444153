import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpParams } from '@angular/common/http';
import { Serviciosparkings } from 'src/app/core/enum/serviciosparkings.enum';
import Swal from 'sweetalert2';
import { msgTPV } from 'src/app/core/models';
@Component({
  selector: 'app-recibo',
  templateUrl: './recibo.component.html',
})
export class ReciboComponent implements OnInit {
  eServiciosparkings = Serviciosparkings;
  loading: boolean;
  msg: string;
  constructor(private router: ActivatedRoute, private api: ApiService, private route: Router) {
    this.loading = false;
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.router.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        const parameters = new HttpParams({ fromObject: params });
        this.api.get('/noti/recibotpv', parameters).subscribe({
          next: (data) => {
            this.msg = data.msg;
            this.loading = true;
            switch (data.msg.idservicio) {
              case Serviciosparkings.PARKING_SUSCRIPCION:
                this.fireCreatedSubcription(data.msg);
                break;
              case Serviciosparkings.PARKING_PORHORAS:
                this.fireCreditConfirm(data.msg);
                break;
              case Serviciosparkings.PARKING_REGISTROTARJETA:
                this.fireRegCardConfirm();
                break;
              case Serviciosparkings.PARKING_REPARACIONSIMPLE:
                this.firePaidRepairConfirm();
                break;
              default:
                break;
            }
          },
        });
      } else {
        this.api.get('/noti/getlastaction').subscribe({
          next: (data) => {
            this.msg = data.msg;
            this.loading = true;
            switch (data.msg.idservicio) {
              case Serviciosparkings.PARKING_SUSCRIPCION:
                this.fireCreatedSubcription(data.msg);
                break;
              case Serviciosparkings.PARKING_PORHORAS:
                this.fireCreditConfirm(data.msg);
                break;
              case Serviciosparkings.PARKING_REGISTROTARJETA:
                this.fireRegCardConfirm();
                break;
              case Serviciosparkings.PARKING_REPARACIONSIMPLE:
                this.firePaidRepairConfirm();
                break;
              default:
                break;
            }
          },
        });
      }
    });
  }
  fireCreatedSubcription(msg: msgTPV) {
    let html$ = `<p class="card-text">En el parking: ${msg.nombre}</p><p class="card-text">Desde el ${msg.initdate}<br> hasta el ${msg.enddate}</p`;

    if (msg.plaza) {
      html$ = html$ + `<br><p class="card-text">Su plaza asignada es la<span style="color:#ffffff;background-color:${msg.plaza.color}"> ${msg.plaza.nombreplaza}&nbsp;</span></p>`;
    }
    Swal.fire({
      title: 'Se ha realizado la reserva',
      html: html$,
      icon: 'success',
    }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }

  fireRegCardConfirm() {
    Swal.fire({
      title: 'Registro correcto',
      text: 'El registro de la tarjeta se ha realizado correctamente',
      icon: 'success',
    }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }
  fireCreditConfirm(msg) {
    Swal.fire({
      title: 'Recarga correcta',
      text: `Su crédito se ha recargado con ${msg.importe}€`,
      icon: 'success',
    }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }
  firePaidRepairConfirm() {
    Swal.fire({
      title: 'Pago correcto',
      text: 'El pago de su reparación se ha realizado correctamente',
      icon: 'success',
    }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }
}
