import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { ResizeService } from 'src/app/core/services/resize.service';
import { delay } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/core/services/screen-size.enum';

import { HomeService } from '../../core/services/home.service';
import { ParkingInfo, Parking } from '../../core/models/parking.model';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { TranslateService } from '@ngx-translate/core';
import { DefaultRedParams, DefaultRedSeccion, RedSeccion, Redparams } from 'src/app/core/models';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [],
})
export class HomeComponent implements OnInit {
  size: SCREEN_SIZE;

  show = false;
  parkinginfo: ParkingInfo;
  public parkings: Parking[] = [];
  url = environment.api_url;
  loadingStatus$: Observable<boolean>;
  public redparams: Redparams = DefaultRedParams;
  public secciones: RedSeccion = DefaultRedSeccion;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(@Inject(DOCUMENT) private doc: Document, private router: Router, private resizeSvc: ResizeService, private homeservice: HomeService, private loadingService: Loaderv2Service, private translate: TranslateService) {
    // this.show = true;
    //  this.loading = true;
    this.resizeSvc.onResize$.pipe(delay(0)).subscribe((x) => {
      this.size = x;
    });
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }

  clickParking(idParking: number) {
    //(click)="clickParking(parking.id)"
    this.router.navigateByUrl(`/mapa/${idParking}`);
  }

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.initProgress();
    // this.plazas = 160;

    /* this.homeservice.getParkingsInfo().subscribe(
      (parkinginfo) => {
        this.loading = false;
        this.parkinginfo = parkinginfo.msg;
      },
      (error) => {}
    );*/
    forkJoin([
      this.homeservice.getHomeParkingList(),
      this.homeservice.getRed(),
      this.homeservice.getSecciones(),
      // this.homeservice.getParkingsInfo(),
    ]).subscribe((results) => {
      this.parkings = results[0].msg;
      this.redparams = results[1].msg;
      this.secciones = results[2].msg;

      //  this.parkinginfo = results[1].msg;
      //   this.loading = false;
      //   this.LoaderService.setBodyLoading(false);
    });
    const body = this.doc.getElementsByClassName('bp-banner__contentleft')[0];
    const s2 = this.doc.createElement('script');
    s2.type = 'text/javascript';
    s2.src = `//mautic.biciparc.com/form/generate.js?id=1`;
    // const body = this.doc.getElementsByTagName('body')[0];
    body.appendChild(s2);
  }
  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }
}
