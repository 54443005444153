 <app-firecode></app-firecode>
<div class="container-sm justify-content-center" *ngIf="(loadingStatus$ |async )">
  <app-loading class="m-5"></app-loading>
</div>

<ng-container *ngIf="(loadingStatus$ |async ) === false && !(loadingStatus)">

  <div [ngClass]="{ 'invisible':loadingStatus , 'animate__animated':true,'animate__fadeIn': !loadingStatus }">
    <div class="container-sm justify-content-center bp-section--main" *ngIf="type === eServiciosparkings.PARKING_SUSCRIPCION">
      <div class="row header-biciparc mx-0">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div class="d-flex justify-content-between">
            <div>
              <h5 class="nombre_parking">{{ parking.nombre| unescape | uppercase}} ({{parking.direccion}})</h5>
            </div>
            <div>
              <a href="https://maps.google.com?saddr=Current+Location&dirflg=b&daddr={{ parking.latitud }},{{ parking.longitud }}" class="btn btn-location header-biciparc" target="_blank">
                <i class="fas fa-location-arrow"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div #allTheseThings class="row mx-0 mt-1 login-box-body" *ngFor="let item of parking.servicios;let i= index; let last= last">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center" *ngIf="item.planes.length>0">
          <h4 class="text-center">{{ item.descripcion | uppercase}} {{parking.nombre | unescape | uppercase}}</h4>
          <ng-container *ngIf="step===0">
            <form autocomplete="off" [formGroup]="forma" (ngSubmit)="guardar(item.id)">
              <div class="mb-3">

                <div class="form-check ps-0" *ngFor="let plan of item.planes">
                  <input type="radio" class="form-check-input" formControlName="plan" value="{{ plan.id }}" id="plan{{ plan.id }}">
                  <label class="radio f5 mb-0" for="plan{{ plan.id }}" [ngClass]="{ 'bg-color-sucess':plan.offerapplied}">
                    <span class="flexlabel">{{plan.plan | titlecase}} - {{ (plan.precio / plan.proxmeses) | number: '1.0-2'}}€/mes
                      <ng-container *ngIf="plan.proxmeses>1">
                        (pagando {{plan.proxmeses}} meses). <span class="f6"> Valido hasta el {{endDate(plan.meses) | date:'dd/MM/yyyy' }}</span>
                      </ng-container>
                      <ng-container *ngIf="plan.proxmeses===1">
                        (pagando 1 mes). <span class="f6"> Valido hasta el {{endDate(plan.meses) | date:'dd/MM/yyyy' }}</span>
                      </ng-container>
                    </span>
                    <p>
                    </p>
                  </label>
                </div>
                <small class="form-text text-danger" *ngIf="isSubmitted && noValidPlan.errors?.required">Debe seleccionar un plan</small>
              </div>
              <div class="alert alert-success clearfix" role="alert" *ngIf="item.offerapplied && !editable" [ngClass]="{ 'invisible':!item.offerapplied && editable , 'animate__animated':true,'animate__fadeIn':item.offerapplied && !editable }">
                <span class="float-start">Código aplicado: {{qrcode}}</span><button type="button" class="btn-close float-end" aria-label="Close" (click)="editQRCode()"></button>
              </div>
              <div class="alert alert-warning clearfix" role="alert" *ngIf="!item.offerapplied && !editable" [ngClass]="{ 'invisible':item.offerapplied && editable , 'animate__animated':true,'animate__fadeIn': !item.offerapplied && !editable }">
                <span class="float-start">Código invalido o no se puede aplicar en este parking: {{qrcode}}</span><button type="button" class="btn-close float-end" aria-label="Close" (click)="editQRCode()"></button>
              </div>

              <div class="input-group mb-3" *ngIf="editable" [ngClass]="{ 'invisible':!editable , 'animate__animated':true,'animate__fadeIn': editable }">
                <input #inputqrcode type="text" class="form-control" placeholder="Codigo de descuento" aria-label="Codigo de descuento" aria-describedby="qrlabel" value="{{qrcode}}">
                <button class="btn btn-primary btn-middle" type="button" id="qrlabel" (click)="activateCamera()"><i class="fas fa-camera"></i></button>
                <button class="btn btn-primary btn-end" type="button" id="qrlabel" (click)="sendQRcode()"><i class="fas fa-check"></i></button>
              </div>
              <zxing-scanner *ngIf="activatedCamera"
                (permissionResponse)="checkPermissions($event)"
                (camerasFound)="camerasFoundHandler($event)"
                (scanComplete)="handleData($event)"></zxing-scanner>

              <div class="mb-3">
                <div class="form-check">
                  <label class="custom-control-label f6" for="customCheck1">
                    Acepto los
                    <a [routerLink]="['/terms']" class="text-black-50">términos, condiciones</a> y la
                    <a [routerLink]="['/privacy']" class="text-black-50">política de privacidad</a>
                    de {{redparams.nombre}}.
                  </label>
                  <input type="checkbox" class="form-check-input" id="customCheck1" value="true" formControlName="checkterms" [class.is-invalid]="getNoValido('checkterms')">
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('checkterms')">Debes aceptar los términos y condiciones</small>
              </div>

              <div class="d-grid gap-2">
                <button *appIsAuth="true" type="submit" class="btn btn-primary f5" [disabled]="false">
                  <span>Contratar abono</span>
                  <i class [ngClass]="{ 'f5 fas fa-sync fa-spin': false }"></i>
                </button>
              </div>

            </form>

            <a [routerLink] *appIsAuth="true" class="f5 mt-3 text-dark" (click)="porhoras()"> <span>Prefiero aparcar por horas</span> </a>
          </ng-container>
          <ng-container *ngIf="step===2">
            <!--//TODO:Arreglar plan contratado-->
            <form #form action="{{formUrl}}" method="post">
              <p>Precio: {{ (precio) | number: '1.0-2'}} € </p>
              <p>A nombre de: {{ user.nombre}} {{user.apellidos}} </p>
              <p>Plan contratado: {{plan}}</p>
              <p>Fecha de renovación: {{ fechafin }} (Puedes cancelar en cualquier momento).</p>
              <input type="hidden" name="Ds_MerchantParameters" value="{{ tpvData.Ds_MerchantParameters}}" />
              <input type="hidden" name="Ds_Signature" value="{{ tpvData.Ds_Signature}}" />
              <input type="hidden" name="Ds_SignatureVersion" value="{{ tpvData.Ds_SignatureVersion}}" />
              <div class="d-grid gap-2">
                <button *appIsAuth="true" type="submit" class="btn btn-primary f5" (click)="form.submit()">
                  <span *ngIf="true">Contratar abono</span>
                  <i class [ngClass]="{ 'f5 fas fa-sync fa-spin': false }"></i>
                </button>
              </div>

            </form>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="container-sm justify-content-center bp-section--main" *ngIf="type === eServiciosparkings.PARKING_PORHORAS">
      <div class="row header-biciparc mx-0">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div class="d-flex justify-content-between">
            <div><h5 class="nombre_parking">{{ parking.nombre | unescape | uppercase}} ({{parking.direccion}})</h5></div>
            <div>
              <a href="https://maps.google.com?saddr=Current+Location&dirflg=b&daddr={{ parking.latitud }},{{ parking.longitud }}" class="btn btn-location header-biciparc" target="_blank">
                <i class="fas fa-location-arrow"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div #allTheseThings class="row mx-0 mt-1 login-box-body" *ngFor="let item of parking.servicios;let i= index let last= last">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center" *ngIf="item.planes.length>0">
          <h4 class="text-center" style="border-bottom: 1px solid black;">{{ item.descripcion | uppercase}} </h4>
          <ng-container *ngIf="step===0">
            <form autocomplete="off" [formGroup]="forma" (ngSubmit)="guardar(item.id)">
              <div class="mb-3">
                <div class="row mx-auto f5" *ngFor="let plan of item.planes">
                  <div class="col-9 me-0 px-0" style="border-bottom: 1px solid black;">
                    Desde {{ (plan.minminutos/60) |number:'1.0-0' }} hasta {{ plan.maxminutos/60 |number:'1.0-0'}} horas
                  </div>
                  <div class="col-3 px-0" style="border-bottom: 1px solid black;">{{ plan.precio}} €</div>
                </div>
              </div>
              <div class="mb-3">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" id="customCheck1" value="true" formControlName="checkterms" [class.is-invalid]="getNoValido('checkterms')">
                  <label class="custom-control-label f6" for="customCheck1">
                    Acepto los
                    <a [routerLink]="['/terms']" class="text-black-50">términos, condiciones</a>
                    y la
                    <a [routerLink]="['/privacy']" class="text-black-50">política de privacidad</a>
                    de {{redparams.nombre}}.
                  </label>
                </div>
                <small class="form-text text-danger" *ngIf="getNoValido('checkterms')">Debes aceptar los términos y condiciones</small>
              </div>
              <div class="d-grid gap-2">
                <button *appIsAuth="true" type="submit" class="btn btn-primary f5" [disabled]="false">
                  <span *ngIf="dentro===0">Aparcar</span>
                  <span *ngIf="dentro!==0">Terminar parking</span>
                  <i class [ngClass]="{ 'f5 fas fa-sync fa-spin': false }"></i>
                </button>
              </div>

            </form>
            <a *appIsAuth="true" [routerLink] class="f5 mt-3 text-dark" (click)="porabono()">
              <span *ngIf="true">Prefiero contratar un abono</span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>

    <div [ngClass]="{ 'invisible':loadingStatus , 'animate__animated':true,'animate__fadeIn': !loadingStatus }">
      <app-auth *appIsAuth="false" [alone]="false"></app-auth>
    </div>
  </div>
</ng-container>
