import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  getToken(): string {
    // tslint:disable-next-line: no-string-literal
    return window.localStorage['jwtToken'];
  }

  saveToken(token: string) {
    // tslint:disable-next-line: no-string-literal
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }
}
