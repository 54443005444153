import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Serviciosparkings } from '../enum/serviciosparkings.enum';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class ParkingsService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parkinglist$: Observable<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  myParkingList: any;
  headersload: HttpHeaders;
  httpParams: HttpParams;

  constructor(private apiService: ApiService) {
    this.headersload = new HttpHeaders().set('load', '1');
    this.httpParams = new HttpParams();
    this.parkinglist$ = this.apiService.get('/api/getparkinglistv4', this.httpParams, this.headersload).pipe(shareReplay(1));
  }

  setReparacion($idmecanico: number, $idparking: number) {
    const data = { idplaza: 0, idvehiculo: 0, idmecanico: $idmecanico, idparking: $idparking };
    return this.apiService.put(`/parking/setreparacion`, data);
  }

  setPlaza($idPlaza: number, $idSuscripcion: number) {
    const data = { idplaza: $idPlaza.toString(), idsuscripcion: $idSuscripcion.toString() };
    return this.apiService.put(`/parking/setplaza`, data);
  }

  getPlazasLibres($idparking: number) {
    return this.apiService.get(`/parking/getplazaslibres/${$idparking}/${Serviciosparkings.PARKING_SUSCRIPCION}`);
  }

  getPlazaLibreSuscripcion($idparking: number) {
    return this.apiService.get(`/parking/getplazalibre/${$idparking}/${Serviciosparkings.PARKING_SUSCRIPCION}`);
  }

  getPlazaLibrePorHoras($idparking: number) {
    return this.apiService.get(`/parking/getplazalibre/${$idparking}/${Serviciosparkings.PARKING_PORHORAS}`);
  }
  getPlazasParkings(id: number) {
    if (id < 0) {
      id = 0;
    }
    return this.apiService.get(`/api/getplazas/${id}`);
  }

  getParking(id: number, type: number, qrcode?: string) {
    if (typeof qrcode === 'undefined') {
      qrcode = '';
    }
    return this.apiService.get(`/api/getparkinglistv4/${id}/${type}/${qrcode}`);
  }

  getParkingList() {
    return this.parkinglist$;
  }

  getTpvDataRenew(idsuscription: number) {
    return this.apiService.get(`/tpv/subscriptrenew/${idsuscription}/`);
  }

  getTpvData(idplan: number, qrcode: string) {
    return this.apiService.get(`/tpv/gettpvdata/subscript/${idplan}/${qrcode}`);
  }

  getTpvDataRegCard() {
    return this.apiService.get(`/tpv/gettpvdata/regcard`);
  }
  getTpvDataCredits(idcredit: number) {
    return this.apiService.get(`/tpv/gettpvdata/buycredits/${idcredit}`);
  }
  getCredits() {
    return this.apiService.get(`/tpv/getcredits/`);
  }

  getUserSubscriptions() {
    return this.apiService.get(`/user/getusersubscriptions`);
  }

  getCodeKey(id: number, idServicio: number) {
    return this.apiService.get(`/parking/getcodekey/${id}/${idServicio}`);
  }

  cancelSubscription(id: number) {
    return this.apiService.get(`/parking/cancelsubscription/${id}`);
  }
  devuelvePrecio(id: number) {
    return this.apiService.get(`/parking/devuelvePrecio/${id}`);
  }
}
