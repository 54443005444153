<div class="container-sm bp-section--main">
  <div class="row">
    <div class="col">
      <div class="login-box">
        <div class="login-logo">
          <a [routerLink]="['/home']"><b>B</b>iciparc</a>
        </div>
        <!-- /.login-logo -->
        <div class="card">
          <div class="register-box-body f5">
            <p class="login-box-msg">¿Has olvidado tu contraseña? <br/>Introduce el email con el que te registraste.</p>
              <form autocomplete="off" [formGroup]="forma" (ngSubmit)="guardar()">
                <div>
                  <div class="input-group mb-3">
                    <input type="email" formControlName="email" class="form-control" placeholder="Email" [class.is-invalid]="getNoValido('email') && !emailmalformed" />
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                  <small class="form-text text-danger" *ngIf="getNoValido('email') || emailmalformed">Correo no válido</small>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid gap-2">
                      <button type="submit" class="btn btn-primary f5">Solicitar nueva contraseña</button>
                    </div>
                  </div>
                  <!-- /.col -->
                </div>
              </form>
            </div>
            <!-- /.login-card-body -->
          </div>
        </div>
      </div>
    </div>
  </div>
