import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Map, View, Overlay } from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { Icon, Style } from 'ol/style';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { defaults as defaultControls } from 'ol/control';

import { Parking } from 'src/app/core/models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit, OnDestroy {
  selectedParking: number;

  // parkings: any[];

  loading: boolean;
  @Input() parking!: Parking;

  map: Map;
  vectorSource: VectorSource;
  greenIcon = new Style({
    image: new Icon({
      anchor: [0.5, 33],
      anchorXUnits: 'fraction',
      anchorYUnits: 'pixels',
      src: 'assets/img/icon-1.png',
    }),
  });
  viewmap: View;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  ngOnInit(): void {
    this.creaMapa();
  }

  private creaMapa(): void {
    //useGeographic();
    this.viewmap = new View({
      zoom: 18,
      center: fromLonLat([this.parking.longitud, this.parking.latitud]),
      minZoom: 18,
      maxZoom: 18,
    });
    const vectorLayer = new VectorLayer({
      source: this.generateData(),
    });
    const rasterLayer = new TileLayer({
      source: new XYZ({
        url: environment.tile_server,
        attributions: environment.attribution,
      }),
    });

    this.map = new Map({
      view: this.viewmap,
      layers: [rasterLayer, vectorLayer],
      target: 'minimap',
      controls: defaultControls({ zoom: false, attributionOptions: { collapsible: false } }),
      interactions: [],
    });
    const element = document.getElementById('popup');

    const popup = new Overlay({
      element: element,
      positioning: 'bottom-center',
      stopEvent: false,
    });
    this.map.addOverlay(popup);

    //   this.viewmap.setProperties({ extent: fromLonLat(this.viewmap.calculateExtent()) });
  }

  generateData(): VectorSource {
    const vectorSource = new VectorSource();

    const marker = new Feature({
      geometry: new Point(fromLonLat([this.parking.longitud, this.parking.latitud])),
      name: this.parking.nombre,
    });
    marker.setStyle(this.greenIcon);
    vectorSource.addFeature(marker);

    return vectorSource;
  }

  ngOnDestroy(): void {
    this.map.dispose();
  }
}
