import { AfterViewInit, ChangeDetectorRef, EventEmitter, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, HostListener } from '@angular/core';

//import { DeviceDetectorService } from 'ngx-device-detector';
import { ClosingState, LightboxData, ShowState } from 'src/app/core/models';
import { DeviceDetectorService } from 'src/app/core/services/device-detector.service';
import { EventService } from '../../core/services/event.service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements OnInit, AfterViewInit {
  constructor(private ref: ChangeDetectorRef, public eventService: EventService, private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
  }
  created = false;
  prevIndex!: number;
  spinnerHeight = 30;
  isZoomIn!: boolean;
  minTimeout = 30;
  preloaderTimeout = 100;
  spinnerStyles = {
    transform: '',
  };
  configThumbnailPreloader = true;
  events = new EventEmitter();

  @HostBinding('class.lightbox-shown') hostShown = false;
  @HostBinding('class.lightbox-hide-controls') hideControls = false;
  @HostBinding('class.lightbox-animation') hostAnimation = false;
  @HostBinding('class.lightbox-simple-mode')
  get simpleMode() {
    return this.properties?.simpleMode;
  }

  @HostBinding('class.lightbox-light') get hostLightTheme() {
    return this.properties?.backgroundColor === 'white';
  }

  @HostBinding('style.backgroundColor')
  hostStyleBackgroundColor!: string;

  @ViewChild('prevImageElem')
  prevImageElem!: ElementRef;
  @ViewChild('lightboxContainer')
  lightboxContainerElem!: ElementRef;

  get currImagePath() {
    const image = this.images[this.index];
    let path;

    if (!image) {
      return false;
    }

    if (image.fullImage && image.fullImage.path) {
      path = image.fullImage.path;
    } else if (image.thumbnailImage && image.thumbnailImage.path) {
      path = image.thumbnailImage.path;
    } else if (image.path) {
      path = image.path;
    }

    return path;
  }

  get prevImagePath() {
    return this.images[this.prevIndex];
  }

  set prevImagePath(value) {
    this.images[this.prevIndex] = value;
  }

  get isHiddenPrevArrow() {
    return (this.isFirstImage && !this.properties?.loop) || this.isZoomIn;
  }
  get isHiddenNextArrow() {
    return (this.isLastImage && !this.properties?.loop) || this.isZoomIn;
  }

  get isPreloader() {
    return this.animationMode === 'zoom-preloader' && this.showState != 'animation-end' && this.currImageLoadingState === 'loading';
  }
  get imageOrientation(): 'vertical' | 'horizontal' {
    if (this.thumbnailImage.naturalWidth > this.thumbnailImage.naturalHeight) {
      return 'horizontal';
    } else {
      return 'vertical';
    }
  }

  @HostListener('window:scroll') scrolling() {
    if (this.showState === 'initial-thumbnail-image' || this.showState === 'initial-virtual-image' || this.closingState === 'animation') {
      this.updateThumbnailPosition();
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {
    switch (event.key) {
      case 'ArrowLeft':
        this.prev();
        break;
      case 'ArrowRight':
        this.next();
        break;
      case 'Escape':
        this.closeLightbox();
        break;
    }
  }

  @HostListener('mouseenter', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMouseEnter(event) {
    this.hideControls = false;
  }

  @HostListener('transitionend', ['$event'])
  transitionEnd(event) {
    if (event.propertyName === 'transform' && this.hostAnimation) {
      this.hostAnimation = false;
    }
  }
  ngOnInit(): void {
    this.currentImageIndex = this.properties.index;
    this.setAnimationDuration();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.currImageLoadingState === 'not-loaded') {
        this.currImageLoadingState = 'loading';
      }
    }, this.preloaderTimeout);
    this.initialLightbox();
    // Mode: default
    if (this.animationMode === 'default') {
      setTimeout(() => {
        this.showLightboxAnimation();
      }, this.minTimeout);
    }
  }

  onImageLoaded() {
    // When opening lightbox
    //console. log('onImageLoaded');
    if (this.animationMode === 'zoom-preloader' && this.showState === 'initial-thumbnail-image') {
      this.initialLightboxVirtualImage();
      setTimeout(() => {
        this.currImageLoadingState = 'uploaded';
        this.showLightboxAnimation();
        if (this.properties?.hideThumbnail) {
          this.hideThumbnailImage();
        }
      }, this.minTimeout);
    }

    // When opening next / previous image
    if (this.showState === 'animation-end') {
      this.currImageLoadingState = 'uploaded';
      if (this.properties?.hideThumbnail) {
        this.hideThumbnailImage();
      }
    }

    this.ref.detectChanges();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onImageError(event) {
    this.currImageLoadingState = 'error';
    this.initialLightboxDefault();

    setTimeout(() => {
      this.showLightboxAnimation();
    }, this.minTimeout);
  }

  onContainerClick(event) {
    if (event.target === this.lightboxContainerElem.nativeElement || this.simpleMode) {
      this.closeLightbox();
    }
  }

  initialLightbox() {
    if (!this.created) {
      this.created = true;
      this.setMaxDimensions();
      //console. log(this.animationMode);
      switch (this.animationMode) {
        case 'zoom-preloader':
          this.initialLightboxThumbnailImage();
          break;
        case 'default':
          this.initialLightboxDefault();
          break;
      }
    }
  }

  initialLightboxDefault() {
    this.showState = 'initial-default';
    this.containerStyles = {
      transform: 'translate3d(0, 0, 0)',
      height: '100%',
      width: '100%',
      opacity: '0',
    };
    // next step: AfterViewInit
  }

  initialLightboxVirtualImage() {
    this.setShowState('initial-virtual-image');
    this.containerStyles = {
      transform: this.containerInitialPosition,
      height: this.virtualImageDimension.height + 'px',
      width: this.virtualImageDimension.width + 'px',
    };
    // next step: onImageLoaded() -> showLightboxAnimation()
  }

  initialLightboxThumbnailImage() {
    this.setShowState('initial-thumbnail-image');
    this.containerStyles = {
      transform: this.containerInitialPosition,
      height: this.thumbnailImagePosition.height + 'px',
      width: this.thumbnailImagePosition.width + 'px',
    };
    // next step: onImageLoaded()
  }

  showLightboxAnimation() {
    this.hostAnimation = true;
    this.setShowState('animation');
    this.hostShown = true;
    this.setBackgroundColor();
    this.setAnimationDuration();

    // Mode: zoom preloader
    if (this.animationMode === 'zoom-preloader' && this.currImageLoadingState !== 'error') {
      this.containerStyles.transform = this.containerFullscreenPosition;
    }

    // Mode: default
    if (this.animationMode === 'default') {
      this.containerStyles.opacity = '1';
    }
    // next step: handleLightboxTransitionEnd
  }

  showLightboxAnimationEnd() {
    this.setShowState('animation-end');
    this.containerStyles = {
      transform: 'translate3d(0, 0, 0)',
      height: '100%',
      width: '100%',
    };
  }

  closeLightbox() {
    this.setClosingState('initial');
    this.hostShown = false;
    this.closeLightboxInitial();
  }

  closeLightboxInitial() {
    this.setClosingState('initial-styles');

    // Mode: zoom preloader
    if (this.animationMode === 'zoom-preloader') {
      this.containerStyles = {
        transform: this.containerFullscreenPosition,
        height: this.virtualImageDimension.height + 'px',
        width: this.virtualImageDimension.width + 'px',
      };
    }

    // Mode: default
    if (this.animationMode === 'default') {
      this.containerStyles.opacity = '1';
    }

    setTimeout(() => {
      this.closeLightboxAnimation();
    }, this.minTimeout);
  }

  closeLightboxAnimation() {
    this.setClosingState('animation');

    // Mode: zoom preloader
    if (this.animationMode === 'zoom-preloader') {
      this.hostAnimation = true;
      this.containerStyles = {
        transform: this.containerInitialPosition,
        height: this.getContainerHeight(),
        width: this.getContainerWidth(),
      };

      this.hostStyleBackgroundColor = '';
    }

    // Mode: default
    if (this.animationMode === 'default') {
      this.hostAnimation = true;
      this.containerStyles.opacity = '0';
      this.hostStyleBackgroundColor = '';
    }

    this.setAnimationDuration();
    // next step: handleLightboxTransitionEnd

    if (this.animationDuration === 0) {
      // in the future, change to a type conversion getter
      this.closeLightboxAnimationEnd();
    }
  }

  closeLightboxAnimationEnd() {
    this.setClosingState('animation-end');
    this.events.emit({ type: 'close' });

    // Mode: zoom preloader
    if (this.animationMode === 'zoom-preloader') {
      this.showThumbnailImage();
    }
  }

  /*
   * Transition End
   */

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleLightboxTransitionEnd(event) {
    if (this.showState === 'animation') {
      this.showLightboxAnimationEnd();
    }

    // Last close step
    if (this.closingState === 'animation') {
      this.closeLightboxAnimationEnd();
    }
  }

  next() {
    if (this.animationMode === 'zoom-preloader') {
      this.showThumbnailImage();
    }
    if (this.isLastImage) {
      if (this.properties.loop) {
        this.currentImageIndex = 0;
      } else {
        return;
      }
    } else {
      this.currentImageIndex++;
      this.currImageLoadingState = 'loading';
    }

    setTimeout(() => {
      if (this.currImageLoadingState !== 'uploaded') {
        this.currImageLoadingState = 'loading';
      }
    }, this.preloaderTimeout);
  }

  prev() {
    if (this.animationMode === 'zoom-preloader') {
      this.showThumbnailImage();
    }

    if (this.isFirstImage) {
      if (this.properties?.loop) {
        this.currentImageIndex = this.latestImageIndex;
      } else {
        return;
      }
    } else {
      this.currentImageIndex--;
      this.currImageLoadingState = 'loading';
    }

    setTimeout(() => {
      if (this.currImageLoadingState !== 'uploaded') {
        this.currImageLoadingState = 'loading';
      }
    }, this.preloaderTimeout);
  }

  setMaxDimensions() {
    this.lightboxImage.nativeElement.style.maxHeight = 'calc(' + this.properties?.imageMaxHeight + ')';
    this.lightboxImage.nativeElement.style.maxWidth = this.properties?.imageMaxWidth;
  }

  handlePinchZoomEvents(event) {
    if (event.type === 'zoom-in') {
      this.isZoomIn = true;
    }

    if (event.type === 'zoom-out') {
      this.isZoomIn = false;
    }
  }

  getContainerWidth(): string {
    return this.thumbnailImagePosition.width / this.containerScale + 'px';
  }
  currentImageIndex = 0;
  indexCurrentSlide = 1;
  showState: ShowState = 'initial';
  closingState: ClosingState = 'initial';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerStyles: any = {
    transition: '',
    transform: '',
    width: '',
    height: '',
    opacity: '',
  };
  currImageLoadingState: 'not-loaded' | 'loading' | 'uploaded' | 'error' = 'not-loaded';
  isMobile: boolean;

  @Input()
  lightboxData!: LightboxData;

  @HostBinding('style.transition')
  hostStyleTransition!: string;

  @ViewChild('imageFirst')
  _imageFirst!: ElementRef;
  @ViewChild('imageSecond')
  _imageSecond!: ElementRef;
  @ViewChild('imageLast')
  _imageLast!: ElementRef;
  @ViewChild('lightboxImage') _lightboxImage!: ElementRef;

  get lightboxImage() {
    return this._lightboxImage;
  }

  get lightboxImageElement() {
    if (this.lightboxImage) {
      return this.lightboxImage.nativeElement;
    }
  }

  get lightboxImageNaturalHeight() {
    if (this.lightboxImageElement) {
      return this.lightboxImageElement.naturalHeight;
    }
  }

  get lightboxImageNaturalWidth() {
    if (this.lightboxImageElement) {
      return this.lightboxImageElement.naturalWidth;
    }
  }

  get index() {
    return this.currentImageIndex;
  }

  get properties() {
    return this.lightboxData.properties;
  }

  get images() {
    return this.lightboxData.images || [this.lightboxData.image];
  }

  get thumbnailImage() {
    if (this.images.length > this.currentImageIndex) {
      return this.images[this.currentImageIndex].nativeElement;
    }
  }

  get thumbnailImagePosition() {
    return this.thumbnailImage.getBoundingClientRect();
  }

  // Image size if it is larger than the window size
  get virtualImageDimension() {
    let height = this.lightboxImageNaturalHeight;
    let width = height * this.imageAspectRatio;
    const windowWidth = document.body.clientWidth;
    const windowHeight = window.innerHeight;

    if (this.isImageLargerWindow) {
      if (height > windowHeight) {
        height = windowHeight;
        width = height * this.imageAspectRatio;
      }

      if (width > windowWidth) {
        width = windowWidth;
        height = width / this.imageAspectRatio;
      }
    } else {
      width = this.lightboxImageNaturalWidth;
      height = this.lightboxImageNaturalHeight;
    }

    if (width === 0 || Number.parseInt(height) === 0) {
      return { width: 200, height: 200 };
    }

    return { width, height };
  }

  get containerInitialPosition() {
    const scale = this.showState === 'initial-thumbnail-image' ? 1 : this.containerScale;
    const top = this.thumbnailImagePosition.top;
    const left = this.thumbnailImagePosition.left;
    return 'matrix(' + scale + ', 0, 0, ' + scale + ',' + left + ',' + top + ')';
  }

  get containerFullscreenPosition() {
    const left = (document.body.clientWidth - this.virtualImageDimension.width) / 2;
    const top = (window.innerHeight - this.virtualImageDimension.height) / 2;

    return 'translate3d(' + left + 'px, ' + top + 'px, 0)';
  }

  get containerScale() {
    return this.thumbnailImagePosition.width / this.virtualImageDimension.width;
  }

  get imageAspectRatio() {
    return this.thumbnailImage.naturalWidth / this.thumbnailImage.naturalHeight;
  }

  get isImageLargerWindow(): boolean {
    const imageNaturalWidth = this.lightboxImageNaturalWidth;
    const imageNaturalHeight = this.lightboxImageNaturalHeight;
    const windowWidth = document.body.clientWidth;
    const windowHeight = window.innerHeight;
    return imageNaturalWidth > windowWidth || imageNaturalHeight > windowHeight;
  }

  get isFirstImage() {
    if (this.properties.loop) {
      return false;
    } else {
      return this.index === 0;
    }
  }

  get isLastImage() {
    if (this.properties.loop) {
      if (this.images.length - 1 === this.currentImageIndex) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.index === this.latestImageIndex;
    }
  }

  get latestImageIndex() {
    return this.images.length - 1;
  }

  get backgroundColor() {
    const opacity = this.properties.backgroundOpacity;
    const color = this.properties.backgroundColor;
    if (color === 'black') {
      return 'rgba(0, 0, 0, ' + opacity + ')';
    } else {
      return 'rgba(255, 255, 255, ' + opacity + ')';
    }
  }

  get animationDuration() {
    const animationDuration = this.properties.animationDuration;

    if (typeof animationDuration === 'string') {
      return Number.parseInt(animationDuration);
    } else {
      return animationDuration;
    }
  }

  get animationMode() {
    if (this.currImageLoadingState === 'error') {
      return 'default';
    }
    return this.properties.animationMode;
  }

  get animationTimingFunction() {
    return this.properties.animationTimingFunction;
  }

  get closeButtonText() {
    return this.properties.closeButtonText;
  }

  get counterSeparator() {
    return this.properties.counterSeparator;
  }

  get counter() {
    // const separator = this.counterSeparator ? this.counterSeparator : '-';
    return this.currentImageIndex + 1 + this.counterSeparator + this.images.length;
  }

  emitState(type: string, state: ShowState | ClosingState) {
    if (state === 'initial-virtual-image' || state === 'initial-styles') {
      return;
    }

    if (state === 'initial-default' || state === 'initial-thumbnail-image') {
      state = 'initial';
    }

    this.eventService.emitChangeEvent({
      type: type + ':' + state,
    });
  }

  setShowState(state: ShowState) {
    this.showState = state;
    this.emitState('show-state', state);
  }

  setClosingState(state: ClosingState) {
    this.closingState = state;
    this.emitState('closing-state', state);
  }

  setAnimationDuration() {
    this.hostStyleTransition = 'background-color ' + this.animationDuration + 'ms';
    this.containerStyles.transition = 'all ' + this.animationDuration + 'ms ' + this.animationTimingFunction;
  }

  setBackgroundColor() {
    this.hostStyleBackgroundColor = this.backgroundColor;
  }

  getContainerHeight(): string {
    return this.thumbnailImagePosition.height / this.containerScale + 'px';
  }

  showThumbnailImage() {
    this.thumbnailImage.style.opacity = '';
  }

  hideThumbnailImage() {
    this.thumbnailImage.style.opacity = 0;
  }

  updateThumbnailPosition() {
    this.containerStyles.transform = this.containerInitialPosition;
  }
}
