import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { CountdownEvent } from 'ngx-countdown';
import { Subscription } from 'rxjs';
import { ParkingsService } from 'src/app/core/services/parkings.service';
import { Unescape } from 'src/app/core/unescape';
import { Serviciosparkings, TypeCodeParkings } from 'src/app/core/enum/serviciosparkings.enum';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FirecodeService } from './firecode.service';
import { Notification, NotificationType } from './notification';
import { UserService } from '../../core/services/user.service';
import { User } from 'src/app/core/models';
//import { Suscripcion } from '../../core/models/subscriptions.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-firecode',
  templateUrl: './firecode.component.html',
})
export class FirecodeComponent implements OnInit, OnDestroy {
  notifications: Notification[] = [];
  private _subscription: Subscription;
  private Options: SweetAlertOptions = {};

  @ViewChild('firstSwal') private firstSwal: SwalComponent;
  @ViewChild('secondSwal') private secondSwal: SwalComponent;
  @ViewChild('thirdSwal') private thirdSwal: SwalComponent;
  @ViewChild('fourSwal') private fourSwal: SwalComponent;
  status: boolean;
  timerStatus: boolean;
  code = 0;
  idsuscripcion: number;
  idservicio: number;
  pulsating: boolean;
  speedpulse: boolean;
  leftTime: number;
  plazasnumeradas: boolean;
  plaza: string;
  color: string;

  user: User = {} as User;

  constructor(private translateSevice: TranslateService, private parkingService: ParkingsService, private _notificationSvc: FirecodeService, private route: Router, private unscape: Unescape, private userService: UserService) {
    this.timerStatus = false;
    this.idsuscripcion = 0;
    this.idservicio = 0;
    this.pulsating = false;
    this.speedpulse = false;

    this.leftTime = environment.production ? 300 : 10;
    this.plazasnumeradas = false;
    this.plaza = '';
    this.color = '';
  }

  private _addNotification(notification: Notification) {
    switch (notification.type) {
      case NotificationType.code:
        this.getCode(parseInt(notification.title, 10), parseInt(notification.message, 10));
        break;
      case NotificationType.nocode:
        this.getNoCode(parseInt(notification.title, 10), parseInt(notification.message, 10));
        break;
      case NotificationType.error:
        this.error(notification.title, notification.message, notification.url);
        break;
      default:
        break;
    }
  }

  error(title: string, message: string, url?: string) {
    Swal.fire({
      title: 'Error',
      html: message,
      icon: 'error',
    }).then(() => {
      if (url !== null) {
        this.route.navigateByUrl(url);
      }
      //
    });
  }

  ngOnInit() {
    this._subscription = this._notificationSvc.getObservable().subscribe((notification) => this._addNotification(notification));
    this.userService.getCurrentUser().subscribe({
      next: (data) => {
        this.user = data.msg;
      },
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  /*
  className(notification: Notification): string {
    let style: string;

    switch (notification.type) {
      case NotificationType.success:
        style = 'success';
        break;

      case NotificationType.warning:
        style = 'warning';
        break;

      case NotificationType.error:
        style = 'error';
        break;

      default:
        style = 'info';
        break;
    }

    return style;
  }*/

  getCode(idsuscripcion: number, idServicio: number) {
    this.parkingService.getCodeKey(idsuscripcion, idServicio).subscribe({
      next: (data) => {
        this.plazasnumeradas = data.msg.plazasnumeradas;
        if (this.plazasnumeradas) {
          this.plaza = data.msg.plaza;
          this.color = data.msg.color;
          this.userService.refreshUser.next(true);
        }
        this.idsuscripcion = idsuscripcion;
        this.code = data.msg.code;
        this.timerStatus = true;
        this.pulsating = false;
        this.speedpulse = false;

        switch (idServicio) {
          case Serviciosparkings.PARKING_SUSCRIPCION:
            if (data.msg.typecode === TypeCodeParkings.CODE_VAR) {
              this.firstSwal.title = this.unscape.it(data.msg.nombre);
              this.firstSwal.fire();
            }

            break;
          case Serviciosparkings.PARKING_PORHORAS:
            this.Options = {
              showCancelButton: false,
              showConfirmButton: false,
              allowOutsideClick: false,
              confirmButtonText: `Aceptar`,
              cancelButtonText: `Cancelar`,
            };
            this.secondSwal.title = this.unscape.it(data.msg.nombre);
            //  this.secondSwal.title = data.msg.nombre;
            this.secondSwal.swalOptions = this.Options;
            this.secondSwal.fire();
            break;

          default:
            break;
        }
      },
      error: (error) => {
        this.idsuscripcion = 0;
        // con sole.log(error);
        //   this.error('Error', 'Ya hay un parking por horas en marcha.');
        switch (error.msg) {
          case 'creditoinsuficiente':
            this.error('Error', 'No tienes sufuciente credito, por favor recarga', '/perfil');
            break;

          default:
            break;
        }
      },
    });
  }

  getNoCode(idsuscripcion: number, idServicio: number) {
    this.parkingService.getCodeKey(idsuscripcion, idServicio).subscribe({
      next: (data) => {
        this.idsuscripcion = idsuscripcion;
        this.plazasnumeradas = data.msg.plazasnumeradas;
        if (this.plazasnumeradas) {
          this.plaza = data.msg.plaza;
          this.color = data.msg.color;
          this.userService.refreshUser.next(true);
        }
        this.timerStatus = false;
        this.pulsating = false;
        this.speedpulse = false;
        switch (idServicio) {
          case Serviciosparkings.PARKING_SUSCRIPCION:
            if (data.msg.typecode === TypeCodeParkings.CODE_FIXED) {
              this.fourSwal.title = this.unscape.it(data.msg.nombre);
              this.status = data.msg.status;
              this.fourSwal.fire();
            }
            /*   this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.route.navigate(['/perfil']);
            });*/
            //  this.route.navigateByUrl('/perfil');
            break;
          case Serviciosparkings.PARKING_PORHORAS:
            this.route.navigateByUrl('/perfil');
            break;
          default:
            break;
        }
      },
      error: (error) => {
        this.idsuscripcion = 0;
        this.idservicio = 0;
        // con sole.log(error);
        //   this.error('Error', 'Ya hay un parking por horas en marcha.');
        switch (error.msg) {
          case 'creditoinsuficiente':
            this.error('Error', 'No tienes sufuciente credito, por favor recarga', '/perfil');
            break;

          default:
            break;
        }
      },
    });
  }

  getNoCodev2(idsuscripcion: number, idservicio: number) {
    this.status = this.getStatus(idsuscripcion);
    this.Options = {
      confirmButtonText: this.status ? this.translateSevice.instant('userprofile.finalizarparking') : this.translateSevice.instant('userprofile.iniciarparking'),
      title: this.status ? this.translateSevice.instant('userprofile.segurofinal') : this.translateSevice.instant('userprofile.seguroinicio'),
    };
    this.idsuscripcion = idsuscripcion;
    this.idservicio = idservicio;
    this.thirdSwal.update(this.Options);
    this.thirdSwal.fire();
  }

  getStatus(idsuscripcion: number): boolean {
    //   const subscription = this.subscriptions[index];
    return this.jsonHasKeyVal(this.user.movimientos.parkings, 'idsuscripcion', idsuscripcion);
  }
  cerrar() {
    this.firstSwal.close();
    this.secondSwal.close();
    this.userService.populate();
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigateByUrl('/perfil');
    });
  }

  handleEvent(e: CountdownEvent) {
    // this.notify = e.action.toUpperCase();
    if (e.action === 'notify') {
      //   this.notify += ` - ${e.left} ms`;
      switch (e.left) {
        case 60000:
          // this.notify = 'coloramarillo';
          this.pulsating = true;
          this.speedpulse = false;
          break;
        case 30000:
          this.pulsating = false;
          this.speedpulse = true;
          break;
        default:
          break;
      }
    }
    if (e.action === 'done') {
      this.timerStatus = false;
    }
    if (e.action === 'start') {
      //    this.timerStatus = true;
    }
  }
  jsonHasKeyVal(json, keyname, value): boolean {
    return Object.keys(json).some((key) => (typeof json[key] === 'object' && json[key] !== null ? this.jsonHasKeyVal(json[key], keyname, value) : key === keyname && json[key] === value));
  }
}
