import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Loader } from './loader.model';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loader = new BehaviorSubject<Loader>({ id: 'global', status: 0 });

  loaderStatus$ = this.loader.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
  /**
   * Show loader
   *
   */
  public showLoader(id = 'global'): void {
    this.loader.next({ id, status: 2 });
  }

  /**
   * Hide loader
   *
   */
  public hideLoader(id = 'global'): void {
    this.loader.next({ id, status: 1 });
    setTimeout(() => {
      this.loader.next({ id, status: 0 });
    }, 900);
  }
}
