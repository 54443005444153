import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from './loader.service';
import { Loader } from './loader.model';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  @Input() public id = 'global';
  public show: number;
  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.loaderStatus$.subscribe((response: Loader) => {
      // this.show = this.id === response.id && response.status;
      this.show = response.status;
    });
  }
}
