import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { ParkingsService } from 'src/app/core/services/parkings.service';
import { Parking } from '../../core/models/parking.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { Loaderv2Service } from '../../core/services/loaderv2.service';
import { HomeService } from 'src/app/core/services/home.service';
import { DefaultRedParams, DefaultRedSeccion, RedSeccion, Redparams } from 'src/app/core/models';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styles: [],
})
export class NavbarComponent implements OnInit {
  public isMenuCollapsed = true;
  public isSubMenuCollapsed = true;
  public isPerfilCollapsed = true;
  public isBodyScrolled = false;
  public redparams: Redparams = DefaultRedParams;
  public parkings: Parking[];
  url = environment.api_url;
  public imageactive = '';
  scrollOffset = 50;
  // public loading: boolean = true;
  loadingStatus$: Observable<boolean>;
  public secciones: RedSeccion = DefaultRedSeccion;
  private wasInside = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isSubMenuCollapsed = true;
      this.isMenuCollapsed = true;
      this.isPerfilCollapsed = true;
      this.checkBodyScroll();
    }
    this.wasInside = false;
  }

  @HostListener('window:scroll', ['$event'])
  ListenScroll($event: Event) {
    if (!this.secciones.bannerblanco) {
      if (this.getYPosition($event) > this.scrollOffset) {
        this.AddBodyScroll();
        this.isBodyScrolled = true;
      } else {
        this.isBodyScrolled = false;
        this.checkBodyScroll();
      }
    }
  }

  checkBodyScroll() {
    if (!this.secciones.bannerblanco) {
      if (!this.isBodyScrolled && this.isSubMenuCollapsed && this.isMenuCollapsed && this.isPerfilCollapsed) {
        this.RemoveBodyScroll();
      }
    }
  }

  getYPosition(e: Event): number {
    return (e.target as Element).children[0].scrollTop;
  }
  AddBodyScroll() {
    this.renderer.addClass(document.body, 'bp-body--scrolled');
  }
  RemoveBodyScroll() {
    this.renderer.removeClass(document.body, 'bp-body--scrolled');
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private renderer: Renderer2,
    private parkingservice: ParkingsService,
    private translate: TranslateService,

    private loadingService: Loaderv2Service,
    private homeservice: HomeService
  ) {
    this.translate.setDefaultLang('es');
    this.translate.use('es');
  }

  ngOnInit(): void {
    this.initProgress();
    this.isMenuCollapsed = true;
    this.isPerfilCollapsed = true;
    this.isSubMenuCollapsed = true;
    /*   this.parkingservice.getParkingList().subscribe({
      next: (data) => {
        this.parkings = data.msg;
      },
    });
    this.homeservice.getSecciones().subscribe({
      next: (data) => {
        this.secciones = data.msg;

      },
    });*/
    forkJoin([this.parkingservice.getParkingList(), this.homeservice.getSecciones(), this.homeservice.getRed()]).subscribe((results) => {
      this.parkings = results[0].msg;

      this.secciones = results[1].msg;
      this.redparams = results[2].msg;
      if (this.secciones.bannerblanco) {
        this.AddBodyScroll();
      }
    });
  }

  clickParking(idParking: number) {
    //  [routerLink] = "['/mapa', parking.id]";
    // this.clickMainMenu();
    this.router.navigateByUrl(`/mapa/${idParking}`);
    this.collapsedAll();
    this.checkBodyScroll();
  }

  collapsedAll() {
    this.isMenuCollapsed = true;
    this.isSubMenuCollapsed = true;
    this.isPerfilCollapsed = true;
  }

  logout() {
    // TODO Preguntar si se quiere salir en un popup
    this.userService.purgeAuth();
    this.router.navigateByUrl('/');
  }

  onmouserover(uuid: string) {
    this.imageactive = uuid;
  }
  onmouseout() {
    this.imageactive = '';
  }

  clickMainMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    if (!this.secciones.bannerblanco) {
      if (!this.isMenuCollapsed) {
        this.AddBodyScroll();
      } else {
        if (!this.isBodyScrolled) {
          this.RemoveBodyScroll();
        }
      }
    }
    if (this.isMenuCollapsed) {
      this.clickMenu();
    }
  }

  clickMenu() {
    this.collapsedAll();
    this.checkBodyScroll();
  }

  clickPertfilItem() {
    this.collapsedAll();
    this.checkBodyScroll();
  }

  clickSubMenu() {
    this.isSubMenuCollapsed = !this.isSubMenuCollapsed;
    this.isPerfilCollapsed = true;
    if (!this.secciones.bannerblanco) {
      if (!this.isSubMenuCollapsed) {
        this.AddBodyScroll();
      } else {
        this.checkBodyScroll();
      }
    }
  }

  clickPerfilMenu() {
    this.isPerfilCollapsed = !this.isPerfilCollapsed;
    if (!this.secciones.bannerblanco) {
      if (!this.isPerfilCollapsed) {
        this.AddBodyScroll();
      } else {
        this.checkBodyScroll();
      }
    }
  }

  initProgress(): void {
    this.loadingStatus$ = this.loadingService.listenLoading();
  }
}
