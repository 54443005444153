
<header class="bp-header" [ngClass]="{ 'invisible':(loadingStatus$ | async ) , 'animate__animated':true,'animate__fadeIn': (loadingStatus$ | async )===false }">
  <nav class="bp-nav--main navbar navbar-expand-xl navbar-light justify-content-end">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']" title="{{redparams.nombre}}" (click)="clickMenu()">
        <img src="assets/img/biciparc-ico.png" alt="{{redparams.nombre}}" class="bp-header__ico" *ngIf="secciones.icono">

        <img [attr.src]="url+'/api/getimage/'+redparams.idfilelogo" alt="Logo {{redparams.nombre}}" class="bp-header__logo">
      </a>


      <ul class="navbar-nav ms-auto pe-3 d-xl-none">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/perfil']" title="{{'navbar.tuplan' | translate }}" (click)="clickMenu()">
            <i class="fas fa-user"></i>
          </a>
        </li>
      </ul>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#bp-navbar--main" (click)="clickMainMenu()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="bp-navbar--main">
        <ul class="navbar-nav pt-2 pt-md-5 pt-xl-0">
          <li class="nav-item dropdown has-megamenu">
            <a class="nav-link dropdown-toggle" [ngClass]="{'show': !isSubMenuCollapsed}" (click)="clickSubMenu()" data-bs-toggle="dropdown" title="{{ 'navbar.nuestrosparkings' | translate }}">
              {{ 'navbar.nuestrosparkings' | translate }}
            </a>
            <div class="dropdown-menu megamenu" role="menu" [ngClass]="{'show': !isSubMenuCollapsed}">
              <div class="row">
                <div class="col-6 col-md-3 col-xxl-2">
                  <ul class="megamenu__list list-unstyled">
                    <ng-container *ngFor="let parking of parkings">
                      <li [attr.data-parking]="parking.uuid" (mouseover)="onmouserover(parking.uuid);" (mouseout)="onmouseout()">
                        <a class="link-darka text-decoration-none" (click)="clickParking(parking.id)">{{ parking.nombre | unescape }}</a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
                <div class="col-6 col-lg-4">
                  <ul class="megamenu__images list-unstyled">
                    <ng-container *ngFor="let parking of parkings">
                      <li [ngClass]="{'active': imageactive===parking.uuid}">
                        <div class="megamenu__container">
                          <img *ngIf="false" [attr.src]="url+'/api/getHomeImage/'+parking.uuid" alt="{{redparams.nombre}} - Aparcamiento {{ parking.nombre | unescape}}" class="img-fluid">
                          <img *ngIf="true" [attr.src]="url + parking.physicalfilename" alt="{{redparams.nombre}} - Aparcamiento {{ parking.nombre | unescape}}" class="img-fluid">
                          <div class="megamenu__centered">{{ parking.nombre | unescape}}</div>
                        </div>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#unete" (click)="clickMenu()" title="{{'navbar.montatubiciparc' | translate }}">
              {{'navbar.montatubiciparc' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/faq" (click)="clickMenu()" title="{{'faq' | translate }}">
              <i class="fas fa-question-circle"></i>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" *appIsAuth="false">
            <a class="nav-link" routerLink="/login" (click)="clickMenu()" title="{{'navbar.accede' | translate }}">
              {{'navbar.accede' | translate }}
            </a>
          </li>
          <li class="nav-item" *appIsAuth="false">
            <a class="nav-link" routerLink="/register" (click)="clickMenu()" title="{{'navbar.registrate' | translate }}">
              {{'navbar.registrate' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/mapa']" (click)="clickMenu()" title="{{'navbar.reservatuplaza' | translate }}">
              {{'navbar.reservatuplaza' | translate }}
            </a>
          </li>
          <li class="nav-item d-none d-xl-block">
            <a class="nav-link" [routerLink]="['/perfil']" title="{{'navbar.tuplan' | translate }}" (click)="clickMenu()">
              <i class="fas fa-user-circle"></i>
            </a>
          </li>
          <li class="nav-item dropdown" *appIsAuth="!false" style="position: initial;">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" (click)="clickPerfilMenu()" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              title="{{'navbar.tuplan' | translate }}">
              {{'navbar.tuperfil' | translate }}
            </a>
            <div class="dropdown-menu minimenu" aria-labelledby="navbarDropdown" [ngClass]="{'show': !isPerfilCollapsed}">
              <div class="container-fluid">
                <div class="row justify-content-end">

                  <div class="col">
                    <ul class="minimenu__list list-unstyled">
                      <li>
                        <a [routerLink]="['/perfil']" title="{{'navbar.tuplan' | translate }}" (click)="clickPertfilItem()">
                          {{'navbar.tuplan' | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/misbicis']" title="{{'navbar.tuplan' | translate }}" (click)="clickPertfilItem()">
                          {{'navbar.tusbicis' | translate }}
                        </a>
                      </li>
                      <li>
                        <a [routerLink]="['/password']" title="{{'navbar.tuplan' | translate }}" (click)="clickPertfilItem()">
                          {{'navbar.contrasena' | translate }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>



            </div>
          </li>
          <li class="nav-item" *appIsAuth="!false">
            <a class="nav-link" (click)="logout()" title="{{'navbar.salir' | translate }}">
              {{'navbar.salir' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

</header>
