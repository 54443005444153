import { Component, OnInit } from '@angular/core';
import { ParkingsService } from 'src/app/core/services/parkings.service';
@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
})
export class ResumeComponent implements OnInit {
  idParkingSelected: number;
  code: number;
  constructor(private parkingService: ParkingsService) {}

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
  }
}
