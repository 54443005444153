import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function

  privacy: string;
  constructor(private homeservice: HomeService) {}

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.homeservice.getPrivacy('es').subscribe({
      next: (data: any) => {
        this.privacy = data.msg;
      },
    });
  }
}
