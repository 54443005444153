import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
})
export class TermsComponent implements OnInit {
  terms: string;
  constructor(private homeservice: HomeService) {}

  ngOnInit(): void {
    window.localStorage.removeItem('returnUrl');
    this.homeservice.getTerms('es').subscribe({
      next: (data: any) => {
        this.terms = data.msg;
      },
    });
  }
}
