import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { LightboxData, DefaultProperties, Properties } from '../models';
import { LightboxComponent } from '../../shared/lightbox/lightbox.component';

export interface AdComponent {
  lightboxData: LightboxData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events: any;
}

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  _defaultProperties!: Properties;
  instanced = false;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    // private viewContainerRef: ViewContainerRef,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  appendComponentToBody(component, lightboxData: LightboxData) {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    (<AdComponent>componentRef.instance).lightboxData = lightboxData;

    this.appRef.attachView(componentRef.hostView);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Add to body
    document.body.appendChild(domElem);

    (<AdComponent>componentRef.instance).events.subscribe((event) => {
      if (event.type === 'close') {
        this.instanced = false;
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }
    });

    // const componentRef = this.viewContainerRef.createComponent(component);
  }
  open(lightboxData: LightboxData) {
    if (!this.instanced) {
      this.instanced = true;
      lightboxData.properties = this.applyPropertieDefaults(DefaultProperties, lightboxData.properties);
      const component = this.getLightboxComponent();
      this.appendComponentToBody(component, lightboxData);
    }
  }

  getLightboxComponent() {
    return LightboxComponent;
  }

  applyPropertieDefaults(defaultProperties, properties) {
    if (!properties) {
      properties = {};
    }

    if (!properties.index) {
      properties.index = 0;
    }
    this._defaultProperties = Object.assign({}, defaultProperties);
    return Object.assign(this._defaultProperties, properties);
  }
}
