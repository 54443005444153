/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { ReplaySubject } from 'rxjs';

import { JwtService } from './jwt.service';

import { map, distinctUntilChanged, tap, shareReplay } from 'rxjs/operators';
import { User, UserMsg } from '../models';
// import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { ApiService } from 'src/app/core/services/api.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  //private currentUserSubject = new BehaviorSubject<User>({} as User);
  private currentUserSubject = new BehaviorSubject<User | null>(null);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  //public isAuthenticated = this.currentUser.pipe(map((user) => !!user));

  public isAuth: boolean;
  headersload: HttpHeaders;
  httpParams: HttpParams;
  refreshUser = new BehaviorSubject<boolean>(false);
  url = environment.api_url;

  constructor(private readonly http: HttpClient, private readonly router: Router, private readonly apiService: ApiService, private readonly jwtService: JwtService) {
    this.headersload = new HttpHeaders().set('load', '1');
    this.httpParams = new HttpParams();
    this.isAuth = false;
  }

  _getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  _update(user): Observable<User> {
    return this.apiService.put('/user/user', user).pipe(
      map((data) => {
        // Update the currentUser observable
        //  con sole.log(data);
        this.currentUserSubject.next(data.user);
        return data.user;
      })
    );
  }

  updatepassword(credentials): Observable<User> {
    return this.apiService.put('/user/updatepassword', credentials);
  }
  savenewpassword(credentials): Observable<User> {
    return this.apiService.put('/user/savenewpassword', credentials);
  }
  forgotpassword(credentials): Observable<User> {
    return this.apiService.post('/user/forgotpassword', credentials);
  }

  _attemptAuth(type: string, credentials): Observable<User> {
    let url: string;
    if (type !== 'register') {
      url = '/user/login';
    } else {
      url = '/user/user';
    }
    return this.apiService.post(url, credentials).pipe(
      map((data) => {
        console.log(data);
        this.setAuth(data.msg);
        return data;
      })
    );
  }

  _setAuth(user: User) {
    this.jwtService.saveToken(user.token);
    this.currentUserSubject.next(user);

    this.isAuth = true;
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.http.get<UserMsg>(`${this.url}/user/user`).subscribe({
        next: (data) => {
          this.setAuth(data.msg);
        },
        error: () => {
          this.purgeAuth();
        },
      });
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  _purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.isAuth = false;
  }
  getRegisteredCards() {
    return this.apiService.get('/user/getregisteredcards');
  }
  activateRegisteredCard(id: string) {
    return this.apiService.put(`/user/activateRegisteredCard/${id}`);
  }
  _login(credentials: { email: string; password: string }): Observable<{ user: User }> {
    return this.http.post<{ user: User }>(`${this.url}/user/login`, { user: credentials }).pipe(tap(({ user }) => this.setAuth(user)));
  }
  login(credentials: { email: string; pass1: string }): Observable<UserMsg> {
    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<UserMsg>(`${this.url}/user/login`, JSON.stringify(credentials), { headers: reqHeaders }).pipe(
      tap((user: UserMsg) => {
        this.setAuth(user.msg);
      })
    );
  }
  setAuth(user: User): void {
    this.jwtService.saveToken(user.token);
    this.isAuthenticatedSubject.next(true);
    this.currentUserSubject.next(user);
  }
  purgeAuth(): void {
    this.jwtService.destroyToken();
    this.currentUserSubject.next(null);
    this.isAuthenticatedSubject.next(false);
  }
  update(user: Partial<User>): Observable<{ user: User }> {
    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<{ user: User }>(`${this.url}/user/user`, JSON.stringify(user), { headers: reqHeaders }).pipe(
      tap(({ user }) => {
        this.currentUserSubject.next(user);
      })
    );
  }
  getCurrentUser(): Observable<UserMsg> {
    //  console.log(caller);
    return this.http.get<UserMsg>(`${this.url}/user/user`).pipe(
      tap({
        next: (user) => this.setAuth(user.msg),
        error: () => this.purgeAuth(),
      }),
      shareReplay(1)
    );
  }
  logout(): void {
    this.purgeAuth();
    void this.router.navigate(['/']);
  }
  register(credentials: { nombre: string; email: string; pass1: string; apellidos: string; telefono: string; pass2: string; checkterms: boolean; checkads: boolean }): Observable<UserMsg> {
    const reqHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<UserMsg>(`${this.url}/user/user`, JSON.stringify(credentials), { headers: reqHeaders }).pipe(tap((user: UserMsg) => this.setAuth(user.msg)));
  }
}
