/**
 * Created by ahsanayaz on 08/11/2016.
 */

export class ReTree {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public test(str: string, regex: any): any {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.test(str);
    } else if (regex && Array.isArray(regex.and)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return regex.and.every((item: any) => {
        return this.test(str, item);
      });
    } else if (regex && Array.isArray(regex.or)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return regex.or.some((item: any) => {
        return this.test(str, item);
      });
    } else if (regex && regex.not) {
      return !this.test(str, regex.not);
    } else {
      return false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public exec(str: string, regex: any): any {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    if (regex instanceof RegExp) {
      return regex.exec(str);
    } else if (regex && Array.isArray(regex)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return regex.reduce((res: any, item: any) => {
        // eslint-disable-next-line no-extra-boolean-cast
        return !!res ? res : this.exec(str, item);
      }, null);
    } else {
      return null;
    }
  }
}
